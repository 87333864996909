import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import './index.scss';

class FilterDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        // Call any function in provider
    }

    handleSelected = (options, { action, removedValue }) => {

        console.log("handleSelected");
        console.log("options", options);
        console.log("action", action);
        console.log("removedValue", removedValue);

        this.props.setOverlayLoader({ how: true })

        setTimeout(() => {
            this.props.setFilter({
                filter: options,
                type: this.props.entity
            })
        }, 500);
    }

    render() {
        const { options, placeholder } = this.props;

        return <Fragment>
            <Select
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({
                        ...provided, zIndex: 9999, borderRadius: 5,
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                    }),
                    option: (base, { data, isDisabled, isFocused, isSelected }) => {
                        return {
                            ...base,
                            borderRadius: 5,
                            backgroundColor: isFocused ? "#dfdfdf" : "white",
                            color: isFocused ? "var(--blue-color)" : "var(--darker-blue-color)",
                            fontWeight: isFocused ? "bold" : "normal",
                        };
                    }
                }
                }
                options={options}
                isSearchable={true}
                onChange={this.handleSelected}
                isMulti
                placeholder={<div className='text-truncate'>{placeholder}</div>}
                className={this.props.dynamicClassName ? this.props.dynamicClassName : 'react-select-container'}
                classNamePrefix={this.props.dynamicPrefix ? this.props.dynamicPrefix : 'react-select'}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--blue-color)',
                        primary: 'var(--darker-blue-color)',
                        neutral0: 'white',
                        neutral20: 'var(--darker-blue-color)',
                    },
                })} />
        </Fragment>
    }
}

export default FilterDropdown;