import React, { Component, Fragment } from 'react';
import { ButtonGroup, Button, Form } from 'react-bootstrap';

import * as d3 from 'd3';
import './index.scss';

import { IoRadioButtonOff } from "react-icons/io5";
import { IoRadioButtonOn } from "react-icons/io5";

class LayerVisibility extends Component {

    constructor(props) {
        super(props);
        this.state = {
            labels: true,
            projects: true,
            clusters: true
        }
    }

    handleLayerVisibility = event => {
        const layer = event.target.id;

        switch (layer) {
            case "visibilityLabels":
                if (this.state.labels === true) {
                    this.setState({
                        labels: false
                    })
                    d3.select(".svg-labels").style('visibility', 'hidden');
                    d3.select(".svg-labels").style('pointer-events', 'none')
                    d3.select(".svg-labels").style('z-index', 0)
                    
                } else {
                    this.setState({
                        labels: true
                    })
                    d3.select(".svg-labels").style('visibility', 'visible')
                    d3.select(".svg-labels").style('pointer-events', 'click')
                    d3.select(".svg-labels").style('z-index', 10)
                }
                break;
            case "visibilityProjects":
                if (this.state.projects === true) {
                    this.setState({
                        projects: false
                    })
                    d3.select("#canvas").style('opacity', 0)
                } else {
                    this.setState({
                        projects: true
                    })
                    d3.select("#canvas").style('opacity', 1)
                }
                break;
            case "visibilityClusters":
                if (this.state.clusters === true) {
                    this.setState({
                        clusters: false
                    })
                    d3.select(".svg-clusters").style('visibility', 'hidden');
                } else {
                    this.setState({
                        clusters: true
                    })
                    d3.select(".svg-clusters").style('visibility', 'visible')
                }
                break;
            default:
                break;
        }
    }

    render() {
        const { labels, projects, clusters } = this.state;
        return <Fragment>
            <h6 className="text-yellow fw-bold text-code text-uppercase pt-2"><small>Visualize:</small></h6>

            <div className='visibility-container d-flex gap-4 pt-0' aria-label="Layers visibility">
                <Form.Check
                    className='text-white'
                    type={'checkbox'}
                    id={`visibilityLabels`}
                    label={`Label`}
                    defaultChecked={labels} onClick={this.handleLayerVisibility}
                />
                <Form.Check
                    className='text-white'
                    type={'checkbox'}
                    id={`visibilityProjects`}
                    label={`Documents`}
                    defaultChecked={projects} onClick={this.handleLayerVisibility}
                />
                <Form.Check
                    className='text-white'
                    type={'checkbox'}
                    id={`visibilityClusters`}
                    label={`Clusters`}
                    defaultChecked={clusters} onClick={this.handleLayerVisibility}
                />
                {/* <Button id="visibilityLabels" variant="secondary" active={labels ? true : false} onClick={this.handleLayerVisibility}>
                    {labels ? <IoRadioButtonOn></IoRadioButtonOn> : <IoRadioButtonOff></IoRadioButtonOff>} Labels
                </Button>
                <Button id="visibilityProjects" variant="secondary" active={projects ? true : false} onClick={this.handleLayerVisibility}>
                    {projects ? <IoRadioButtonOn></IoRadioButtonOn> : <IoRadioButtonOff></IoRadioButtonOff>} Docs
                </Button>
                <Button id="visibilityClusters" variant="secondary" active={clusters ? true : false} onClick={this.handleLayerVisibility}>
                    {clusters ? <IoRadioButtonOn></IoRadioButtonOn> : <IoRadioButtonOff></IoRadioButtonOff>} Clusters
                </Button> */}
            </div>
        </Fragment>
    }
}

export default LayerVisibility;