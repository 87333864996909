import React, { Component, Fragment } from 'react';
// import { Accordion } from 'react-bootstrap';
import FilterDropdown from '../../components/FilterDropdown';
import FilterSlider from '../../components/FilterSlider';
import ColouringSelector from '../../components/ColouringSelector';
import LayerVisibility from '../../components/LayerVisibility';
import './index.scss';
import * as d3 from 'd3';
import { DebounceInput } from 'react-debounce-input';
import ReactTooltip from '../../components/ReactTooltip';

import {
    CONFIG
} from '../../common/constants';
import FilterKeywordSearch from '../FilterKeywordSearch';
class FiltersPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        // Call any function in provider
    }



    onFreeTextChange(e) {
        this.props.setOverlayLoader({ how: true })
        setTimeout(() => {
            this.props.setFilter({
                filter: [{
                    value: e.target.value,
                    label: e.target.value,
                    sort: e.target.value,
                }],
                type: 'keyword_search'
            })
        }, 500);
    }

    render() {

        var minYear = parseInt(d3.min(this.props.data.topics.data.map(x => x.year)));

        var maxYear = parseInt(d3.max(this.props.data.topics.data.map(x => x.year)));

        var defaultYears = [minYear, maxYear];
        if (this.props.filters.selected.filter(x => x.type == 'years').length > 0) {
            defaultYears[0] = (parseInt(this.props.filters.selected.filter(x => x.type == 'years')[0].values.reduce(function (prev, current) {
                return (parseInt(prev) < parseInt(current)) ? parseInt(prev) : parseInt(current)
            })))
            defaultYears[1] = (parseInt(this.props.filters.selected.filter(x => x.type == 'years')[0].values.reduce(function (prev, current) {
                return (parseInt(prev) > parseInt(current)) ? parseInt(prev) : parseInt(current)
            })))
        }

        return <Fragment>
            <div className="filters-panel">
                <h4 className='pt-3 pb-4 text-white px-4' ><strong>{CONFIG.APP.NAME}</strong></h4>
                <div className="filters-list px-4 mb-5">
                    <div className="filter-item">
                        <h6 className="filter-label"><small>Topic</small></h6>
                        <FilterDropdown

                            entity={'topics'}
                            placeholder={'Filter by topics'}
                            options={this.props.filters.populate['topics'].options}
                            {...this.props}></FilterDropdown>
                    </div>

                    <div className="filter-item">
                        <h6 className="filter-label"><small>Search in text<span>
                            <ReactTooltip text="INFO" color="white" tooltipText="Keyword search with direct match"></ReactTooltip>
                        </span></small></h6>
                        {/* <DebounceInput
                            className='form-control'
                            placeholder={'Search by keyword(s)'}
                            minLength={2}
                            debounceTimeout={300}
                            onChange={(e) => { this.onFreeTextChange(e) }} /> */}
                        <FilterKeywordSearch
                            placeholder={'Search by keyword(s)'}
                            {...this.props}></FilterKeywordSearch>

                    </div>

                    <div className="filter-item">
                        <h6 className="filter-label"><small>Sustainable Development Goal (SDG)</small></h6>
                        <FilterDropdown
                            entity={'SDGs'}
                            placeholder={'Filter by SDG'}
                            options={this.props.filters.populate['SDGs'].options}
                            {...this.props}></FilterDropdown>
                    </div>

                    <div className="filter-item d-none">
                        <h6 className="filter-label"><small>Keywords</small></h6>
                        <FilterDropdown
                            entity={'keywords'}
                            placeholder={'Filter by keyword'}
                            options={this.props.filters.populate['keywords'].options}
                            {...this.props}></FilterDropdown>
                    </div>

                    <div className="filter-item">
                        <h6 className="filter-label"><small>Type of document</small></h6>
                        <FilterDropdown
                            entity={'type'}
                            placeholder={'Filter by type'}
                            options={this.props.filters.populate['type'].options}
                            {...this.props}></FilterDropdown>
                    </div>

                    <div className="filter-item">
                        <h6 className="filter-label"><small>University</small></h6>
                        <FilterDropdown
                            entity={'university'}
                            placeholder={'Filter by university'}
                            options={this.props.filters.populate['university'].options}
                            {...this.props}></FilterDropdown>
                    </div>

                    <div className="filter-item d-none">
                        <h6 className="filter-label"><small>Years</small></h6>
                        <FilterDropdown
                            entity={'years'}
                            placeholder={'Filter by years'}
                            options={this.props.filters.populate['years'].options}
                            {...this.props}></FilterDropdown>
                    </div>

                    <div className="filter-item">
                        <h6 className="filter-label"><small>Time range for publications<span>
                            <ReactTooltip text="INFO" color="white" tooltipText="Select a range of maximum 2 years to have the best capacity of exploration"></ReactTooltip>
                        </span></small></h6>
                        <div className="">
                            <FilterSlider entity={'publicationsYears'}
                                everyTwo={false}
                                defaultValue={[2020, 2021]}
                                min={2014}
                                max={2021}
                                {...this.props}></FilterSlider>
                        </div>
                    </div>
                    <div className="filter-item mt-3">
                        <h6 className="filter-label"><small>Time range for other documents</small></h6>
                        <div className="">
                            <FilterSlider entity={'years'}
                                defaultValue={defaultYears}
                                min={2010}
                                max={maxYear}
                                {...this.props}></FilterSlider>
                        </div>
                    </div>
                </div>
                <div className='bottom-filters p-4'>
                    <ColouringSelector {...this.props} ></ColouringSelector>
                    <LayerVisibility></LayerVisibility>
                </div>
            </div>
        </Fragment>
    }
}

export default FiltersPanel;