import React, { useCallback, useEffect } from 'react';
import { Graphics } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import * as d3 from 'd3';
import _ from 'lodash';

import {
    CONFIG
} from '../../common/constants';

import {
    showTooltip,
    hideTooltip,
    createTooltip
} from './Tooltip';

import {
    zoomed
} from './Zoom';

export const ProjectDot = function (props) {

    // const onClickPoint = ({
    //     dot = null,
    //     topic = null
    // }) => {}
    function drawStar(x, y, points, innerRadius, outerRadius, angle = 0) {
        var p = []
        if (points > 2) {
            let step, halfStep, start, n, dx, dy;
            step = (Math.PI * 2) / points;
            halfStep = step / 2;
            start = (angle / 180) * Math.PI;
            // target.moveTo(x + (Math.cos(start) * outerRadius), y - (Math.sin(start) * outerRadius));
            p.push(x + (Math.cos(start) * outerRadius), y - (Math.sin(start) * outerRadius))
            for (n = 1; n <= points; ++n) {
                dx = x + Math.cos(start + (step * n) - halfStep) * innerRadius;
                dy = y - Math.sin(start + (step * n) - halfStep) * innerRadius;
                p.push(dx, dy)
                // target.lineTo(dx, dy);
                dx = x + Math.cos(start + (step * n)) * outerRadius;
                dy = y - Math.sin(start + (step * n)) * outerRadius;
                p.push(dx, dy)
                // target.lineTo(dx, dy);
            }
            return p
        }
    }


    const draw = useCallback((g) => {
        if (props.getFilteredProjects().length > 0 && !props.getFilteredProjects().includes(props.datum.id))
            return
        const onOverPoint = (event) => {
            // console.log("ON_OVER_POINT");
            // console.log("OVER PROPS", props);
            // console.log("EVENT", event);
            const currentPointData = event.target.data;

            // ? HOVER ON A DOCUMENT
            if (getCanvasVisibility())
                setTimeout(function () {
                    showTooltip({
                        event: event,
                        datum: props.datum,
                        cluster: props.getTopicInformation(props.datum.cluster)
                    });
                }, 300)
            props.dotHandleOver({
                selectedDot: currentPointData.id,
                selectedCluster: currentPointData.cluster,
                canvasApp: props.canvasApplication,
                filteredDots: props.getFilteredProjects(),
                clickedDot: props.getClickedDot(),
                clickedLabel: props.getClickedLabel(),
                totalParticipants: props.totalParticipants
            });

        }

        const onOutPoint = () => {
            setTimeout(function () {
                hideTooltip();
            }, 300)
            props.dotHandleOut({
                canvasApp: props.canvasApplication,
                filteredDots: props.getFilteredProjects(),
                filteredLabels: props.getFilteredTopics(),
                clickedDot: props.getClickedDot(),
                clickedLabel: props.getClickedLabel()
            })
        }

        // console.log("DRAW DOT -> props", props)
        // if (!props.getFilteredTopics().includes(props.datum.cluster)) {
        //     console.log("TEST NOW")
        //     d3.selectAll(".cluster-container").style("opacity", 0);
        //     // console.log("DDD", props.getFilteredTopics(), props.datum.cluster)
        //     // return
        // }

        props.datum.dotColor = props.dotColor;
        g.lineStyle(0);

        g.beginFill(d3.rgb('#fff').formatHex().replace('#', '0x'), 1)

        g.tint = d3.rgb(props.dotColor).formatHex().replace('#', '0x');

        if (props.datum.type == 'National projects') {
            g.drawRect(props.x, props.y, props.dotSize * 3.5, props.dotSize * 3.5);
            g.hitArea = new PIXI.Rectangle(
                props.x, props.y, props.dotSize * 3.5, props.dotSize * 3.5
            );
        }

        if (props.datum.type == 'H2020') {
            g.drawCircle(props.x, props.y, props.dotSize * 2, props.dotSize * 2);
            g.hitArea = new PIXI.Circle(
                props.x,
                props.y,
                props.dotSize * 2
            );
        }
        if (props.datum.type == 'Patents') {
            g.drawPolygon([props.x, props.y,
            props.x + 5, props.y + 10,
            props.x - 5, props.y + 10
            ]);
            g.hitArea = new PIXI.Polygon(
                props.x, props.y,
                props.x + 5, props.y + 10,
                props.x - 5, props.y + 10
            );
        }

        if (props.datum.type == 'Publications') {
            g.drawCircle(props.x, props.y, props.dotSize * 2, props.dotSize * 2);
            g.hitArea = new PIXI.Circle(
                props.x,
                props.y,
                props.dotSize * 2
            );
            // function drawStar(x, y, points, innerRadius, outerRadius, angle = 0) {

            // g.drawPolygon(drawStar(props.x, props.y, 5, 0, 10, 90));
            // g.hitArea = new PIXI.Polygon(
            //     props.x, props.y,
            //     props.x + 5, props.y + 10,
            //     props.x - 5, props.y + 10
            // );
        }

        g.endFill();
        g.alpha = CONFIG.TOPICS.LAYOUT.OPACITIES.NORMAL;

        g.interactive = true;
        g.buttonMode = true;
        g.data = props.datum;

        g.on('click', (event) => {
            const dotData = event.target.data
            if (getDotsVisibility()) {
                props.openAnalyticsModal(dotData.id, dotData.cluster);
                onOverPoint(event);
                // Paint a circle over the selected point
                d3.selectAll('.circle-selected').remove();
                const svg = d3.select('.svg-labels');
                svg.append('circle')
                    .attr('class', 'circle-selected')
                    .attr('topic', dotData.cluster)
                    .attr('r', props.dotSize * 1.25)
                    .attr('cx', props.x)
                    .attr('cy', props.y)
                    .attr('stroke', "#FFF")
                    .attr('fill', "transparent")
            }
        });
        g.on('mouseover', (event) => onOverPoint(event));
        g.on('mouseout', () => onOutPoint());

    }, [props]);

    return <Graphics draw={draw} />;

}

export const Line = function (props) {
    const draw = useCallback((g) => {
        g.clear()

        if (props.line_coords.length > 0) {
            g.beginFill(0x666666, 0.5)
            g.lineStyle(3, 0x000000, 0.6)

            g.moveTo(props.line_coords[0].x, props.line_coords[0].y)

            for (var i = 1; i < props.line_coords.length; i++) {
                let cord = props.line_coords[i]
                g.lineTo(cord.x, cord.y)
            }

            g.endFill()
        }
    }, [props]);
    return <Graphics draw={draw} />;

}


function getDotsVisibility() {
    var el = document.querySelector('#canvas');
    if (getComputedStyle(el).getPropertyValue('opacity') != 0) {
        console.log("DOTS VISIBLE")
        el.style.pointerEvents = 'click';
    }
    else {
        console.log("DOTS HIDDEN")
        el.style.pointerEvents = 'none';
    }

    return getComputedStyle(el).getPropertyValue('opacity') != 0;
}

function getLabelsVisibility() {
    var el = document.querySelector('.svg-labels');
    return getComputedStyle(el).getPropertyValue('visibility') != 'hidden';
}

function getCanvasVisibility() {
    var el = document.querySelector('#canvas');
    return getComputedStyle(el).getPropertyValue('opacity') != 0;
}


export const LabelsSVG = function (props) {

    const svgRef = React.useRef(null);
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const svgEl = d3.select(svgRef.current).attr('class', 'svg-labels');
        svgEl.selectAll("*").remove(); // Clear svg content before adding new elements 

        // LABELS
        const gLabels = svgEl.append('g')
            .attr("class", "labels-container cluster");

        const label = gLabels.selectAll("text")
            .data(props.data.topics.topics)
            .enter();


        // text
        label.append("text")
            .attr("data-cluster", d => d.cluster)
            .attr('class', d => 'label label-' + d.cluster)
            .attr("x", d => props.xScale(d.x))
            .attr("y", d => props.yScale(d.y))
            // .attr("dx", d => d.x + 10)
            .attr("dy", navigator.userAgent.indexOf("Firefox") > -1 ? '1%' : '0%')
            .attr("dominant-baseline", "hanging")
            .attr("baseline-shift", "sub")
            .attr("text-anchor", "start")
            .attr("fill", d => props.colorScale(d.cluster))
            .style("opacity", 1)
            // .style("z-index", 999)
            .text(d => "‎  ‎  ‎"
                + d.topic_label
                + "  ‎ ‎ ").call(getBB);

        // center label vertically in rect




        // Rectangle
        label.insert("rect", "text")
            .attr('class', d => 'label label-' + d.cluster)
            .attr("x", d => props.xScale(d.x))
            .attr("y", d => props.xScale(d.y))
            .attr("rx", 8)
            // .attr("height", 30)

            .attr("fill", "rgba(20,20,20,0.8")

            // .style("fill", "rgba(255,255,255,0.9)")
            .style("stroke", d => props.colorScale(d.cluster))
            .style("pointer-events", "fill")
            .style("height", '30px')
            .style("z-index", 999)

            // ? Hover on a label
            .on("mouseover", d => {
                if (getLabelsVisibility()) {
                    setTimeout(function () {
                        createTooltip({
                            x: d.clientX,
                            y: d.clientY,
                            content: d.target.__data__['topic_words'],
                            clusterName: d.target.__data__['topic_label'],
                            clusterColor: props.colorScale(parseInt(d.srcElement['__data__']['cluster']))
                        })
                    }, 300)
                    props.dotHandleOver({
                        selectedCluster: d.target.__data__['cluster'].toString(),
                        canvasApp: props.canvasApplication,
                        clickedDot: props.getClickedDot(),
                        clickedLabel: props.getClickedLabel()
                    });
                }
            })
            .on("mouseout", () => {
                setTimeout(function () {
                    hideTooltip();
                }, 300)
                props.dotHandleOut({
                    canvasApp: props.canvasApplication,
                    filteredDots: props.getFilteredProjects(),
                    filteredLabels: props.getFilteredTopics(),
                    clickedDot: props.getClickedDot(),
                    clickedLabel: props.getClickedLabel()
                })
            })
            .on("click", d => {
                if (getLabelsVisibility()) {
                    props.setOverlayLoader(true);
                    props.openAnalyticsModal(null, d.target.__data__['cluster']);
                }
            });

        // Pass the zoom function directly to canvas
        const d3Canvas = d3.select("canvas");
        d3.select(d3Canvas.node()).call(d3.zoom()
            .scaleExtent([0.8, 8])
            .on("zoom", ({ transform }) => zoomed({
                transform: transform,
                canvasApp: props.canvasApplication
            })));

        zoomed({ transform: d3.zoomIdentity, canvasApp: props.canvasApplication });
    });

    return <svg ref={svgRef} width={props.canvasWidth + 300} height={props.canvasHeight} />

}

export const ClustersSVG = function (props) {
    const svgRef = React.useRef(null);
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        const svgEl = d3.select(svgRef.current).attr('class', 'svg-clusters');
        svgEl.selectAll("*").remove(); // Clear svg content before adding new elements 
        // const gMainCluster = svgEl.append('g');
        // var mainContour = d3.contourDensity()
        //     .x(d => props.xScale(d.x))
        //     .y(d => props.yScale(d.y))
        //     .size([props.canvasWidth, props.canvasHeight])
        //     .bandwidth(30)
        //     .thresholds(30)
        //     (
        //         props.data.topics.topics
        //     );
        // console.log("MAINCONTOURS", mainContour);
        // var mainOpacityScale = d3.scalePow()
        //     .exponent(9)
        //     .domain([0, mainContour.length])
        //     .range([0, 1]);
        // const mainG = gMainCluster.append('g')
        //     .attr('id', 'main')
        //     .attr("class", "maincluster-container")
        //     .attr("fill", "none")
        //     .attr("stroke", "#C00") //colorCluster)
        //     .attr("stroke-linejoin", "round")
        //     .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);

        // mainG.selectAll("path")
        //     .data(mainContour)
        //     .enter()
        //     .append("path")
        //     .attr('class', 'maincluster')
        //     .attr("stroke-width", 1)
        //     .attr("stroke-opacity", function (d, i) {
        //         return i * 0.05;
        //         //return _.clamp(i * 0.025, 0, MAX_OPACITY/2);
        //     })
        //     .attr("fill", function (d) {
        //         return CONFIG.TOPICS.LAYOUT.COLOR_CLUSTER;
        //     })
        //     .attr("fill-opacity", function (d, i) {
        //         return mainOpacityScale(i);
        //     })
        //     .attr("d", d3.geoPath());

        // DENSITY BY PROJECTS IN CLUSTER

        const gClusters = svgEl.append('g');
        props.data.topics.topics.forEach(function (cluster, i) {
            // create contours from the data
            var contours = d3.contourDensity()
                .x(d => props.xScale(d.x))
                .y(d => props.yScale(d.y))
                .size([props.canvasWidth, props.canvasHeight])
                .bandwidth(18)
                .thresholds(9)
                (
                    _.filter(
                        props.data.topics.data,
                        function (row) { return row.cluster === cluster.cluster; }
                    )
                );

            // opacity with scalePow will go to 1 very fast in the latest contours
            // (the inner ones). The higher the exponent, more at the end the fast
            // increase will happen
            var opacityScale = d3.scalePow()
                .exponent(9)
                .domain([0, contours.length])
                .range([0, 0.2]);

            const g = gClusters.append('g')
                .attr('id', 'id' + cluster.cluster)
                .attr("class", "cluster-container")
                .attr("fill", "none")
                .attr("stroke", CONFIG.TOPICS.LAYOUT.COLOR_CLUSTER) //colorCluster)
                // .attr("stroke", props.colorScale(cluster)) //colorCluster)
                .attr("stroke-linejoin", "round")
                .style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE)

            g.selectAll("path")
                .data(contours)
                .enter()
                .append("path")
                .attr('class', 'cluster' + cluster)
                .attr("stroke-width", 1)
                .attr("stroke-opacity", function (d, i) {
                    // return 0;
                    return i * 0.05;
                    //return _.clamp(i * 0.025, 0, MAX_OPACITY/2);
                })
                .attr("fill", function (d) {
                    return CONFIG.TOPICS.LAYOUT.COLOR_CLUSTER;
                })
                .attr("fill-opacity", function (d, i) {
                    // return 0;
                    return opacityScale(i);
                })
                .attr("d", d3.geoPath());

        });
    })

    return <svg ref={svgRef} width={props.canvasWidth} height={props.canvasHeight} />
}


// UTILS

const getBB = function (selection) {
    selection.each(function (d) { d.bbox = this.getBBox(); })
}