import React, { Component, Fragment } from 'react';
import { Context } from '../../context/Context';

import './index.scss';

import {
    CONFIG
} from '../../common/constants';
class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.escFunction = this.escFunction.bind(this);
    }

    escFunction = event => {
        if (event.key === "Escape") {
            this.closeModal()
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    closeModal() {
        this.context.setModalOpened('');
        this.context.closeOffCanvasMenu();
    }

    render() {
        return <Fragment>
            <Context.Consumer>
                {context =>
                    <div className={"modal lg d-block " + (context.getModalOpened() == this.props.modalId ? 'visible opacity-100' : 'opacity-0 invisible')} tabIndex="-1">
                        <div className={"modal-dialog modal-dialog-centered modal-dialog-scrollable " + (this.props.size ? 'modal-' + this.props.size : 'modal-lg')}>
                            <div tabIndex="-1" onBlur={() => { this.closeModal() }} className="modal-content">
                                <div className="modal-header border-0 position-absolute top-0 end-0 p-0 pt-3 pe-3">
                                    <button onClick={() => { this.closeModal() }} type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className={this.props.padding == false ? 'modal-body px-0' : 'modal-body px-4'}>
                                    {this.props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Context.Consumer>
        </Fragment>
    }
}

Modal.contextType = Context;
export default Modal;