import React from 'react';
import Footer from '../../components/Footer';
import EutNavbar from '../../components/Navbar';
import './index.scss';

function About() {
    return <div className='about h-100'>
        <EutNavbar />
        <div className='page-title'>
            <h1 className='text-white'>ABOUT</h1>
        </div>
        <div className='mt-5 animate__animated animate__fadeIn'>
            <div className='row mx-auto justify-content-center d-flex h-100 container px-md-5 pb-5'>
                <div className='col-md-6 col-12'>
                    <p>This website has been developed in the context of the European project EUt+ EXTRAS (EXperimentation to Transform Research Activities and Steering) and it’s a product of the task x4.1 “Perform an internal analysis of research collaborations among EUt+ partners and with the local ecosystems”.</p>
                    <br />
                    <p>As the description of the deliverable “Comprehensive report mapping trends and possible links within EUt+ and beyond” did not define exactly the final outcome, in order to make sure that the final product was answering the needs of the different partners and stakeholder, as well as being aligned with what was written in the bid, it was decided to follow a co-design approach.</p>
                    <br />
                    <p>The co-design process started in November 2021 where a wide open discussion took place with the majority of the participants from the WPX4 brainstorming about all possible functions, products and targets responding to the content bid, as well as the trade-offs and connections with other tasks.</p>
                </div>
                <div className='col-md-6 mt-md-0 mt-5'>
                    <p>Following this initial discussion, bilateral meetings were run with the VPs of Research and Innovation (R&I) and the heads of R&I offices in order to better understand which were their specific needs and demands and which questions they wanted the platform to answer.</p>
                    <br />
                    <p>After these bilateral meetings, a restitution meeting with all the VPs of Research and Innovation and the heads of R&I offices was held in Cartagena in February 2022 to summarise key findings and insights from the interviews. At the end of the meeting, the participants were asked to vote and rank their priorities in terms of the main functions of the platform and the needs it had to answer.</p>
                    <br />
                    <p>As a result of the priority-setting generated through the voting, a proposal of a “R&I information toolkit” was then presented and validated, that comprised several different products and each of them answering different needs:</p>
                    <ul>
                        <li><p>An interactive platform that allows to browse all the research and innovation activities of the eight partners;</p></li>
                        <li><p>EUt+ R&I Mapping slide deck offering an overview of all the R&I activities of the eight partners;</p></li>
                        <li><p>A set of institutional R&I mapping slide decks with key strategic graphs regarding each of the institutions.</p></li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="about-second-section" className='mt-5'>

            <div className='row mt-4 mx-auto justify-content-center d-flex h-100 container px-md-5 pb-5'>
                <h1 className='py-4 fw-bold text-blue'>What is Topic Modeling?</h1>

                <div className='col-md-6 col-12'>
                    <p>Topic Modelling (TM) is a technique that uses machine learning to automatically “discover” the  topics from a collection of texts. TM allows to go beyond standard taxonomies of and to classify records from multiple sources in accordance with a common categorisation, that is not defined a-priori,  but rather “emerges” from the specific corpus of texts.</p>
                    <br />
                    <p>For example, Topic Modeling could be applied to a corpus of newspaper articles, which would result in the automatic cataloguing of articles into sections on Politics, Economy, Sports, etc. The labelling of the topics - therefore the process of giving a name to a topic by looking at the keywords and the documents associated with it - was done by external experts and it was reviewed and validated with key stakeholders from the eight universities. </p>
                </div>
                <div className='col-md-6 col-12 mt-md-0 mt-5'>
                    <p>A deep learning based model for natural language processing (BERT) is used to generate the document embeddings, namely vectorial representations of the texts in a high-dimensional vector space. 
                        Semantically similar texts are represented by close points in the vector space. The application of the K-Means clustering algorithm then creates groups of similar embeddings and makes it possible to extract the different topics contained in the text corpus.</p>
                    <br />
                    <p>For each publication, the reference text includes the title, abstract. In the case of projects, the title and the objective or description were used.</p>
                </div>
               

                <div className='col-12 mt-2'>
                    <div className='text-center p-4'>
                        <h6 className='text-muted'>The three toolkit products from Task 4.1 will be complementary with the products and insights currently in development in SWAFs tasks 3.2 (mapping demand by SMEs) 4.2 (local socioeconomic priorities), 4.3 (external research trends) and 5.2 (EUt+ common research infrastructure). They also benefit greatly from the common EUt+ <a href="https://eut.openaire.eu/" target="_blank">OpenAire research portal</a></h6>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default About;