import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import { history } from './common';
import Homepage from './sections/Homepage';
import Home from './sections/Home';
import Report from './sections/Report';
import SlideDeck from './sections/SlideDeck';
import About from './sections/About';
import Contact from './sections/Contact';
import NotFound from './sections/NotFound';

export const RouteApp = props => {
    return (
        <Router history={history}>
            <Routes>
                <Route path='/' element={<Homepage />} />
                <Route path='/dashboard' element={<Home />} />
                <Route path='/report' element={<Report />} />
                <Route path='/slide-deck' element={<SlideDeck />} />
                <Route path='/about' element={<About />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='*' element={<NotFound />} />

            </Routes>
        </Router>
    )
}