import React, { Component, Fragment } from 'react';
import './index.scss';
import { Context } from '../../context/Context';
import listIcon from '../../assets/img/list-icon.png'

import {
    CONFIG
} from '../../common/constants';
class FiltersSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        // Call any function in provider
    }

    // onFreeTextChange(e) {
    //     this.props.setOverlayLoader({ how: true })
    //     setTimeout(() => {
    //         this.props.setFilter({
    //             filter: [{
    //                 value: e.target.value,
    //                 label: e.target.value,
    //                 sort: e.target.value,
    //             }],
    //             type: 'keyword_search'
    //         })
    //     }, 500);
    // }

    render() {
        return <Fragment>
            <div className="filters-summary d-flex py-2 mt-2">
                <div className="px-3 text-center my-auto">
                    <h5 className="my-0">{this.props.filters.summary.national_projects}</h5>
                    {/* <h5 className="my-0">N.A.</h5> */}
                    <h6 className="fw-400 my-auto">National proj. ■</h6>
                </div>
                <div className="px-3 text-center my-auto">
                    <h5 className="mb-0">{this.props.filters.summary.h2020_projects.toLocaleString('es-ES')}</h5>
                    {/* <h5 className="mb-0">N.A.</h5> */}
                    <h6 className="fw-400 my-auto">H2020 proj. ●</h6>
                </div>
                <div className="px-3 text-center my-auto">
                    <h5 className="mb-0 m">{this.props.filters.summary.patents.toLocaleString('es-ES')}</h5>
                    {/* <h5 className="mb-0 m">N.A.</h5> */}
                    <h6 className="fw-400 my-auto">Patents ▴</h6>
                </div>
                <div className="px-3 text-center my-auto">
                    <h5 className="mb-0 m">{this.props.filters.summary.publications.toLocaleString('es-ES')}</h5>
                    {/* <h5 className="mb-0 m">N.A.</h5> */}
                    <h6 className="fw-400 my-auto">Publications</h6>
                </div>
                <div className="bord mx-2"></div>
               
                <div className="px-3 text-center my-auto">
                    <h5 className="mb-0 m">{this.props.filters.summary.topics.toLocaleString('es-ES')}</h5>
                    {/* <h5 className="mb-0 m">N.A.</h5> */}
                    <h6 className="fw-400 my-auto">Topics</h6>
                </div>
                <div className="px-3 text-center my-auto">
                    <h5 className="mb-0 m">{this.props.filters.summary.current.toLocaleString('es-ES')}</h5>
                    {/* <h5 className="mb-0 m">N.A.</h5> */}
                    <h6 className="fw-400 my-auto"><small>Total documents</small></h6>
                </div>

                <div className="col d-none">
                    <div className="text-center d-flex">
                        <div className="col my-auto text-center text-uppercase">
                            <div className="d-flex text-center">
                                <div className="col my-auto text-end text-uppercase">
                                    <p className="p-description"><small>National projects </small></p>
                                </div>
                                <div className="col my-auto text-center text-uppercase ms-2">
                                    ■
                                </div>
                            </div>
                            <div className="d-flex text-center ms-1">
                                <h2>{this.props.filters.summary.national_projects}</h2>
                            </div>

                        </div>
                        <div className="col my-auto text-center text-uppercase ms-4">
                            <div className="d-flex text-center">
                                <div className="col my-auto text-end text-uppercase">
                                    <p className="p-description"><small>H2020 Projects</small></p>
                                </div>
                                <div className="col my-auto text-center text-uppercase">
                                    ●
                                </div>
                            </div>
                            <div className="d-flex text-center">
                                <h2>{this.props.filters.summary.h2020_projects}</h2>
                            </div>
                        </div>
                        <div className="col my-auto text-center text-uppercase ms-4">
                            <div className="d-flex text-center">
                                <div className="col my-auto text-end text-uppercase">
                                    <p className="p-description"><small>Patents</small></p>
                                </div>
                                <div className="col my-auto text-center text-uppercase">
                                    ▴
                                </div>
                            </div>
                            <div className="d-flex text-center">
                                <h2>{this.props.filters.summary.patents}</h2>
                            </div>
                        </div>
                        <div className="bord ms-3"></div>
                        <div className="col my-auto text-center text-uppercase ms-4 ">
                            <div className="d-flex text-center">
                                <div className="col my-auto text-end text-uppercase">
                                    <p className="p-description"><small>Topics</small></p>
                                </div>
                                <div className="col my-auto text-center text-uppercase">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="d-flex text-center ms-1">
                                <h2>{this.props.filters.summary.topics}</h2>
                            </div>
                        </div>
                        <div className="col my-auto text-center text-uppercase ms-1">
                            <div className="d-flex text-center">
                                <div className="col my-auto text-end text-uppercase">
                                    <p className="p-description ms-2"><small>Documents</small></p>
                                </div>
                                <div className="col my-auto text-center text-uppercase">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="d-flex text-center ms-1">
                                <h2 className="">{this.props.filters.summary.current}</h2>

                            </div>
                        </div>

                    </div>

                </div>


                {/* <div className="col my-auto text-center text-uppercase">
                    <p className="p-description"><small>National projects</small></p>
                    <h2>{this.props.filters.summary.national_projects}</h2>
                </div>
                <div className="col my-auto text-center text-uppercase">
                    <p className="p-description"><small>H2020</small></p>
                    <h2>{this.props.filters.summary.h2020_projects}</h2>
                </div>
                <div className="col my-auto text-center text-uppercase">
                    <p className="p-description"><small>Patents</small></p>
                    <h2>{this.props.filters.summary.patents}</h2>
                </div>
                <div className="col my-auto text-center text-uppercase">
                    <p className="p-description"><small>topics</small></p>
                    <h2>{this.props.filters.summary.topics}</h2>
                </div> */}

            </div>
            <Context.Consumer>
                {context =>
                    <div>
                        <div onClick={() => context.toggleListOpened()} className="list-box d-flex py-2 mt-2">
                            <div className="px-3 text-center my-auto">
                                <div className="d-flex">
                                    <div className="col my-auto">
                                        <img className='list-icon' src={listIcon}></img>
                                        <h6  style={{ "lineHeight": "12px" }} className="fw-400 my-auto mb-1 mt-1"><small>List of<br />documents</small></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => context.setModalOpened('info')} className="info-button d-flex py-2 mt-2">
                            <div className="px-3 text-center my-auto">
                                {/* <h5 className="my-0" style={{ fontFamily: 'Times New Roman', color: 'white', fontWeight: 'bold' }}>
                                    i
                                </h5> */}
                                <h6 className="fw-400 my-auto mb-1">Data <br/>Sources</h6>
                            </div>
                        </div>
                    </div>
                }
            </Context.Consumer>

        </Fragment >
    }
}

export default FiltersSummary;