const tooltipStructure = { "signal": "{title: datum.dataValue, '': datum.dataKey}" };

export function createPieChartSpec(colorsArray, donut = false) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "width": 280,
    "height": 300,
    "autosize": "pad",
    "signals": [
      { "name": "startAngle", "value": 0 },
      { "name": "endAngle", "value": 6.29 },
      { "name": "padAngle", "value": 0 },
      { "name": "sort", "value": true },
      { "name": "strokeWidth", "value": 2 },
      {
        "name": "selected",
        "value": "",
        "on": [{ "events": "mouseover", "update": "datum" }]
      }
    ],
    "data": [
      {
        "name": "table",
        "transform": [
          {
            "type": "pie",
            "field": "dataValue",
            "startAngle": { "signal": "startAngle" },
            "endAngle": { "signal": "endAngle" },
            "sort": { "signal": "sort" }
          }
        ]
      },
      {
        "name": "fieldSum",
        "source": "table",
        "transform": [
          {
            "type": "aggregate",
            "fields": ["dataValue"],
            "ops": ["sum"],
            "as": ["sum"]
          }
        ]
      }
    ],
    "scales": [
      { "name": "color", "type": "ordinal", "range": colorsArray }
    ],
    "marks": [
      {
        "type": "arc",
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": { "signal": "{title: datum.dataValue, '': datum.dataKey}" },
            "fill": { "scale": "color", "field": "dataKey" },
            "x": { "signal": "width / 2" },
            "y": { "signal": "height / 2" }
          },
          "update": {
            "startAngle": { "field": "startAngle" },
            "endAngle": { "field": "endAngle" },
            "padAngle": {
              "signal": "if(selected && selected.dataKey == datum.dataKey, 0.015, 0.015)"
            },
            "innerRadius": {
              "signal": `if(selected && selected.dataKey == datum.dataKey, if(width >= height, height, width) / 2 * ${donut ? '0.45' : '0'}, if(width >= height, height, width) / 2 * ${donut ? '0.5' : '0'})`
            },
            "outerRadius": {
              "signal": "if(selected && selected.dataKey == datum.dataKey, if(width >= height, height, width) / 2 * 1.05 * 0.8, if(width >= height, height, width) / 2 * 0.8)"
            },
            "opacity": {
              "signal": "if(selected && selected.dataKey !== datum.dataKey, 1, 1)"
            },
            "stroke": { "signal": "scale('color', datum.dataKey)" },
            "strokeWidth": { "signal": "strokeWidth" },
            "fillOpacity": {
              "signal": "if(selected && selected.dataKey == datum.dataKey, 0.8, 0.8)"
            }
          }
        }
      },
      {
        "type": "text",
        "encode": {
          "enter": { "fill": { "value": "#525252" }, "text": { "value": "" }, },
          "update": {
            "opacity": { "value": donut ? 1 : false },
            "x": { "signal": "width / 2" },
            "y": { "signal": "height / 2" },
            "align": { "value": "center" },
            "baseline": { "value": "middle" },
            "fontSize": { "signal": "if(width >= height, height, width) * 0.05" },
            "text": { "value": "Frameworks" }
          }
        }
      },
      {
        "name": "mark_dataValue",
        "type": "text",
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "text": {
              "signal": "if(datum['endAngle'] - datum['startAngle'] < 0.1, '', slice(datum['dataKey'], 0, 8))"
            },
            "x": { "signal": "if(width >= height, height, width) / 2" },
            "y": { "signal": "if(width >= height, height, width) / 2" },
            "radius": {
              "signal": "if(selected && selected.dataKey == datum.dataKey, if(width >= height, height, width) / 2 * 1.05 * 0.65, if(width >= height, height, width) / 2 * 0.65)"
            },
            "theta": { "signal": "(datum['startAngle'] + datum['endAngle'])/2" },
            "fill": { "value": "#FFFFFF" },
            "fontSize": { "value": 12 },
            "align": { "value": "center" },
            "baseline": { "value": "middle" }
          }
        }
      },
      {
        "name": "mark_dataValue1",
        "type": "text",
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "text": {
              "signal": "if(datum['endAngle'] - datum['startAngle'] < 0.1, '', format(datum['dataValue'], '.0f'))"
            },
            "x": { "signal": "if(width >= height, height, width) / 2" },
            "y": { "signal": "if(width >= height, height, width) / 2 + 13" },
            "radius": {
              "signal": "if(selected && selected.dataKey == datum.dataKey, if(width >= height, height, width) / 2 * 1.05 * 0.65, if(width >= height, height, width) / 2 * 0.65)"
            },
            "theta": { "signal": "(datum['startAngle'] + datum['endAngle'])/2" },
            "fill": { "value": "#FFFFFF" },
            "fontSize": { "value": 12 },
            "fontWeight": { "value": "bold" },
            "align": { "value": "center" },
            "baseline": { "value": "middle" }
          }
        }
      }
    ]
  }
}

// export function createBarChartSpec(colorsArray) {
//   return {
//     "$schema": "https://vega.github.io/schema/vega/v5.json",
//     "description": "A basic bar chart example, with value labels shown upon mouse hover.",
//     "width": 280,
//     "height": 200,
//     "data": [
//       {
//         "name": "table",
//         "transform": [
//           {
//             "type": "stack",
//             "field": "dataKey",
//             "sort": { "field": "dataKey", "order": "descending" }
//           }
//         ]
//       }
//     ],

//     "scales": [
//       {
//         "name": "xscale",
//         "type": "band",
//         "domain": {
//           "data": "table",
//           "field": "dataKey",
//           "sort": { "op": "sum", "field": "dataValue", "order": "descending" }
//         },
//         "range": "width",
//         "padding": 0.05,
//         "round": true
//       },
//       {
//         "name": "yscale",
//         "domain": { "data": "table", "field": "dataValue" },
//         "nice": true,
//         "round": true,
//         "range": "height"
//       },
//       { "name": "color", "type": "ordinal", "range": colorsArray }
//     ],
//     "axes": [{ "orient": "bottom", "scale": "xscale", "labelLimit": { "signal": "width / 5 " } }],
//     "marks": [
//       {
//         "type": "rect",
//         "from": { "data": "table" },
//         "encode": {
//           "enter": {
//             "tooltip": { "signal": "{title: datum.dataValue, '': datum.dataKey}" },

//             "x": { "scale": "xscale", "field": "dataKey" },
//             "width": { "scale": "xscale", "band": 1 },
//             "y": { "scale": "yscale", "field": "dataValue" },
//             "y2": { "scale": "yscale", "value": 0 }
//           },
//           "update": { "fill": { "scale": "color", "field": "dataKey" } }
//         }
//       },
//       {
//         "type": "text",
//         "from": { "data": "table" },
//         "encode": {
//           "enter": {
//             "x": { "scale": "xscale", "field": "dataKey", "band": 0.5 },
//             "y": { "scale": "yscale", "field": "dataValue", "offset": 12 },
//             "fill": [{ "value": "white" }],
//             "align": { "value": "center" },
//             "baseline": { "value": "middle" },
//             "text": { "field": "dataValue" },
//             "fontSize": { "value": 12 },
//             "fontWeight": { "value": 100 }
//           }
//         }
//       }
//     ]
//   }
// }

// If the data has also the key "dataCategory" it's a stacked bar chart, otherwise a normal bar chart

export function createStackedBarChartSpecUniv(data, normalise) {
  var transform = []
  var tooltip = "{title: datum.dataValue, '': datum.dataKey + (datum.dataGroup ? ' - ' + datum.dataGroup : '') }"
  if (normalise == true) {
    tooltip = "{title: format(datum.dataValue, ',.1%'), '': datum.dataKey + (datum.dataGroup ? ' - ' + datum.dataGroup : '') }"
    transform = [{
      "type": "joinaggregate",
      "groupby": ["dataKey"],
      "ops": ["sum"],
      "fields": ["dataValue"],
      "as": ["dataKey_total"]
    },
    {
      "type": "formula",
      "expr": "datum.dataValue / datum.dataKey_total",
      "as": "dataValue"
    }];
  }

  transform = transform.concat([{
    "type": "joinaggregate",
    "groupby": ["dataKey", "dataGroup"],
    "ops": ["sum"],
    "fields": ["dataValue"],
    "as": ["sum_dataValue"]
  },
  {
    "type": "stack",
    "groupby": ["dataKey"],
    "field": "sum_dataValue",
    "sort": { "field": ["dataGroup"], "order": ["ascending"] },
    "as": ["sum_dataValue_start", "sum_dataValue_end"],
    "offset": "zero"
  },
  {
    "type": "filter",
    "expr": "isValid(datum[\"sum_dataValue\"]) && isFinite(+datum[\"sum_dataValue\"])"
  }])

  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "background": "white",
    "width": 240,
    "height": 300,
    "data": [
      {
        "name": "table",
        "values": data,
        "transform": transform
      }
    ],
    "signals": [
      { "name": "y_step", "value": 20 },
      {
        "name": "height",
        "update": "bandspace(domain('y').length, 0.1, 0.05) * y_step"
      }
    ],
    "marks": [
      {
        "name": "marks",
        "type": "rect",
        "style": ["bar"],
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": {
              "signal": tooltip
            },
          },
          "update": {
            "fill": { "scale": "color", "field": "dataGroup" },
            "ariaRoleDescription": { "value": "bar" },
            "description": {
              "signal": "\"Sum of dataValue: \" + (format(datum[\"sum_dataValue\"], \"\")) + \"; dataKey: \" + (isValid(datum[\"dataKey\"]) ? datum[\"dataKey\"] : \"\"+datum[\"dataKey\"]) + \"; dataGroup: \" + (isValid(datum[\"dataGroup\"]) ? datum[\"dataGroup\"] : \"\"+datum[\"dataGroup\"])"
            },
            "x": { "scale": "x", "field": "sum_dataValue_end" },
            "x2": { "scale": "x", "field": "sum_dataValue_start" },
            "y": { "scale": "y", "field": "dataKey" },
            "height": { "scale": "y", "band": 1 }
          }
        }
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "linear",
        "domain": {
          "data": "table",
          "fields": ["sum_dataValue_start", "sum_dataValue_end"]
        },
        "range": [0, { "signal": "width" }],
        "nice": true,
        "zero": true
      },
      {
        "name": "y",
        "type": "band",
        "domain": { "data": "table", "field": "dataKey", "sort": { "op": "sum", "field": "dataKey", "order": "descending" } },
        "range": { "step": { "signal": "y_step" } },
        "paddingInner": 0.1,
        "paddingOuter": 0.05
      },
      {
        "name": "color",
        "type": "ordinal",
        "domain": { "data": "table", "field": "dataGroup", "sort": true },
        "range": "category"
      }
      // {
      //   "name": "color",
      //   "type": "ordinal",
      //   "range": colorsArray,
      //   "domain": { "data": "table", "field": "dataGroup", "sort": true },
      // }
    ],
    "axes": [
      {
        "scale": "x",
        "orient": "bottom",
        "gridScale": "y",
        "grid": true,
        "tickCount": { "signal": "ceil(width/40)" },
        "domain": false,
        "labels": false,
        "aria": false,
        "maxExtent": 0,
        "minExtent": 0,
        "ticks": false,
        "zindex": 0
      },
      {
        "scale": "x",
        "orient": "bottom",
        "grid": false,
        "title": null,
        "labelFlush": true,
        "format": normalise ? ".0p" : null,
        "labelOverlap": true,
        "tickCount": { "signal": "ceil(width/40)" },
        "zindex": 0
      },
      {
        "scale": "y",
        "orient": "left",
        "grid": false,
        "title": null,
        "zindex": 0
      }
    ],
    "legends": [
      {
        "fill": "color",
        "symbolType": "square",
        "title": "Legend",
        "direction": "horizontal",
        "orient": "bottom",
      }
    ]
  }
}

export function createHorizontalStackedBarChartSpecUniv(size, yTitle) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "A basic stacked bar chart example.",
    "background": "white",
    "width": 225,
    "height": size * 20,
    "autosize": "pad",
    "data": [
      {
        "name": "table",
        "values": [
          { "dataGroup": "Patents", "dataValue": 5, "dataKey": "UPCT" },
          { "dataGroup": "Pubs", "dataValue": 15, "dataKey": "UPCT" },
          { "dataGroup": "Projs", "dataValue": 25, "dataKey": "UPCT" },
          { "dataGroup": "Patents", "dataValue": 15, "dataKey": "UTT" },
          { "dataGroup": "Projs", "dataValue": 25, "dataKey": "UTT" },
          { "dataGroup": "Pubs", "dataValue": 55, "dataKey": "UTT" }
        ],
        "transform": [
          {
            "type": "joinaggregate",
            "ops": ["sum"],
            "groupby": ["dataKey"],
            "fields": ["dataValue"]
          },
          {
            "type": "formula",
            "expr": "parseFloat(format(datum.dataValue / datum.sum_dataValue * 100, '.2f'))",
            "as": "ratio"
          },
          {
            "type": "stack",
            "groupby": ["dataKey"],
            "field": "ratio",
            "sort": { "field": "dataGroup" },
            "as": ["x0", "x1"]
          },
          {
            "type": "formula",
            "expr": "parseFloat(format(datum.dataValue / datum.sum_dataValue, '.2f'))",
            "as": "ratio_op"
          },
          {
            "type": "stack",
            "groupby": ["dataKey"],
            "field": "ratio_op",
            "sort": { "field": "ratio_op" },
            "as": ["x0_op", "x1_op"]
          }
        ]
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "band",
        "range": "width",
        "domain": {
          "data": "table",
          "field": "ratio",
          "sort": { "field": "dataValue", "order": "descending" }
        }
      },
      {
        "name": "yBand",
        "type": "band",
        "range": "height",
        "domain": {
          "data": "table",
          "field": "dataKey",
          "sort": { "field": "dataKey", "order": "descending" }
        }
      },
      {
        "name": "xLinear",
        "type": "linear",
        "range": "width",
        "nice": true,
        "zero": true,
        "domain": { "data": "table", "fields": ["x0", "x1"] }
      },
      {
        "name": "xLinear_op",
        "type": "linear",
        "range": "width",
        "nice": true,
        "zero": true,
        "domain": { "data": "table", "fields": ["x0_op", "x1_op"] }
      },
      {
        "name": "color",
        "type": "ordinal",
        "range": "category",
        "domain": { "data": "table", "field": "dataGroup" }
      }
    ],
    "axes": [
      {
        "scale": "xLinear_op",
        "orient": "bottom",
        "grid": false,
        "format": "0.0%",
        "zindex": 0,
        "domainWidth": 0,
        "titlePadding": 15
      },
      {
        "scale": "yBand",
        "orient": "left",
        "grid": false,
        "title": yTitle,
        "zindex": 0,
        "domainWidth": 0,
        "titlePadding": 15
      }
    ],
    "legends": [
      {
        "fill": "color",
        "symbolType": "square",
        "columns": 2,

        "title": "Legend",
        "direction": "horizontal",
        "orient": "bottom"
      }
    ],
    "marks": [
      {
        "type": "group",
        "marks": [
          {
            "type": "rect",
            "from": { "data": "table" },
            "encode": {
              "enter": {
                "tooltip": {
                  "signal": "{title: datum.dataValue + ' (' + format(datum.ratio, '.2f') +'%)', '': datum.dataGroup + (datum.dataKey ? ' - ' + datum.dataKey : '') }"
                },
                "y": { "scale": "yBand", "field": "dataKey" },
                "height": { "scale": "yBand", "band": 1, "offset": -1 },
                "x": { "scale": "xLinear", "field": "x0" },
                "x2": { "scale": "xLinear", "field": "x1" },
                "fill": { "scale": "color", "signal": "datum.dataGroup" }
              },
              "update": { "fillOpacity": { "value": 1 } },
              "hover": { "fillOpacity": { "value": 0.5 } }
            }
          },
          {
            "type": "text",
            "from": { "data": "table" },
            "encode": {
              "enter": {
                "tooltip": {
                  "signal": "{title: datum.dataValue + ' (' + format(datum.ratio, '.2f') +'%)', '': datum.dataGroup + (datum.dataKey ? ' - ' + datum.dataKey : '') }"
                }
              },
              "update": {
                "text": { "signal": "datum.dataValue" },
                "fill": { "value": "#fff" },
                "align": { "value": "center" },
                "y": { "scale": "yBand", "field": "dataKey", "band": 1, "offset": size - (size * 3) },

                "x": { "scale": "xLinear", "signal": "(datum.x0 + datum.x1) / 2" },

              }
            }
          }
        ]
      }
    ]
  }
}


// Check
export function createStackedBarChartSpec(colorsArray) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "A basic stacked bar chart example.",
    "width": 270,
    "height": 50,
    "padding": 0,
    "data": [
      {
        "name": "table",
        "transform": [
          {
            "type": "stack",
            "groupby": ["dataGroup"],
            "field": "dataValue",
            "sort": { "field": "dataValue" },
            "as": ["x0", "x1"]

          }
        ]
      }
    ]
    ,
    "scales": [
      {
        "name": "x",
        "type": "band",
        "range": "width",
        "domain": {
          "data": "table",
          "field": "dataKey",
          "sort": {
            "op": "sum",
            "field": "dataValue",
            "order": "descending"
          }
        }
      },
      {
        "name": "yBand",
        "type": "band",
        "range": "height",
        "domain": {
          "data": "table",
          "field": "dataGroup",
          "sort": {
            "op": "sum",
            "field": "dataValue",
            "order": "descending"
          }
        }
      },
      {
        "name": "y",
        "type": "linear",
        "range": "height",
        "nice": true,
        "zero": true,
        "domain": { "data": "table", "field": "y1" }
      },
      {
        "name": "xLinear",
        "type": "linear",
        "range": "width",
        "nice": true,
        "zero": true,
        "domain": { "data": "table", "field": "x1" }
      },
      {
        "name": "color",
        "type": "ordinal",
        "range": colorsArray,
        "domain": {
          "data": "table",
          "field": "dataKey",
          "sort": { "op": "sum", "field": "dataValue", "order": "descending" }
        }
      }
    ],
    "axes": [
      { "orient": "bottom", "scale": "xLinear", "zindex": 1, "labelLimit": { "signal": "width / 5 " } }
    ],
    "marks": [
      {
        "type": "rect",
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": {
              "signal": "{title: datum.dataValue, '': datum.dataKey + (datum.dataCategory ? ' - ' + datum.dataCategory : '') }"
            },
            "y": { "scale": "yBand", "field": "dataGroup" },
            // "width": { "scale": "x", "band": 1, "offset": -1 },
            "height": { "scale": "yBand", "band": 1, "offset": -1 },
            "x": { "scale": "xLinear", "field": "x0" },
            "x2": { "scale": "xLinear", "field": "x1" },
            "fill": {
              "scale": "color",
              "signal": "datum.dataCategory ? datum.dataCategory : datum.dataKey"
            }
          },
          "update": { "fillOpacity": { "value": 1 } },
          "hover": { "fillOpacity": { "value": 0.5 } }
        }
      }
      //   // , 
      //   // {
      //   //   "type": "text",
      //   //   "from": { "data": "table" },
      //   //   "encode": {
      //   //     "enter": {
      //   //       "x": { "scale": "x", "field": "dataKey", "band": 0.5 },
      //   //       "y": { "scale": "y", "field": "y1", "offset": 12 },
      //   //       "fill": [{ "value": "white" }],
      //   //       "align": { "value": "center" },
      //   //       "baseline": { "value": "middle" },
      //   //       "text": { "field": "dataValue" },
      //   //       "fontSize": { "value": 12 },
      //   //       "fontWeight": { "value": 100 }
      //   //     }
      //   //   }
      //   // }
    ]
  }
}
// Check
export function createStackedBarChartPercentageSpec(colorsArray) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "A basic stacked bar chart example.",
    "width": 270,
    "height": 50,
    "padding": 0,
    "data": [
      {
        "name": "table",
        "transform": [
          { "type": "joinaggregate", "ops": ["sum"], "fields": ["dataValue"] },
          {
            "type": "formula",
            "expr": "parseFloat(format(datum.dataValue / datum.sum_dataValue * 100, '.2f'))",
            "as": "ratio"
          },
          {
            "type": "stack",
            "groupby": ["dataGroup"],
            "field": "ratio",
            "sort": { "field": "ratio" },
            "as": ["x0", "x1"]
          }
        ]
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "band",
        "range": "width",
        "domain": {
          "data": "table",
          "field": "dataKey",
          "sort": { "op": "sum", "field": "x1", "order": "descending" }
        }
      },
      {
        "name": "yBand",
        "type": "band",
        "range": "height",
        "domain": {
          "data": "table",
          "field": "dataGroup",
          "sort": { "op": "sum", "field": "dataValue", "order": "descending" }
        }
      },
      {
        "name": "y",
        "type": "linear",
        "range": "height",
        "nice": true,
        "zero": true,
        "domain": { "data": "table", "field": "y1" }
      },
      {
        "name": "xLinear",
        "type": "linear",
        "range": "width",
        "nice": true,
        "zero": true,
        "domain": { "data": "table", "field": "x1" }
      },
      {
        "name": "color2",
        "type": "ordinal",
        "range": "category",
        "domain": { "data": "table", "field": "dataKey" }
      },
      {
        "name": "color",
        "type": "ordinal",
        "range": colorsArray,
        "domain": {
          "data": "table",
          "field": "dataKey",
          "sort": { "op": "sum", "field": "dataValue", "order": "descending" }
        }
      }
    ],
    "axes": [
      {
        "orient": "bottom",
        "scale": "xLinear",
        "zindex": 1,
        "labelLimit": { "signal": "width / 5 " }
      }
    ],
    "marks": [
      {
        "type": "rect",
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": {
              "signal": "{title: datum.dataValue + ' (' + format(datum.ratio, '.2f') +'%)', '': datum.dataKey + (datum.dataCategory ? ' - ' + datum.dataCategory : '') }"
            },
            "y": { "scale": "yBand", "field": "dataGroup" },
            "height": { "scale": "yBand", "band": 1, "offset": -1 },
            "x": { "scale": "xLinear", "field": "x0" },
            "x2": { "scale": "xLinear", "field": "x1" },
            "fill": {
              "scale": "color",
              "signal": "datum.dataCategory ? datum.dataCategory : datum.dataKey"
            }
          },
          "update": { "fillOpacity": { "value": 1 } },
          "hover": { "fillOpacity": { "value": 0.5 } }
        }
      }
    ]
  }
}

export function createVerticalBarChartSpec(colorsArray) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "A bar chart showing the US population distribution of dataKey groups in 2000.",
    "width": 200,
    "style": "cell",
    "data": [
      {
        "name": "table",
      }
    ],
    "signals": [
      { "name": "y_step", "value": 17 },
      {
        "name": "height",
        "update": "bandspace(domain('y').length, 0.1, 0.05) * y_step"
      }
    ],
    "marks": [
      {
        "name": "marks",
        "type": "rect",
        "style": ["bar"],
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": tooltipStructure,
          },
          "update": {
            "fill": { "scale": "color", "field": "dataKey" },
            "ariaRoleDescription": { "value": "bar" },
            "description": {
              "signal": "\"population: \" + (format(datum[\"dataValue\"], \"\")) + \"; dataKey: \" + (isValid(datum[\"dataKey\"]) ? datum[\"dataKey\"] : \"\"+datum[\"dataKey\"])"
            },
            "x": { "scale": "x", "field": "dataValue" },
            "x2": { "scale": "x", "value": 0 },
            "y": { "scale": "y", "field": "dataKey" },
            "height": { "scale": "y", "band": 1 }
          }
        }
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "linear",
        "domain": { "data": "table", "field": "dataValue" },
        "range": [0, { "signal": "width" }],
        "nice": true,
        "zero": true
      },
      {
        "name": "y",
        "type": "band",
        "domain": { "data": "table", "field": "dataKey", "sort": { "op": "sum", "field": "dataValue", "order": "descending" } },
        "range": { "step": { "signal": "y_step" } },
        "paddingInner": 0.1,
        "paddingOuter": 0.05
      },
      {
        "name": "color", "type": "ordinal", "range": colorsArray, "domain": {
          "data": "table",
          "field": "dataKey",
          "sort": { "op": "sum", "field": "dataValue", "order": "descending" }
        }
      }
    ],
    "axes": [
      {
        "scale": "x",
        "orient": "bottom",
        "gridScale": "y",
        "grid": true,
        "tickCount": { "signal": "ceil(width/40)" },
        "domain": false,
        "labels": false,
        "aria": false,
        "maxExtent": 0,
        "minExtent": 0,
        "ticks": false,
        "zindex": 0
      },
      {
        "scale": "x",
        "orient": "bottom",
        "grid": false,
        "labelFlush": true,
        "labelOverlap": true,
        "tickCount": { "signal": "ceil(width/40)" },
        "zindex": 0
      },
      { "scale": "y", "orient": "left", "grid": false, "zindex": 0, "labelLimit": 80 }
    ]
  }
}

export function createLineChartSpec(data, colorsArray) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "A basic line chart example.",
    "width": 250,
    "height": 130,
    "data": [
      {
        "name": "table",
        "values": data,
        "transform": [
          { "type": "formula", "expr": "parseInt(datum.dataKey)", "as": "dataKey" },
          {
            "type": "aggregate",
            "fields": ["dataValue"],
            "groupby": ["dataKey"],
            "ops": ["sum"],
            "as": ["dataValue"]
          },
          { "type": "collect", "sort": { "field": "dataKey" } }
        ]
      }
    ],
    "scales": [
      {
        "name": "x",
        "type": "point",
        "range": "width",
        "domain": {
          "data": "table",
          "field": "dataKey",
          "sort": { "op": "sum", "field": "dataKey", "order": "ascending" }
        }
      },
      {
        "name": "y",
        "type": "linear",
        "range": "height",
        "nice": true,
        "zero": true,
        "domain": { "data": "table", "field": "dataValue" }
      },
      { "name": "color", "type": "ordinal", "range": colorsArray }
    ],
    "axes": [
      { "orient": "bottom", "scale": "x" },
      { "orient": "left", "scale": "y" }
    ],
    "marks": [
      {
        "type": "line",
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": tooltipStructure,
            "x": { "scale": "x", "field": "dataKey" },
            "y": { "scale": "y", "field": "dataValue" },
            "stroke": { "scale": "color", "field": "dataKey" },
            "strokeWidth": { "value": 2 }
          },
          "update": {
            "interpolate": { "value": "monotone" },
            "strokeOpacity": { "value": 0.6 }
          },
          "hover": { "strokeOpacity": { "value": 0.5 } }
        }
      },
      {
        "name": "layer_1_marks",
        "type": "symbol",
        "style": ["point"],
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": tooltipStructure
          },
          "update": {
            "size": { "value": 50 },
            "opacity": { "value": 1 },
            "fill": { "scale": "color", "field": "symbol" },
            "ariaRoleDescription": { "value": "point" },
            "x": { "scale": "x", "field": "dataKey" },
            "y": { "scale": "y", "field": "dataValue" }
          }
        }
      }
    ]
  }
}

export function createTreeMapSpec(colorsArray, colorDomain) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "width": 280,
    "height": 300,
    "padding": 0,
    "signals": [
      {
        "name": "selected",
        "value": "",
        "on": [
          { "events": "mouseover", "update": "datum" },
          { "events": "mouseout", "update": { "value": 0 } }
        ]
      }
    ],
    "data": [
      {
        "name": "table",
        "transform": [
          { "type": "stratify", "key": "dataKey", "parentKey": "parent" },
          {
            "type": "treemap",
            "field": "dataValue",
            // "method": "slicedice",
            "method": "squarify",
            "ratio": 1,
            "size": [{ "signal": "width" }, { "signal": "height" }]
          }
        ]
      }
    ],
    "scales": [
      {
        "name": "color", "type": "ordinal", "range": colorsArray, "domain": colorDomain,
      }
    ],
    "marks": [
      {
        "type": "rect",
        "from": { "data": "table" },
        "encode": {
          "enter": {
            "tooltip": { "signal": "{title: datum.dataValue, '': datum.dataKey}" },


            "fill": { "scale": "color", "field": "dataKey" },
            "opacity": {
              "signal": "if(selected && selected.dataKey !== datum.dataKey, 0.8, 1)"
            }
          },
          "update": {
            "x": { "field": "x0" },
            "x2": { "field": "x1" },
            "y": { "field": "y0" },
            "y2": { "field": "y1" },
            "opacity": {
              "signal": "if(selected && selected.dataKey !== datum.dataKey, 0.8, 1)"
            }
          }
        }
      },
      {
        "type": "text",
        "from": { "data": "table" },
        "encode": {
          "update": {
            "fontSize": { "value": 14 },
            "fill": { "value": "#fff" },
            "opacity": {
              "signal": "if(selected && selected.dataKey !== datum.dataKey, 0.5, 1)"
            },
            "text": { "field": "dataValue" },
            "fontWeight": { "value": 400 },
            "align": { "value": "center" },
            "baseline": { "value": "middle" },
            "x": { "signal": "(datum.x0 + datum.x1) / 2 " },
            "x2": { "signal": "datum.x1" },
            "y": { "signal": "(datum.y0 + datum.y1) / 2 - 10" },
            "y2": { "signal": "datum.y1" }
          }
        }
      },
      {
        "type": "text",
        "from": { "data": "table" },
        "encode": {
          "update": {
            "fontSize": { "value": 15 },
            "fill": { "value": "#fff" },
            "opacity": {
              "signal": "if(selected && selected.dataKey !== datum.dataKey, 0.5, 1)"
            },
            "text": { "field": "dataKey" },
            "fontWeight": { "value": 700 },
            "align": { "value": "center" },
            "baseline": { "value": "middle" },
            "x": { "signal": "(datum.x0 + datum.x1) / 2 " },
            "x2": { "signal": "datum.x1" },
            "y": { "signal": "(datum.y0 + datum.y1) / 2 + 10" },
            "y2": { "signal": "datum.y1" }
          }
        }
      }
    ]
  }
}

export function createSpecIndexChart(data, limitMax, colorsArray) {
  return {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "description": "A basic grouped bar chart example.",
    "width": 200,
    "height": 100,
    "padding": 5,

    "data": [
      {
        "name": "table",
      }
    ],

    "scales": [
      {
        "name": "yscale",
        "type": "band",
        "domain": { "data": "table", "field": "dataKey", "sort": { "op": "sum", "field": "dataKey", "order": "descending" } },
        "range": "height",
        "padding": 0.1
      },
      {
        "name": "xscale",
        "type": "linear",
        "domain": [-limitMax + 2, limitMax],
        "range": "width",
        "round": true,
        "zero": true,
        "nice": true
      },
      { "name": "color", "type": "ordinal", "range": colorsArray }
    ],

    "axes": [
      { "orient": "left", "scale": "yscale", "tickSize": 0, "labelPadding": 4, "zindex": 1 },
      { "orient": "bottom", "scale": "xscale" }
    ],

    "marks": [
      {
        "type": "group",

        "from": {
          "facet": {
            "data": "table",
            "name": "facet",
            "groupby": "dataKey"
          }
        },

        "encode": {
          "enter": {
            "y": { "scale": "yscale", "field": "dataKey" }
          }
        },

        "signals": [
          { "name": "height", "update": "bandwidth('yscale')" }
        ],

        "scales": [
          {
            "name": "pos",
            "type": "band",
            "range": "height",
            "domain": { "data": "facet", "field": "position" }
          }
        ],

        "marks": [
          {
            "name": "bars",
            "from": { "data": "facet" },
            "type": "rect",
            "encode": {
              "enter": {
                "tooltip": tooltipStructure,

                "y": { "scale": "pos", "field": "position" },
                "height": { "scale": "pos", "band": 1 },
                "x": { "scale": "xscale", "signal": "1" },
                "x2": { "scale": "xscale", "signal": "datum.dataValue" },
                "fill": { "scale": "color", "field": "position" }
              }
            }
          },
          {
            "type": "text",
            "from": { "data": "bars" },
            "encode": {
              "enter": {
                "x": { "field": "x2", "offset": -5 },
                "y": { "field": "y", "offset": { "field": "height", "mult": 0.5 } },
                "fill": [
                  { "test": "contrast('white', datum.fill) > contrast('black', datum.fill)", "value": "white" },
                  { "value": "black" }
                ],
                "align": { "value": "right" },
                "baseline": { "value": "middle" },
                "text": { "field": "datum.value" }
              }
            }
          }
        ]
      }
    ]
  }
}