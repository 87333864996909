import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import eutLogo from '../../assets/img/eut-header-logo.png'
import './index.scss';
import { NavLink } from 'react-router-dom';

class EutNavbar extends Component {

    render() {
        return (
            <Navbar expand="lg">
                <>
                    <NavLink to='/' className='nav-link'>
                        <Navbar.Brand>
                            <img className='eut-logo' src={eutLogo}></img>
                        </Navbar.Brand>
                    </NavLink>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink to='/' className='nav-link'>Home</NavLink>
                            {/* <NavLink to='/report' className='nav-link'>Report</NavLink> */}
                            {/* <NavLink to='/slide-deck' className='nav-link'>Slide deck</NavLink> */}
                            <NavLink to='/about' className='nav-link'>About</NavLink>
                            <NavLink to='/contact' className='nav-link'>Contact</NavLink>
                            <NavLink to='/dashboard' className='nav-link'>Platform</NavLink>
                        </Nav>
                    </Navbar.Collapse>
                </>
            </Navbar>
        )
    }
}

export default EutNavbar