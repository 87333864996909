import React, { Component, Fragment } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

import {
    getProjectParticipantsInformation,
    getAbstract
} from '../../data/DataService'

import '../AnalyticsModal/index.scss';
import ReactTooltip from '../ReactTooltip';

import { Vega } from 'react-vega'
import * as specs from '../VegaCharts/specs/specs'

import { Accordion } from 'react-bootstrap'
import {
    CONFIG,
    UNI_NAME_MAPPING,
    UNI_COLORS_MAPPING,
} from '../../common/constants'


class AnalyticsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abstract: 'Loading...',
            truncateText: true,
            showPublications: true
        }
    }

    async componentDidMount() {

        if (!this.props.clickedDot) return;
        let abstract = await getAbstract(this.props.clickedDot)

        this.setState({
            abstract: abstract
        })
    }

    getHighlightedAbstract() {
        let currentKeyword = this.props.filters.selected.filter(x => x.type == 'keyword_search')
        // currentKeyword = currentKeyword.length > 0 ? currentKeyword[0].values[0].split(/[\s,]+/) : null;
        currentKeyword = currentKeyword.length > 0 ? currentKeyword.map(x => x.values)[0][0] : null;

        if (!currentKeyword) return this.state.abstract

        var text = ''
        this.state.abstract.split(' ').forEach(word => {
            currentKeyword.forEach(keyword => {

                // console.log("Comparing", word.toLowerCase().replace(/\W/g, '').trim(), keyword.toLowerCase())
                if (word.toLowerCase().replace(/\W/g, '').trim() == keyword.toLowerCase()) {
                    word = '<span style="background-color: #ffff00 !important; padding: 0.2rem; border-radius: 5px; font-weight: bold;">' + word + '</span>'
                    // word = '<mark>' + keyword + '</mark>'
                }
            });
            text += (word + ' ');
        });

        return text
    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.clickedDot !== prevProps.clickedDot || this.props.clickedLabel !== prevProps.clickedLabel) {
            let participantsInProject = await getProjectParticipantsInformation(this.props.clickedDot);
            this.setState({
                projectParticipantsInfo: participantsInProject,
            })
        }
    }

    calculateTopicInformation = (topicId) => {
        var aggregates = {
            totalProjects: 0,
            nationalProjects: 0,
            h2020Projects: 0,
            patents: 0,
            publications: 0,
            universitySourceDistribution: {},
            typeDistributionOnAvg: {},
            typeDistribution: {
            },
            yearDistribution: {},
            SDGDistribution: {},
        }

        const projectsInTopic = _.filter(this.props.data.topics.data, project => project.cluster === topicId)
        const topicSummary = this.props.data.topics.summary
            .filter(x => x.cluster_n == this.props.clickedLabel)


        var typeDistributionObj = _(topicSummary)
            .groupBy('type')
            .map((doc, id) => ({
                dataKey: id,
                dataValue: _.sumBy(doc, 'n_docs'),
            }))
            .value()

        var universitySourceDistribution = { 'table': [] }
        var grouped = _.groupBy(topicSummary, function (i) {
            return i.type + '$' + i.uni
        })


        Object.keys(grouped).forEach(group => {
            var obj = {}
            let split_group = group.split('$')
            obj['dataGroup'] = split_group[0]
            obj['dataKey'] = split_group[1]

            
            // sum array of object with property
            var sum = _.sumBy(grouped[group], 'n_docs')
            
            obj['dataValue'] = sum

            universitySourceDistribution.table.push(obj)
        });



        // ? Type distribution average (spec index on sources)
        var typeDistributionOnAvg = {
            table: []
        }

        var groupByTypeAndCluster = _.groupBy(this.props.data.topics.summary, function (i) {
            return i.type + '$' + i.cluster_n
        })

        var docPerTypeTotals = [];
        Object.keys(groupByTypeAndCluster).forEach(group => {
            var obj = {}
            let split_group = group.split('$')
            obj['type'] = split_group[0]
            obj['cluster'] = split_group[1]
            obj['sum_docs'] = _.sumBy(groupByTypeAndCluster[group], 'n_docs')
            docPerTypeTotals.push(obj)
        });

        var total_topics = _.uniqBy(this.props.data.topics.summary
            .filter(x => this.state.showPublications ? true : x.type != 'Publications'), 'cluster_n').length

        // typeDistributionOnAvg = {
        //     table: _(docPerTypeTotals)
        //         .groupBy('type')
        //         .map((doc, id) => ({
        //             dataKey: id,
        //             dataValue: (_.sumBy(doc, 'sum_docs')),
        //         }))
        //         .value()
        // }

        // console.log("21-dec, first", typeDistributionOnAvg)

        // var typeDistributionOnAvgfinalresults = {
        //     table: []
        // }
        // typeDistributionOnAvg.table.map(x => x.dataKey).forEach(t_type => {
        //     var totalsobjtype = typeDistributionObj.filter(x => x.dataKey == t_type);
        //     var xobjtype = typeDistributionOnAvg.table.filter(x => x.dataKey == t_type)
        //     if (totalsobjtype.length > 0 && xobjtype.length > 0)
        //         typeDistributionOnAvgfinalresults.table.push({
        //             dataKey: t_type,
        //             dataValue: ((totalsobjtype[0].dataValue / xobjtype[0].dataValue)).toFixed(2)
        //         })
        // });

        var typeDistributionOnAllTopics =
            _(this.props.data.topics.summary)
                .groupBy('type')
                .map((doc, id) => ({
                    type: id,
                    n: (_.sumBy(doc, 'n_docs')),
                }))
                .value()


        var totalNumberOfDoc = _(topicSummary)
            .groupBy('type')
            .map((doc, id) => ({
                type: id,
                n: (_.sumBy(doc, 'n_docs')),
            }))
            .value().reduce((acc, { n }) => acc + n, 0)

        var totalNumberOfDocOnAllTopics = typeDistributionOnAllTopics.reduce((acc, { n }) => acc + n, 0)
        typeDistributionOnAvg = {
            table: _(topicSummary)
                .groupBy('type')
                .map((doc, id) => ({
                    dataKey: id,
                    dataValue: (((_.sumBy(doc, 'n_docs') / typeDistributionOnAllTopics.filter(x => x.type == id)[0].n)) / (totalNumberOfDoc / totalNumberOfDocOnAllTopics)).toFixed(2),
                }))
                .value()
        }



        aggregates = {
            totalProjects: projectsInTopic.length,
            nationalProjects: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "National projects"), 'n_docs') : 0,
            h2020Projects: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "H2020"), 'n_docs') : 0,
            patents: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "Patents"), 'n_docs') : 0,
            publications: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "Publications"), 'n_docs') : 0,
            universitySourceDistribution: universitySourceDistribution,
            typeDistributionOnAvg: typeDistributionOnAvg,
            typeDistribution: { // sources distribution chart 
                table: typeDistributionObj
            },
            yearDistribution: { // trend chart
                table: _.sortBy(_(topicSummary.filter(x => x.year > 2013 && x.year < 2022).filter(x => this.state.showPublications ? true : x.type != 'Publications'))
                    .groupBy('year')
                    .map((doc, id) => ({
                        dataKey: id,
                        dataValue: _.sumBy(doc, 'n_docs'),
                    }))
                    .value(), 'dataKey').filter(x => /*x.dataKey >= 2014 && */x.dataKey <= 2020)
            },
            SDGDistribution: _.countBy(projectsInTopic.reduce((acc, val) => {
                if (val.SDGs)
                    val.SDGs.forEach(el => {
                        acc.push({ projectId: val.id, SDGs: [el][0] });
                    });
                return acc;
            }, []), 'SDGs'),
        }

        return aggregates;
    }

    paintClusterKeywords = (keywords) => {
        const keywordsArray = keywords.split(", ").map(x => x.replace(',', '')).filter(x => x.trim() != '-' && x.trim() != '(' && x.trim() != ')');
        const htmlContent = <p className=''>{keywordsArray.map((keyword, index) => <span key={index} className='badge bg-light me-1'>{keyword}</span>)}</p>;
        return htmlContent;
    }

    paintProjectKeywords = (keywords) => {
        const keywordsArray = keywords.split(",").slice(0, 25);
        const htmlContent = <p className=''>{keywordsArray.map((keyword, index) => <span key={index} className='badge bg-light me-1'>{keyword}</span>)}</p>;
        return htmlContent;
    }

    generateColorPalette = (color) => {
        var palette = [];
        if (!color) return ['rgb(0,0,0)'];
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        var start_rgb = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        }

        palette.push({ r: start_rgb.r, g: start_rgb.g, b: start_rgb.b })

        for (var i = 0; i < 8; i++) {
            palette.push({ r: (palette[palette.length - 1].r / 1.15).toFixed(0), g: (palette[palette.length - 1].g / 1.15).toFixed(0), b: (palette[palette.length - 1].b / 1.15).toFixed(0) })
        }

        return palette.map(x => {
            return `rgb(${x.r}, ${x.g}, ${x.b})`
        })
    }

    slicePalette = (palette, n) => {
        return palette.slice(0, n).reverse()
    }

    organiseDataForVega = (data) => {
        return {
            table: Object.entries(data).map(([dataKey, dataValue]) => ({
                dataKey,
                dataValue
            })).filter(x => x.dataKey != 'N/A')
        }
    }

    render() {


        function handleHover(...args) {
            console.log("HANDLE HOVER", args);
        }

        const signalListeners = { tooltip: handleHover };

        const { projectParticipantsInfo } = this.state;


        const currentProject = this.props.getProjectInformation(this.props.clickedDot);
        const currentTopic = this.props.getTopicInformation(this.props.clickedLabel);
        console.log("CURRTOPIC", currentTopic)

        const clusterColor = _.find(this.props.data.topics.data, project => project.cluster === this.props.clickedLabel).dotColor;

        // Calculate 
        const currentTopicAggregates = this.calculateTopicInformation(this.props.clickedLabel);

        var SDGDistributionVegaValue = this.organiseDataForVega(currentTopicAggregates.SDGDistribution);

        SDGDistributionVegaValue.table.forEach(sdg => {
            sdg['parent'] = " "
        });
        SDGDistributionVegaValue.table.unshift({ "dataKey": " ", "parent": null, "dataValue": null })
        SDGDistributionVegaValue.table = SDGDistributionVegaValue.table.map(x => { x.dataKey = x.dataKey.replace('ODS', 'SDG'); return x })

        var topic_palette = this.generateColorPalette(clusterColor)
        // Still doesn't work, to change
        var SDGPalette = {
            'SDG 1': 'rgb(229,36,59)',
            'SDG 2': 'rgb(221,166,58)',
            'SDG 3': 'rgb(76,159,56)',
            'SDG 4': 'rgb(197,25,45)',
            'SDG 5': 'rgb(255,58,33)',
            'SDG 6': 'rgb(38,189,226)',
            'SDG 7': 'rgb(252,195,11)',
            'SDG 8': 'rgb(162,25,66)',
            'SDG 9': 'rgb(253,105,37)',
            'SDG 10': 'rgb(221,19,103)',
            'SDG 11': 'rgb(253,157,36)',
            'SDG 12': 'rgb(191,139,46)',
            'SDG 13': 'rgb(63,126,68)',
            'SDG 14': 'rgb(10,151,217 )',
            'SDG 15': 'rgb(86,192,43)',
            'SDG 16': 'rgb(0,104,157)',
            'SDG 17': 'rgb(25,72,106)',
        }

        
        return <Fragment>
            {/* <h6 className='text-uppercase'><small>Topic</small></h6> */}
            <div className='p-3'>
                {
                    this.props.clickedDot
                    && <>
                        <div className=''>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h6 className="panel-title text-uppercase">Document title</h6>
                                    <h5 className='modal-text'>{currentProject[0] ? currentProject[0].title : ''}</h5>
                                </div>
                            </div>

                            <div className='d-flex justify-content-between mt-3'>
                                <div className=''>
                                    <h6 className="panel-title text-uppercase">Type</h6>
                                    <h5 className='modal-text'>{currentProject[0].type}</h5>
                                </div>
                                <div className=''>
                                    <h6 className="panel-title text-uppercase">University</h6>
                                    <h5 className='modal-text'>{UNI_NAME_MAPPING[currentProject[0].university]}</h5>
                                </div>
                                <div className='me-3'>
                                    <div>
                                        <h6 className="panel-title text-uppercase">Year</h6>
                                        <h5 className='modal-text'>{currentProject[0].year}</h5>
                                    </div>
                                </div>

                            </div>

                            <h6 className="panel-title text-uppercase mt-3">Abstract</h6>

                            <h5 dangerouslySetInnerHTML={{
                                __html: this.state.truncateText && this.state.abstract.length > 500
                                    ? (this.getHighlightedAbstract().slice(0, 500).trim() + '...')
                                    : this.getHighlightedAbstract()
                            }} className='modal-text modal-abstract'>

                            </h5>
                            <small className='read-more text-muted' onClick={() => {
                                this.setState({
                                    truncateText: !this.state.truncateText
                                })
                            }}>{this.state.abstract.length > 500 ? (this.state.truncateText ? 'Read more' : 'Hide') : ''}</small>

                            {currentProject[0].keywords != '' ? <h6 className="panel-title text-uppercase mt-3">Keywords</h6> : <></>}
                            <div className="row mt-1">
                                <div className="col">{this.paintProjectKeywords(currentProject[0].keywords)}
                                </div>
                            </div>
                            
                            {currentProject[0].type == "Publications" && currentProject[0].journal && currentProject[0].journal != "" ?
                                <>
                                    <div className='mt-3'>
                                    <div>
                                        <h6 className="panel-title text-uppercase">Publication venue</h6>
                                        <h5 className='modal-text'>{currentProject[0].journal}</h5>
                                    </div>
                                </div>
                                </> : <></>}

                            {(currentProject[0].type == "Publications" && currentProject[0].doi != "") || (currentProject[0].type == "H2020") ?
                                <>
                                    <h6 className="panel-title text-uppercase mt-3">Link</h6>
                                    <div className="row">
                                        <div className="col">
                                            {currentProject[0].type == "Publications" ? <a href={`https://explore.openaire.eu/search/publication?pid=${currentProject[0].doi}`} target="_blank">Go to OpenAIRE</a> : currentProject[0].type == "H2020" ? <a href={`https://cordis.europa.eu/project/id/${currentProject[0].originalID}`} target="_blank">Go to CORDIS</a> : ''}
                                        </div>
                                    </div>
                                </>
                                : <></>
                            }

                            {projectParticipantsInfo && projectParticipantsInfo.length > 0 ?
                                <>
                                    <h6 className="panel-title mt-0">Participants organisations</h6>
                                    <ul className='mb-4 list-group'>
                                        {
                                            _.map(projectParticipantsInfo, (participant, index) => {
                                                return <li key={index} className='list-group-item'>
                                                    {/* <small className='d-block text-uppercase'>{participant.countryName}</small> */}
                                                    {participant.ecParticipantName}  <br />
                                                    {/* <strong>{d3.format(',')(participant.ecContribution)}€</strong> */}
                                                </li>
                                            })
                                        }
                                    </ul>
                                </> : ''}
                        </div>
                    </>}
                <> {!this.props.clickedDot ?
                    <div className='clickedDotCharts'>
                        <section id="title">
                            <h6 className="panel-title text-uppercase">Title of the topic</h6>
                            <div className='d-flex mt-2'>
                                <div style={{ '--tip-color': clusterColor }} className='left-tip'></div>
                                <h5 style={{ color: '#333' }} className='my-auto modal-text topic-title'>{currentTopic[0].topic_label}</h5>
                            </div>
                        </section>

                        <section id="n_documents">
                            <div className='d-flex justify-content-between w-75 mt-4'>
                                <div>
                                    <p className='panel-title text-uppercase'><small>National projects</small></p>
                                    <h4>{currentTopicAggregates.nationalProjects}</h4>
                                </div>
                                <div>
                                    <p className='panel-title text-uppercase'><small>H2020 Projects</small></p>
                                    <h4>{currentTopicAggregates.h2020Projects}</h4>
                                </div>
                                <div>
                                    <p className='panel-title text-uppercase'><small>Patents</small></p>
                                    <h4>{currentTopicAggregates.patents}</h4>
                                </div>
                                {this.state.showPublications ?
                                    <div>
                                        <p className='panel-title text-uppercase'><small>Publications</small></p>
                                        <h4>{currentTopicAggregates.publications}</h4>
                                    </div> : <></>}
                            </div>
                        </section>

                        <section id="keywords">
                            <h6 className="panel-title text-uppercase mt-4">Most distinctive keywords of the topic</h6>
                            <div className="row mt-2">
                                <div className="col kw-container">{this.paintClusterKeywords(currentTopic[0].topic_words)}
                                </div>
                            </div>
                        </section>

                        {/* <section id="show_publications" className='d-none'>
                            <div className="mt-4 mb-3">
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="form-check" onClick={() => {
                                            this.setState({
                                                showPublications: !this.state.showPublications
                                            })
                                            this.calculateTopicInformation(this.props.clickedLabel);
                                        }}>
                                            <input className="form-check-input" type="checkbox" checked={this.state.showPublications} />
                                            <label className="form-check-label" for="flexCheckChecked">
                                                {this.state.showPublications}
                                                Show publications
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        <section id="vega-charts">
                            <div className="mt-5">
                                <div className='row'>
                                    <div className='col-6'>
                                        <h6 className="panel-title text-uppercase mb-3">Number of documents
                                            <ReactTooltip color="black" tooltipText="Number of documents per year in this topic in the range 2014-2021"></ReactTooltip>
                                        </h6>

                                        {/* <Vega spec={specs.createLineChartSpec(topic_palette)} data={currentTopicAggregates.yearDistribution} /> */}
                                        <Vega spec={specs.createLineChartSpec(currentTopicAggregates.yearDistribution.table, topic_palette)} />
                                    </div>

                                    <div className='col-6'>
                                        <h6 className="panel-title text-uppercase mb-3">Relative presence of the data sources in this topic
                                            {/* TODO */}
                                            <ReactTooltip color="black" tooltipText="Data sources with a relative presence higher than 1 are relatively more present in this topic than in the average topic."></ReactTooltip>

                                        </h6>
                                        <Vega
                                            spec={specs.createSpecIndexChart(currentTopicAggregates.typeDistributionOnAvg.table, Math.ceil(Math.max(...currentTopicAggregates.typeDistributionOnAvg.table.map(x => parseFloat(x.dataValue)))), this.slicePalette(topic_palette, currentTopicAggregates.typeDistributionOnAvg.table.length))}
                                            data={currentTopicAggregates.typeDistributionOnAvg}
                                        />
                                    </div>
                                </div>

                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        <h6 className="panel-title text-uppercase mb-3">Number of documents by university</h6>
                                        <Vega
                                            spec={specs.createStackedBarChartSpecUniv(currentTopicAggregates.universitySourceDistribution.table, false)} />
                                    </div>

                                    <div className='col-6'>
                                        <h6 className="panel-title text-uppercase mb-3">Share of document types by university</h6>
                                        <Vega
                                            spec={specs.createStackedBarChartSpecUniv(currentTopicAggregates.universitySourceDistribution.table, true)} />
                                    </div>
                                </div>



                                {/* <h6 className="mt-4 panel-title">Sources distribution</h6>
                            <Vega
                                spec={specs.createVerticalBarChartSpec(this.slicePalette(topic_palette, currentTopicAggregates.typeDistribution.table.length))}
                                data={currentTopicAggregates.typeDistribution} /> */}


                                {SDGDistributionVegaValue.table.length > 1 && false ?
                                    <>
                                        <h6 className="mt-4 panel-title">SDG distribution</h6>
                                        <Vega spec={specs.createTreeMapSpec(Object.values(SDGPalette), Object.keys(SDGPalette))} data={SDGDistributionVegaValue} />
                                    </> : ''}
                            </div>
                        </section>
                    </div> : <></>}
                </>
            </div>
        </Fragment >
    }
}

export default AnalyticsModal;