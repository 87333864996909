import React, { Component, Fragment, useEffect, useRef } from 'react';
import Cytoscape from 'cytoscape';
import COSEBilkent from 'cytoscape-cose-bilkent';
import d3Force from 'cytoscape-d3-force';
import CytoscapeComponent from 'react-cytoscapejs';
// import popper from 'cytoscape-popper';
import * as d3 from 'd3';
import * as jsnx from 'jsnetworkx';
import _ from 'lodash';
import './index.scss';

import {
    ACTIVITY_TYPES
} from '../../common/constants';

Cytoscape.use(COSEBilkent);
Cytoscape.use(d3Force);
// Cytoscape.use(popper);

class NetworkCanvas extends Component {

    initListeners() {

        // const cyRef = this.cy;

        this.cy.on('tap', 'node', evt => {
            var node = evt.target;
            this.cy.elements().difference(node.outgoers()).not(node).addClass('semitransp');
            node.toggleClass('selected').outgoers().addClass('semitransp');
            node.toggleClass('isSelected');
        })

        this.cy.on('mouseover', 'node', evt => {
            var node = evt.target;
            // const nodeData = node._private.data;
            // console.log("NODE", node)
            // console.log("NODE PRIVATE", node._private.data);

            this.cy.elements().difference(node.outgoers()).not(node).addClass('semitransp');
            node.addClass('highlight').outgoers().addClass('highlight');
            node.addClass('hasLabel').outgoers().addClass('hasLabel');

            // this.cy.elements().toggleClass('hasLabel');

            // let popper = node.popper({
            //     content: () => {
            //         let div = d3.select('#canvasContainer').append('div').attr('id', 'popperContainer');

            //         div.html('<p>' + nodeData.name + '</p>');

            //         console.log("div", div);

            //         return div.node();
            //     }
            // })

            // let update = () => {
            //     popper.update();
            // };

            // node.on('position', update);

            // this.cy.on('pan zoom resize', update);

        })

        this.cy.on('mouseout', 'node', evt => {
            var node = evt.target;
            this.cy.elements().removeClass('semitransp');
            node.removeClass('highlight').outgoers().removeClass('highlight');
            node.removeClass('hasLabel').outgoers().removeClass('hasLabel');
            d3.selectAll('#popperContainer').remove();
        })
    }

    constructor(props) {
        super(props);
        this.state = {}
    }

    // shouldComponentUpdate(nextProps, nextState) {

    //     console.log("this.props", this.props);
    //     console.log("nextProps", nextProps);

    //     if (_.isEqual(this.props.filters.populate.participants, nextProps.filters.populate.participants) && _.isEqual(this.props.data.topics.data, nextProps.data.topics.data)) {
    //         console.log("NetworkCanvas -> Has not change");
    //         return false;
    //     } else {
    //         console.log("NetworkCanvas -> shouldComponentUpdate");
    //         return true;
    //     }
    // }

    componentDidMount() { }




    render() {

        // console.log("NetworkCanvas RENDER");
        // console.log("THIS.PROPS", this.props);

        const { filters } = this.props;
        const layout = { name: 'cose-bilkent' };

        // const layout = {
        //     animate: true, // whether to show the layout as it's running; special 'end' value makes the layout animate like a discrete layout
        //     maxIterations: 0, // max iterations before the layout will bail out
        //     maxSimulationTime: 0, // max length in ms to run the layout
        //     ungrabifyWhileSimulating: false, // so you can't drag nodes during layout
        //     fixedAfterDragging: false, // fixed node after dragging
        //     fit: false, // on every layout reposition of nodes, fit the viewport
        //     padding: 30, // padding around the simulation
        //     boundingBox: undefined, // constrain layout bounds; { x1, y1, x2, y2 } or { x1, y1, w, h }
        //     /**d3-force API**/
        //     alpha: 1, // sets the current alpha to the specified number in the range [0,1]
        //     alphaMin: 0.001, // sets the minimum alpha to the specified number in the range [0,1]
        //     alphaDecay: 1 - Math.pow(0.001, 1 / 300), // sets the alpha decay rate to the specified number in the range [0,1]
        //     alphaTarget: 0, // sets the current target alpha to the specified number in the range [0,1]
        //     velocityDecay: 0.4, // sets the velocity decay factor to the specified number in the range [0,1]
        //     collideRadius: 1, // sets the radius accessor to the specified number or function
        //     collideStrength: 0.7, // sets the force strength to the specified number in the range [0,1]
        //     collideIterations: 1, // sets the number of iterations per application to the specified number
        //     linkId: function id(d) {
        //       return d.index;
        //     }, // sets the node id accessor to the specified function
        //     linkDistance: 30, // sets the distance accessor to the specified number or function
        //     linkStrength: function strength(link) {
        //       return 1 / Math.min(count(link.source), count(link.target));
        //     }, // sets the strength accessor to the specified number or function
        //     linkIterations: 1, // sets the number of iterations per application to the specified number
        //     manyBodyStrength: -30, // sets the strength accessor to the specified number or function
        //     manyBodyTheta: 0.9, // sets the Barnes–Hut approximation criterion to the specified number
        //     manyBodyDistanceMin: 1, // sets the minimum distance between nodes over which this force is considered
        //     manyBodyDistanceMax: Infinity, // sets the maximum distance between nodes over which this force is considered
        //     xStrength: 0.1, // sets the strength accessor to the specified number or function
        //     xX: 0, // sets the x-coordinate accessor to the specified number or function
        //     yStrength: 0.1, // sets the strength accessor to the specified number or function
        //     yY: 0, // sets the y-coordinate accessor to the specified number or function
        //     radialStrength: 0.1, // sets the strength accessor to the specified number or function
        //     radialRadius: [radius],// sets the circle radius to the specified number or function
        //     radialX: 0, // sets the x-coordinate of the circle center to the specified number
        //     radialY: 0, // sets the y-coordinate of the circle center to the specified number
        //     // layout event callbacks
        //     ready: function(){}, // on layoutready
        //     stop: function(){}, // on layoutstop
        //     tick: function(progress) {}, // on every iteration
        //     // positioning options
        //     randomize: false, // use random node positions at beginning of layout
        //     // infinite layout options
        //     infinite: false // overrides all other options for a forces-all-the-time mode
        //   }
        // const layout = {
        //     name: 'cose',
        //     ready: function(){},
        //     stop: function(){},
        //     animate: true,
        //     animationEasing: undefined,
        //     animationDuration: undefined,
        //     animateFilter: function ( node, i ){ return true; },
        //     animationThreshold: 250,
        //     refresh: 20,
        //     fit: true,
        //     padding: 30,
        //     boundingBox: undefined,
        //     nodeDimensionsIncludeLabels: false,
        //     randomize: false,
        //     componentSpacing: 40,
        //     nodeRepulsion: function( node ){ return 2048; },
        //     nodeOverlap: 4,
        //     edgeElasticity: function( edge ){ return 32; },
        //     nestingFactor: 1.2,
        //     gravity: 1,
        //     numIter: 1000,
        //     initialTemp: 1000,
        //     coolingFactor: 0.99,
        //     minTemp: 1.0
        // };

        return <Fragment>
            {

                <CytoscapeComponent
                    elements={filters.networkData}
                    layout={{ name: 'cose-bilkent' }}
                    cy={cy => {
                        this.cy = cy;
                        this.initListeners();
                        cy.layout(layout).run()
                        cy.fit()
                    }}
                    style={
                        {
                            'width': this.props.canvasWidth,
                            'height': this.props.canvasHeight
                        }
                    }
                    stylesheet={[
                        {
                            selector: 'node',
                            style: { 
                                'opacity': '0.5',
                                'width': (ele) => {
                                    if (ele.isNode()) return ele.data('size');
                                },
                                'height': (ele) => {
                                    if (ele.isNode()) return ele.data('size');
                                },
                                'background-color': (ele) => {
                                    if (ele.isNode()) return ele.data('color');
                                },
                                'font-size': '8px',
                                'color': '#F2F2F2'
                            }
                        },
                        {
                            selector: 'node.highlight',
                            style: {
                                'opacity': '1'
                            }
                        },
                        {
                            selector: 'node.selected',
                            style: {
                                'opacity': '1',
                                'border-width': '1',
                                'border-color': '#FFFFFF'
                            }
                        },
                        {
                            selector: 'node.semitransp',
                            style: {
                                'opacity': '0.1'
                            }
                        },
                        {
                            selector: "edge",
                            style: {
                                'line-color': '#666666',
                                'opacity': '0.3',
                                'width': (ele) => {
                                    if (ele.isEdge()) return ele.data('weight');
                                }
                            }
                        },
                        {
                            selector: 'edge.highlight',
                            style: { 
                                'line-color': '#CCCCCC',
                                'opacity': '0.9' 
                            }
                        },
                        {
                            selector: 'edge.semitransp',
                            style: { 'opacity': '0.1' }
                        },
                        {
                            selector: '.hasLabel',
                            css: {
                                'label': (ele) => {
                                    if (ele.isNode()) return ele.data('label');
                                    // if (ele.isEdge()) return ele.data('weight');
                                }
                            },

                        },
                        {
                            selector: '.isSelected',
                            css: {
                                'label': (ele) => {
                                    if (ele.isNode()) return ele.data('label');
                                    // if (ele.isEdge()) return ele.data('weight');
                                }
                            },

                        }
                    ]}
                />
            }
        </Fragment>
    }
}

export default NetworkCanvas;