import React, { Component, Fragment } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import { Context } from '../../context/Context';

import {
    getAbstract
} from '../../data/DataService'

import '../ListModal/index.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {
    ACTIVITY_TYPES,
    CONFIG,
    UNI_NAME_MAPPING
} from '../../common/constants';

import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'

class ListModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            perPage: 50,
            pressedDownload: false
        }
    }

    async componentDidMount() {
        this.setState({
            currentPage: 1
        })
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.filters.filteredProjects.length != this.props.filters.filteredProjects.length) {
            this.setState({
                currentPage: 1
            })
        }

    }

    render() {
        var theadData = ['title', 'topic', 'type', 'university_', 'year', ' ']

        var documentsList = this.props.data.topics.data;
        if (this.props.filters.filteredProjects.length > 0)
            documentsList = documentsList.filter(x => this.props.filters.filteredProjects.includes(x.id))

        var clusterNames = this.props.data.topics.topics.reduce((acc, { cluster, topic_label }) => ({ ...acc, [cluster]: topic_label }), {});
        
        documentsList = documentsList.map(x => {
            x.topic = clusterNames[x.cluster]
            x.university_ = UNI_NAME_MAPPING[x.university]
            x.link = x.type == "Publications" ? `https://explore.openaire.eu/search/publication?pid=${x.doi}` : x.type == "H2020" ? `https://cordis.europa.eu/project/id/${x.originalID}` : ''
            return x;
        })

        var paginationItems = []
        var numOfPages = Math.ceil(documentsList.length / this.state.perPage);
        var trimPages = numOfPages > 10;


        Array.from({ length: numOfPages }, (_, i) => i + 1)
            .forEach(index => {
                if (trimPages == false || (trimPages == true && (index <= 3 || index >= numOfPages - 3) || index == this.state.currentPage))
                    paginationItems.push(<li onClick={() => {
                        this.setState({
                            ...this.state,
                            currentPage: index
                        })
                    }} className="page-item"><a className={"page-link cursor-pointer text-dark " + (index == this.state.currentPage ? 'fw-bold' : '')}>{index}</a></li>)
                if (trimPages == true && index == 4) {
                    paginationItems.push(<li disabled className="page-item"><a className={"page-link cursor-pointer text-dark " + (index == this.state.currentPage ? 'fw-bold' : '')}>...</a></li>)
                }
            });

        function downloadXsl(that) {
            that.setState({
                pressedDownload: true
            })
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            let documentsListClean = [...documentsList]
            documentsListClean = documentsListClean.map(x => {
                delete x['id']
                delete x['cluster']
                delete x['originalID']
                delete x['university']
                delete x['x']
                delete x['y']
                delete x['dotColor']
                delete x['SDGs']
                return x
            })

            const ws = XLSX.utils.json_to_sheet(documentsListClean)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            FileSaver.saveAs(data, 'List of documents' + fileExtension)

            setTimeout(() => {
                that.setState({
                    pressedDownload: false
                })
            }, 1500);
        }

        return <Fragment>
            <Context.Consumer>
                {context =>
                    <div id="list-modal"
                        className={" modal shadow d-block " + (context.isListOpened() ? 'visible opacity-100' : 'opacity-0 invisible')}
                        tabIndex="-1">
                        <div className={"modal-dialog  modal-dialog-centered modal-dialog-scrollable maxh-75 modal-xl " + (context.getModalOpened() == 'analytics' ? 'obfuscate' : '')}>
                            <div className="modal-content maxh-75">
                                <div className="modal-header border-0">
                                    <div className='d-flex justify-content-between'>
                                        <h4 className='text-white my-auto'>
                                            List of documents <small className='text-code d-none'>({documentsList.length})</small>
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 100, hide: 400 }}
                                                overlay={(props) => {
                                                    return <Tooltip id="button-tooltip" {...props}>
                                                        Download in .xsl format
                                                    </Tooltip>
                                                }}
                                            >
                                                <i onClick={() => downloadXsl(this)} className='download-xsl fas fa-download fa-xs ms-2 '></i>
                                            </OverlayTrigger >
                                        </h4>
                                    </div>
                                    <div>
                                        {/* <button onClick={() => downloadXsl(this)} className='btn px-1 py-1 btn-sm bg-light border my-auto'>Download .xsl <i className={this.state.pressedDownload ? 'fas fa-spinner fa-spin' : ''}></i> </button> */}
                                        <button
                                            onClick={() => { context.toggleListOpened() }}
                                            type="button"
                                            className="btn"
                                            data-bs-dismiss="modal"
                                            aria-label="Close">
                                            <i className='fas fa-times fa-lg text-white'></i>
                                        </button>
                                    </div>
                                </div>
                                <div className={'modal-body p-0'}>
                                    {/* <div className={"modal-body " + this.props.padding == false ? 'px-0' : 'px-4'}> */}

                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead className='text-code'>
                                                {theadData.map(heading => {
                                                    return <th className='text-uppercase fw-bold border-0 px-4' key={heading}>{heading.replace('_', ' ')}</th>
                                                })}
                                            </thead>
                                            <tbody>
                                                {documentsList.slice(parseInt(this.state.currentPage * this.state.perPage) - parseInt(this.state.perPage), parseInt(this.state.currentPage * this.state.perPage)).map((row, index) => {
                                                    return <tr key={index}>
                                                        {theadData.map((key, index) => {
                                                            if (key != ' ')
                                                                return <td onClick={() => {
                                                                    this.props.openAnalyticsModal(row['id'], row['cluster'])
                                                                }} className={'cursor-pointer px-4'
                                                                    + (key == 'id' ? ' td-max-id ' : '')
                                                                    + (key == 'title' ? ' fw-bold ' : '')
                                                                    // + (key == 'topic' ? ' bg-topic-tip ' : '')
                                                                }
                                                                    style={{ '--color': row['dotColor'] }}
                                                                    key={row[key]}>
                                                                    <span className={(key == 'topic' ? ' bg-topic-tip ' : '')}>{row[key]}</span>
                                                                </td>
                                                            else
                                                                return <td className={'px-4 cursor-pointer '} key={row[key]} onClick={() => {
                                                                    this.props.openAnalyticsModal(row['id'], row['cluster'])
                                                                }}><button className='info-btn btn'>Info</button></td>
                                                        })}
                                                    </tr>;
                                                })}
                                            </tbody>
                                        </table>
                                        <div className='row justify-content-center px-4'>
                                            <div className='col text-center'>
                                                <nav aria-label="Page navigation">
                                                    <ul className="pagination">
                                                        <li onClick={() => {
                                                            this.setState({
                                                                currentPage: this.state.currentPage > 1 ? this.state.currentPage - 1 : this.state.currentPage
                                                            })
                                                        }} className="page-item "><a className="page-link text-dark cursor-pointer">Previous</a></li>
                                                        {paginationItems}
                                                        <li onClick={() => {
                                                            this.setState({
                                                                currentPage: this.state.currentPage < numOfPages ? this.state.currentPage + 1 : this.state.currentPage
                                                            })
                                                        }} className="page-item"><a className="page-link text-dark cursor-pointer">Next</a></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Context.Consumer>
        </Fragment>
    }

}

export default ListModal;