import React, { Component, Fragment } from 'react';
import './index.scss';

import {
    CONFIG
} from '../../../common/constants';
class UniversityMiniCard extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        // Call any function in provider
    }

    render() {

        return <Fragment>
            <div className='row d-flex'>
                {/* <div className='col-1 my-auto'><small>{this.props.name.split(' ').map(x=>x[0] != 'o' ? x[0] : '').join('')}</small></div> */}
                <div className='col-1 my-auto'><img className='my-auto' height={30} width={30} src={this.props.img}></img></div>
                <div className='col ms-3'>
                    <div className='row'>
                        <div className='col'>
                            <h6 className='fw-bold'>{this.props.name}</h6>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <h6 className='fw-normal'>{this.props.contactInfo} - {this.props.email}</h6>
                            <h6 className='fw-normal'>{this.props.contactInfo} - {this.props.email}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    }
}

export default UniversityMiniCard;