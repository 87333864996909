import React, { Component } from 'react';
import { Context } from './Context';

import _ from 'lodash';
import * as d3 from 'd3';
import pointInPolygon from 'point-in-polygon';

import {
    // FROM LOCAL
    getProjectCoords,
    getTopics,
    checkEcLocal,
    getSummary,
    getSummaryEC,
    getPublicationsEC,

    keywordSearch
} from '../data/DataService';

import {
    getNetworkData
} from './networkData'

import {
    CONFIG,
    UNI_NAME_MAPPING,
    UNI_COLORS_MAPPING,
} from '../common/constants'

export class Provider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showOverlayLoader: false,
            isMenuOpened: false,
            modalOpened: '',

            listOpened: false,

            data: {
                topics: {},
                network: {},
                list: {}
            },
            filters: {
                summary: {
                    projects: 0,
                    topics: 0,
                    participants: 0
                },
                populate: {},
                selected: [],
                filteredProjects: [],
                filteredTopics: []
            },
            colouringType: 'cluster',
            colorScaleDescription: [] // [{name: 'something', color: '#000'}]
        };

        this.setCanvasApplication = this.setCanvasApplication.bind(this);
        this.displayFilters = this.displayFilters.bind(this);
        this.setFilteredProjects = this.setFilteredProjects.bind(this);
        this.setOverlayLoader = this.setOverlayLoader.bind(this);
        this.toggleListOpened = this.toggleListOpened.bind(this)
        this.setModalOpened = this.setModalOpened.bind(this)
        this.setFilter = this.setFilter.bind(this);
        this.getFilteredProjects = this.getFilteredProjects.bind(this);
        this.getFilteredTopics = this.getFilteredTopics.bind(this);
        this.handleOffCanvasMenu = this.handleOffCanvasMenu.bind(this);
        this.openOffCanvasMenu = this.openOffCanvasMenu.bind(this);
        this.openAnalyticsModal = this.openAnalyticsModal.bind(this);
        this.closeOffCanvasMenu = this.closeOffCanvasMenu.bind(this);
        this.getTopicInformation = this.getTopicInformation.bind(this);
        this.getProjectInformation = this.getProjectInformation.bind(this);
        this.getClickedDot = this.getClickedDot.bind(this);
        this.getClickedLabel = this.getClickedLabel.bind(this);
        this.dotHandleOver = this.dotHandleOver.bind(this);
        this.dotHandleOut = this.dotHandleOut.bind(this);
        this.initDashboard = this.initDashboard.bind(this);
        this.setColouringType = this.setColouringType.bind(this);
        this.setColorScaleDescription = this.setColorScaleDescription.bind(this);

        // this.setClickedDot = this.setClickedDot.bind(this);

        // Participants list functions
        this.listItemHandleClick = this.listItemHandleClick.bind(this);
        this.sortList = this.sortList.bind(this);
    }



    async getProjCoords(years) {
        return new Promise(async (resolve, reject) => {
            let projectCoords = await getProjectCoords();
            var dataset = this.state.data.topics.topics;

            var customColorScale = d3.scaleOrdinal()
                .domain(_.map(dataset, topic => topic['cluster']))
                .range(
                    [].concat(d3.schemeSet1)
                        .concat(d3.schemeSet2)
                        .concat(d3.schemeSet3)
                        .concat(d3.schemeTableau10)
                        .concat(d3.schemePaired)
                        .slice(0, dataset.length)
                );


            let reducedProjectCoords = _.map(_.filter(projectCoords, project => {
                project.year = parseInt(project.year);
                return (project.type == "Publications" && project.year >= years[0] && project.year <= years[1]) || project.type != "Publications";
            }), project => {
                let newProject = {};
                newProject.id = project.id.replace(/"/g, '\\"');
                newProject.originalID = project.originalID ? project.originalID.replace(/"/g, '\\"') : '';
                newProject.doi = project.doi ? project.doi : '';
                newProject.cluster = project.cluster;
                newProject.x = project.x;
                newProject.y = project.y;
                newProject.journal = project.journal;
                newProject.type = project.type;
                newProject.year = project.year;
                newProject.university = project.uni;
                newProject.title = project.title ? project.title : '';
                newProject.keywords = project.keywords;
                newProject.SDGs = project.SDGs ? project.SDGs.split(';') : [];
                newProject.dotColor = customColorScale(project.cluster);

                return newProject;
            })

            resolve(reducedProjectCoords);
        });
    }


    async fetchProjectsCoords() {
        let currentData = { ...this.state.data };
        var reducedProjectCoords = await this.getProjCoords([2020, 2021]);
        currentData.topics.data = reducedProjectCoords;

        // Populate Filter dropdown
        let typeOptions = _.map([...new Set(currentData.topics.data.map(x => x.type))], type => {
            return {
                value: type,
                label: type,
                sort: type
            }
        });


        let universityOptions = _.map(Object.keys(UNI_NAME_MAPPING), university => {
            return {
                value: university,
                label: UNI_NAME_MAPPING[university],
                sort: university
            }
        });

        let yearOptions = _.map([...new Set(currentData.topics.data.map(x => x.year))], year => {
            return {
                value: parseInt(year),
                label: parseInt(year),
                sort: parseInt(year)
            }
        });

        let sdgOptions = []
        const sdgNames = {
            1: 'No Poverty',
            2: 'Zero Hunger',
            3: 'Good Health and Well-being',
            4: 'Quality Education',
            5: 'Gender Equality',
            6: 'Clean Water and Sanitation',
            7: 'Affordable and Clean Energy',
            8: 'Decent Work and Economic Growth',
            9: 'Industry, Innovation and Infrastructure',
            10: 'Reduced Inequalities',
            11: 'Sustainable Cities and Communities',
            12: 'Responsible Consumption and Production',
            13: 'Climate Action',
            14: 'Life Below Water',
            15: 'Life On Land',
            16: 'Peace, Justice and Strong Institutions',
            17: 'Partnerships for the Goals'
        };

        for (var i = 1; i < 17; i++) {
            let val = 'SDG ' + i;
            sdgOptions.push({
                value: val,
                label: val + ' ' + sdgNames[i],
                sort: val
            })
        }


        typeOptions = _.orderBy(typeOptions, ['sort'], ['asc']);
        let currentFilters = { ...this.state.filters };

        currentFilters.populate.type = {
            options: typeOptions
        }

        currentFilters.populate.university = {
            options: universityOptions
        }

        currentFilters.populate.SDGs = {
            options: sdgOptions
        }

        currentFilters.populate.years = {
            options: yearOptions
        }

        this.setState({
            ...this.state,
            data: currentData,
            filters: currentFilters
        });

        return true;
    }

    refreshSummary() {
        let data = this.state.data.topics.data;
        let currentFilters = { ...this.state.filters };

        currentFilters.summary.national_projects = _.filter(data, project => project.type == "National projects").length;
        currentFilters.summary.h2020_projects = _.filter(data, project => project.type == "H2020").length;
        currentFilters.summary.patents = _.filter(data, project => project.type == "Patents").length;
        currentFilters.summary.publications = _.filter(data, project => project.type == "Publications").length;
        currentFilters.summary.current = currentFilters.summary.national_projects + currentFilters.summary.h2020_projects + currentFilters.summary.patents + currentFilters.summary.publications;


        this.setState({
            filters: currentFilters
        });
    }

    async fetchTopics() {
        await checkEcLocal();
        let topics = await getTopics();
        let summary = await getSummary();

        summary = summary.map(x => {
            x.n_docs = parseInt(x.n_docs)
            return x
        })

        let currentData = { ...this.state.data };
        currentData.topics.topics = topics;
        currentData.topics.summary = summary;

        // Format numbers
        topics = _.map(topics, topic => {
            topic.cluster = Number(topic.cluster);
            topic.x = Number(topic.x);
            topic.y = Number(topic.y);
            return topic;
        })

        // Populate Filter dropdown
        let topicOptions = _.map(topics, topic => {
            return {
                value: topic.cluster,
                label: topic.topic_label,
                sort: topic.topic_label
            }
        });


        let keywordsOptions = _.map([...new Set(_.flatten(currentData.topics.topics.map(x => x.topic_words.split(', '))))], keyword => {
            return {
                value: keyword,
                label: keyword,
                sort: keyword
            }
        });

        topicOptions = _.orderBy(topicOptions.filter(x => x.label != ""), ['sort'], ['asc']);
        let currentFilters = { ...this.state.filters };

        currentFilters.populate.topics = {
            options: topicOptions
        }

        currentFilters.populate.keywords = {
            options: keywordsOptions
        }

        this.setState({
            ...this.state,
            data: currentData,
            filters: currentFilters
        });
    }

    setCanvasApplication = (app) => this.setState({ canvasApplication: app });

    getTopicInformation = (topicId) => _.filter(this.state.data.topics.topics, topic => topic.cluster === topicId);

    getProjectInformation = (projectId) => _.filter(this.state.data.topics.data, project => project.id === projectId);

    setFilteredProjects({ array = [] }) {
        let currentFilters = { ...this.state.filters };
        currentFilters.filteredProjects = currentFilters.filteredProjects.concat(array);
        currentFilters.filteredProjects = _.uniq(currentFilters.filteredProjects).flat();
        this.setState({
            filters: currentFilters
        })
    }

    getOverlayLoader = () => this.state.showOverlayLoader

    setOverlayLoader({ how = true }) {
        this.setState({
            showOverlayLoader: how
        })
        return this.state.showOverlayLoader
    }

    setModalOpened(modalId) {
        this.setState({
            modalOpened: modalId,
            showOverlayLoader: ((modalId != '' && modalId != null) || this.state.listOpened) ? true : false
        })
        return this.state.modalOpened
    }

    getModalOpened = () => this.state.modalOpened

    toggleListOpened() {
        this.setState({
            listOpened: !this.state.listOpened,
            showOverlayLoader: !this.state.listOpened ? true : false

        })
    }

    isListOpened = () => this.state.listOpened

    setFilter({ filter = null, type = null }) {
        let currentFilters = { ...this.state.filters };
        let currentData = { ...this.state.data };

        switch (type) {
            case 'topics':
                const topicFound = _.find(currentFilters.selected, filter => filter.type === 'topics');
                topicFound
                    ? topicFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'topics',
                        values: _.map(filter, o => o.value)
                    })
                break;
            case 'type':
                const typeFound = _.find(currentFilters.selected, filter => filter.type === 'type');
                typeFound
                    ? typeFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'type',
                        values: _.map(filter, o => o.value)
                    })
                break;
            case 'keywords':
                const keywordFound = _.find(currentFilters.selected, filter => filter.type === 'keywords');
                keywordFound
                    ? keywordFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'keywords',
                        values: _.map(filter, o => o.value)
                    })
                break;
            case 'university':
                const universityFound = _.find(currentFilters.selected, filter => filter.type === 'university');
                universityFound
                    ? universityFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'university',
                        values: _.map(filter, o => o.value)
                    })
                break;

            case 'technologies':
                const technologyFound = _.find(currentFilters.selected, filter => filter.type === 'technologies');
                technologyFound
                    ? technologyFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'technologies',
                        values: _.map(filter, o => o.value)
                    })
                break;
            case 'SDGs':
                const SDGFound = _.find(currentFilters.selected, filter => filter.type === 'SDGs');
                SDGFound
                    ? SDGFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'SDGs',
                        values: _.map(filter, o => o.value)
                    })
                break;
            case 'years':
                const yearsFound = _.find(currentFilters.selected, filter => filter.type === 'years');
                console.log("years", yearsFound)

                yearsFound
                    ? yearsFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'years',
                        values: _.map(filter, o => o.value)
                    })
                break;
            case 'publicationsYears':
                const publicationsYearsFound = _.find(currentFilters.selected, filter => filter.type === 'publicationsYears');
                publicationsYearsFound
                    ? publicationsYearsFound.values = _.map(filter, o => o.value)
                    : currentFilters.selected.push({
                        type: 'publicationsYears',
                        values: _.map(filter, o => o.value)
                    })
                break;
            case 'keyword_search':
                currentFilters.selected = currentFilters.selected.filter(x => x.type != 'keyword_search');
                if (filter.length > 0 && filter[0].value != '')
                    currentFilters.selected.push({
                        type: 'keyword_search',
                        values: _.map(filter, o => o.value),
                        orQuery: _.map(filter, o => o.label)[0],
                    })
                break;
            default:
                console.log(filter, type)
                break;
            // code block
        }

        this.setState({
            filters: currentFilters,
        })


        this.displayFilters();
    }

    // getFilters() {}
    getFilteredProjects = () => this.state.filters.filteredProjects;
    getFilteredTopics = () => this.state.filters.filteredTopics;
    getClickedDot = () => this.state.clickedDot;
    getClickedLabel = () => this.state.clickedLabel;
    getTotalParticipantsNumber = () => this.state.filters.populate.participantsReference.length;
    removeFilter(removedFilter) { }


    listItemHandleClick = ({ item = null }) => this.setState({ isMenuOpened: true, clickedItem: item.id });

    sortList = ({ criteria = null, direction = null }) => {

        let currentFilters = { ...this.state.filters };

        let listData = currentFilters.list

        switch (criteria) {
            case 'name':
                listData = _.orderBy(
                    listData,
                    ['final_Name'],
                    [direction]);
                break;
            case 'country':
                listData = _.orderBy(
                    listData,
                    ['country'],
                    [direction]);
                break;

            case 'documents':
                listData = _.orderBy(
                    listData,
                    ['Total Documents'],
                    [direction]);
                break;


            default:
                break;
        }

        currentFilters.list = listData;

        this.setState({
            filters: currentFilters
        })
    }


    ///////////////////////////////////////////////////////////////////////////
    ////////// UTILS //////////////////////////////////////////////////////////

    async displayFilters() {
        console.log("displayFilters")
        // 1. Get active IDS

        let currentFilters = { ...this.state.filters };
        let currentData = { ...this.state.data };

        var emptyFilters = false;
        if (currentFilters.selected.map(x => x.values).filter(x => x.length > 0).length == 0) {
            console.log("Empty filters, check")
            emptyFilters = true;
        }

        let topicIDS = [];
        let typesIDS = [];
        let SDGsIDS = [];
        let publicationsYearsIDS = [];
        let yearsIDS = [];
        let universityIDS = [];
        let keywordsIDS = [];
        let keywordSearchIDS = [];
        let keywordSearchOrQuery = null;


        var foundKeywordIds = []
        _.map(currentFilters.selected, filter => {
            if (filter.type === 'topics') topicIDS = filter.values;
            if (filter.type === 'type') typesIDS = filter.values;
            if (filter.type === 'university') universityIDS = filter.values;
            if (filter.type === 'keywords') keywordsIDS = filter.values;
            if (filter.type === 'SDGs') SDGsIDS = filter.values;
            if (filter.type === 'publicationsYears') publicationsYearsIDS = filter.values;
            if (filter.type === 'years') yearsIDS = filter.values;
            if (filter.type === 'keyword_search') {
                keywordSearchIDS = filter.values
                keywordSearchOrQuery = filter.orQuery;
            }
        })

        if (currentFilters.selected.map(x => x.type).includes('keyword_search')) {
            foundKeywordIds = await keywordSearch(keywordSearchIDS[0], keywordSearchOrQuery)
        }

        if (publicationsYearsIDS.length > 0) {
            var reducedProjectCoords = await this.getProjCoords([Math.min(...publicationsYearsIDS), Math.max(...publicationsYearsIDS)]);
            currentData.topics.data = reducedProjectCoords;
            this.setState({
                data: currentData
            })
        }

        var projects = currentData.topics.data;
        projects = projects.filter(x => x.type != "Publications")

        var publications = currentData.topics.data;
        publications = publications.filter(x => x.type == "Publications")

        var projectsAndPublications = projects.concat(publications)

        let filteredProjects = projects.filter(project => {
            return ((
                (topicIDS.length === 0 || topicIDS.includes(project.cluster)) &&
                (typesIDS.length === 0 || typesIDS.includes(project.type)) &&
                (keywordsIDS.length === 0 || keywordsIDS.some(v => project.keywords.split(', ').includes(v.toLowerCase()))) &&
                (universityIDS.length === 0 || universityIDS.includes(project.university)) &&
                (SDGsIDS.length === 0 || (project.SDGs && SDGsIDS.some(SDG => project.SDGs.includes(SDG)))) &&
                (yearsIDS.length === 0 || (yearsIDS.includes(parseInt(project.year))))
                && (keywordSearchIDS.length === 0 || (foundKeywordIds.some(id => id === project.id)))
            ));
        }).map(project => project.id);

        let filteredPublications = publications.filter(publication => {
            return ((
                (topicIDS.length === 0 || topicIDS.includes(publication.cluster)) &&
                (typesIDS.length === 0 || typesIDS.includes(publication.type)) &&
                (keywordsIDS.length === 0 || keywordsIDS.some(v => publication.keywords.split(', ').includes(v.toLowerCase()))) &&
                (universityIDS.length === 0 || universityIDS.includes(publication.university)) &&
                (SDGsIDS.length === 0 || (publication.SDGs && SDGsIDS.some(SDG => publication.SDGs.includes(SDG)))) &&
                (publicationsYearsIDS.length === 0 || (publicationsYearsIDS.includes(parseInt(publication.year)))) &&
                (keywordSearchIDS.length === 0 || foundKeywordIds.some(id => id === publication.id))
            ));
        }).map(project => project.id);

        filteredProjects = _.concat(filteredProjects, filteredPublications)
        console.log("filteredProjects", new Set(filteredProjects.map(x => x.split("_")[0])))
        this.setState({
            filteredProjects: filteredProjects
        })

        // 2. Modify dots
        this.state.canvasApplication.stage.children.forEach(function (child) {

            if (child.data) {

                if (filteredProjects.length === projectsAndPublications.length) {
                    child.alpha = CONFIG.TOPICS.LAYOUT.OPACITIES.NORMAL
                    child.visible = true;
                    child.interactive = true;
                } else {
                    if (filteredProjects.includes(child.data.id)) {
                        child.alpha = CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE
                        child.visible = true;
                        child.interactive = true;
                    } else {
                        child.alpha = CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;
                        child.visible = false;
                        child.interactive = false;
                    }
                }
            }
        })


        // 3. Modify labels
        // 4. Modify clusters

        // Get Filtered topics from filtered projects id
        let filteredTopics = _.filter(projectsAndPublications, project => filteredProjects.includes(project.id));

        filteredTopics = _.uniq(_.map(filteredTopics, project => project.cluster));

        if (filteredTopics.length > 0) {
            d3.selectAll(".label").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.TOPIC_INACTIVE);
            d3.selectAll("rect.label").style("pointer-events", "none");
            d3.selectAll(".cluster-container").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.TOPIC_INACTIVE);
            _.map(filteredTopics, id => {
                d3.selectAll(".label.label-" + id).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);
                d3.selectAll("rect.label.label-" + id).style("pointer-events", "fill");
                d3.select("#id" + id).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);
            })
        } else {
            d3.selectAll(".label").style("opacity", 0);
            d3.selectAll(".cluster-container").style("opacity", 0);
        }


        var filteredProjectsWithInfo = _.filter(projectsAndPublications, project => filteredProjects.includes(project.id));
        currentFilters.summary.national_projects = new Set(_.filter(filteredProjectsWithInfo, project => project.type == "National projects").map(x => x.id)).size;
        currentFilters.summary.h2020_projects = new Set(_.filter(filteredProjectsWithInfo, project => project.type == "H2020").map(x => x.id)).size;


        currentFilters.summary.patents = new Set(_.filter(filteredProjectsWithInfo, project => project.type == "Patents").map(x => x.id)).size;
        currentFilters.summary.publications = new Set(_.filter(filteredProjectsWithInfo, project => project.type == "Publications").map(x => x.id)).size;
        currentFilters.summary.current = currentFilters.summary.national_projects + currentFilters.summary.h2020_projects + currentFilters.summary.patents + currentFilters.summary.publications;

        currentFilters.summary.topics = filteredTopics.length;
        currentFilters.filteredProjects = filteredProjects;
        currentFilters.filteredTopics = filteredTopics;

        this.setState({
            data: currentData,
            filters: currentFilters,
        })

        setTimeout(() => {
            this.setState({
                showOverlayLoader: false
            })


        }, 500);
        if (this.state.colouringType) {
            console.log("HAVE TO SET COLORUING TYPE", this.state.colouringType)
            this.setColouringType(this.state.colouringType)
        }
    }

    handleOffCanvasMenu = () => this.setState({ isMenuOpened: !this.state.isMenuOpened });
    openOffCanvasMenu = (projectId, topicId) => this.setState({ isMenuOpened: true, clickedDot: projectId, clickedLabel: topicId });
    openAnalyticsModal = (projectId, topicId) => {
        this.setModalOpened('analytics');
        this.setState({ clickedDot: projectId, clickedLabel: topicId })
    };
    closeOffCanvasMenu = () => {
        this.setModalOpened(null)
        this.setState({ isMenuOpened: false, clickedDot: null, clickedLabel: null });
        this.dotHandleOut({
            canvasApp: this.state.canvasApplication,
            filteredDots: this.getFilteredProjects(),
            filteredLabels: this.getFilteredTopics()
        })
        d3.selectAll('.circle-selected').remove();
    }

    // TOOLS
    dotHandleOver({
        selectedDot = null,
        selectedCluster = null,
        canvasApp = null,
        filteredDots = [],
        clickedDot = null,
        clickedLabel = null
    }) {

        // TODO Check, why?
        // if (this.getFilteredProjects().length != 0) {
        //     return
        // }

        // 1. Hide all
        d3.selectAll(".label").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.TOPIC_INACTIVE);
        d3.selectAll(".cluster-container").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.TOPIC_INACTIVE);

        if (selectedCluster || clickedLabel) {

            // 2. Show selected labels
            d3.selectAll(".label.label-" + selectedCluster).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL);
            d3.selectAll(".label.label-" + clickedLabel).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL);

            // 3. Show selected clusters
            d3.select("#id" + selectedCluster).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL);
            d3.select("#id" + clickedLabel).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL);
        }

        // 4. Show dots for selected topic
        canvasApp.stage.children.forEach(function (child) {
            if (child.data) {
                child.alpha = child.data.cluster.toString() === selectedCluster.toString()
                    || (filteredDots.includes(child.data.id) && filteredDots.length !== canvasApp.stage.children.length)
                    ? CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE
                    : CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE;

                if (selectedDot || clickedDot) {
                    if (child.data.id === selectedDot || child.data.id === clickedDot) {
                        child.alpha = CONFIG.TOPICS.LAYOUT.OPACITIES.FULL;
                    }
                }
            }
        })

    }

    dotHandleOut({
        canvasApp = null,
        filteredDots = [],
        filteredLabels = [],
        clickedDot = null,
        clickedLabel = null
    }) {

        // Manage labels
        // Manage clusters
        d3.selectAll(".label").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.TOPIC_INACTIVE);
        d3.selectAll(".cluster-container").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.TOPIC_INACTIVE);

        if (filteredLabels.length > 0) {
            _.map(filteredLabels, id => {
                d3.selectAll(".label.label-" + id).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);
                d3.select("#id" + id).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);
            })
        }
        else if (clickedLabel) {
            d3.selectAll(".label.label-" + clickedLabel).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL);
            d3.select("#id" + clickedLabel).style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.FULL);
        }
        else {
            d3.selectAll(".label").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);
            d3.selectAll(".cluster-container").style("opacity", CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE);
        }

        // Manage dots
        canvasApp.stage.children.forEach(function (child) {
            if (child.data)
                child.alpha = filteredDots.length > 0 || clickedDot
                    ? filteredDots.includes(child.data.id) || child.data.id === clickedDot
                        ? CONFIG.TOPICS.LAYOUT.OPACITIES.ACTIVE
                        : CONFIG.TOPICS.LAYOUT.OPACITIES.INACTIVE
                    : CONFIG.TOPICS.LAYOUT.OPACITIES.NORMAL
        });
    }

    // Summary component
    calculatePartipantsInFilteredProjects = (array) => {
        let projectsTotalParticipants = _.filter(this.state.data.topics.data, project => {
            return array.includes(project.id);
        })

        projectsTotalParticipants = _.map(projectsTotalParticipants, project => {
            return project.participants;
        })

        projectsTotalParticipants = _.uniq(projectsTotalParticipants.flat());

        return projectsTotalParticipants;
    }

    // Set dropdowns options
    setDropdownOptions({
        type = null,
        label = null,
        value = null,
        sort = null,
        data = []
    }) {
        let options = _.uniqBy(_.map(data, participant => {
            return {
                'label': participant[label],
                'value': participant[value],
                'sort': participant[sort]
            }
        }), 'value');
        options = _.orderBy(options, ['sort'], ['asc']);

        return options;
    }

    setColouringType(type) {
        this.setState({
            colouringType: type,
            showOverlayLoader: false
        })

        var dataset = type == 'cluster' ? this.state.data.topics.topics : this.state.data.topics.data;

        var customColorScale = d3.scaleOrdinal()
            .domain(_.map(dataset, topic => topic[type]))
            .range(
                [].concat(d3.schemeSet1)
                    .concat(d3.schemeSet2)
                    .concat(d3.schemeSet3)
                    .concat(d3.schemeTableau10)
                    .concat(d3.schemePaired)
                    .slice(0, dataset.length)
            );


        var colorScaleDescription = [];
        if (type != 'cluster') {
            var countByCustom = _.countBy(dataset, type)
            Object.keys(countByCustom).forEach(custom => {
                var colorObj = {
                    name: custom,
                    color: type == 'university' ? UNI_COLORS_MAPPING[custom] : customColorScale(custom),
                }
                colorScaleDescription.push(colorObj)
            });
        }
        this.setColorScaleDescription(colorScaleDescription);


        var map_c = {}
        this.state.canvasApplication.stage.children.forEach((child) => {
            if (child.data && type != 'university') {
                child.tint = customColorScale(child.data[type]).replace('#', '0x')
                map_c[child.data['topic']] = customColorScale(child.data[type]);
            }
            if (child.data && type == 'university') {
                child.tint = UNI_COLORS_MAPPING[child.data[type]] ? UNI_COLORS_MAPPING[child.data[type]].replace('#', '0x') : '0x000000'
            }
        })
    }

    setColorScaleDescription(scale) {
        this.setState({
            colorScaleDescription: scale,
        })
    }

    async initDashboard() {

        // load topics
        await this.fetchTopics();
        // load coords
        await this.fetchProjectsCoords();

        this.refreshSummary();

        this.setState({
            loading: false,
            showOverlayLoader: true
        });

        setTimeout(() => {
            this.setFilter({
                filter: [
                    {
                        "value": 2020,
                        "label": 2020,
                        "sort": 2020
                    },
                    {
                        "value": 2021,
                        "label": 2021,
                        "sort": 2021
                    }
                ],
                type: 'publicationsYears'
            })
        }, 1000);
        setTimeout(() => {
            this.setState({
                loading: false,
                showOverlayLoader: false
            });
        }, 2000);
    }

    async componentDidMount() {
        this.initDashboard();
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 27) {
                this.setModalOpened(null);
                this.setState({
                    listOpened: false,
                    showOverlayLoader: false

                })
            }
        });
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state.loading != nextState.loading
    //         || this.state.data != nextState.data
    //         || this.state.filteredProjects != nextState.filteredProjects
    //         || this.state.filters != nextState.filters
    //         || this.state.showOverlayLoader != nextState.showOverlayLoader
    //         ) {
    //         return true;
    //     }
    // }


    render() {
        return (
            <Context.Provider
                value={{
                    setCanvasApplication: this.setCanvasApplication,
                    setFilteredProjects: this.setFilteredProjects,
                    setFilter: this.setFilter,
                    setOverlayLoader: this.setOverlayLoader,
                    getOverlayLoader: this.getOverlayLoader,

                    setModalOpened: this.setModalOpened,
                    getModalOpened: this.getModalOpened,

                    isListOpened: this.isListOpened,
                    toggleListOpened: this.toggleListOpened,

                    displayFilters: this.displayFilters,

                    getFilteredProjects: this.getFilteredProjects,
                    getFilteredTopics: this.getFilteredTopics,
                    handleOffCanvasMenu: this.handleOffCanvasMenu,
                    openOffCanvasMenu: this.openOffCanvasMenu,
                    openAnalyticsModal: this.openAnalyticsModal,
                    closeOffCanvasMenu: this.closeOffCanvasMenu,
                    getTopicInformation: this.getTopicInformation,
                    getProjectInformation: this.getProjectInformation,
                    getClickedDot: this.getClickedDot,
                    getClickedLabel: this.getClickedLabel,
                    dotHandleOver: this.dotHandleOver,
                    dotHandleOut: this.dotHandleOut,
                    // setClickedDot: this.setClickedDot,
                    listItemHandleClick: this.listItemHandleClick,
                    sortList: this.sortList,
                    initDashboard: this.initDashboard,
                    setColouringType: this.setColouringType,
                    setColorScaleDescription: this.setColorScaleDescription,
                    ...this.state
                }}>
                {this.props.children}
            </Context.Provider>
        );
    }
}