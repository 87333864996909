import React, { Component, Fragment } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import * as d3 from 'd3';
import './index.scss';

import { IoRadioButtonOff } from "react-icons/io5";
import { IoRadioButtonOn } from "react-icons/io5";

class ColouringSelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cluster: true,
            university: false,
            type: false
        }
    }

    setColouringType(type) {
        this.props.setOverlayLoader({ how: true })
        switch (type) {
            case "cluster":
                this.setState({
                    cluster: true,
                    university: false,
                    type: false
                })
                break;
            case "university":
                this.setState({
                    cluster: false,
                    university: true,
                    type: false
                })
                break;
            case "type":
                this.setState({
                    cluster: false,
                    university: false,
                    type: true
                })
                break;
        }
        setTimeout(() => {
            this.props.setColouringType(type);
        }, 500);
    }

    render() {
        const { cluster, university, type } = this.state;
        return <Fragment>
            <h6 className="text-yellow text-code fw-bold text-uppercase"><small>Colouring by:</small></h6>

            <ButtonGroup className='colouring-container pt-0' aria-label="Layers colouring">
                <Button variant="secondary" active={cluster ? true : false} onClick={() => { this.setColouringType('cluster') }}>
                    Topic
                </Button>
                <Button variant="secondary" active={university ? true : false} onClick={() => { this.setColouringType('university') }}>
                    University
                </Button>
                <Button variant="secondary" active={type ? true : false} onClick={() => { this.setColouringType('type') }}>
                    Type
                </Button>
            </ButtonGroup>
        </Fragment>
    }
}

export default ColouringSelector;