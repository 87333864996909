import React from 'react';
import { NavLink } from 'react-router-dom';
import EutNavbar from '../../components/Navbar';
import './index.scss';



function Contact() {

    return <div className='notfound h-100'>
        <EutNavbar />
        <div className='notfound-div h-100'>
            <h1 className='text-code'>404</h1>
            <h2>Page not found</h2>
            <h4 className=''><NavLink to='/' className='fw-light go-home'>Go to the homepage</NavLink></h4>
        </div>

    </div>
}

export default Contact;