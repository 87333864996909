import React from 'react';
import { NavLink } from 'react-router-dom';
import EutNavbar from '../../components/Navbar';
import './index.scss';

import confettiBg from '../../assets/img/static_website/confetti-bg.png';
import mockCopertina from '../../assets/img/static_website/mock-copertina.png';
import slideDeckCopertina from '../../assets/img/static_website/slide-deck-copertina-landscape.png';
import confetti from '../../assets/img/static_website/confetti-slide-deck.png';
import collaborations from '../../assets/img/static_website/collaborations-slide-deck.png';
import parallel from '../../assets/img/static_website/parallel-slide-deck.png';
import Footer from '../../components/Footer';

import tudublin from '../../assets/img/static_website/logos/tudublin_text.jpeg';
import tus from '../../assets/img/static_website/logos/tus_text.jpeg';
import rtu from '../../assets/img/static_website/logos/rtu_text.png';
import utt from '../../assets/img/static_website/logos/utt_text.jpeg';
import h_da from '../../assets/img/static_website/logos/h_da_text.jpeg';
import upct from '../../assets/img/static_website/logos/upct_text.jpeg';
import utcn from '../../assets/img/static_website/logos/utcn_text.jpeg';
import cut from '../../assets/img/static_website/logos/cut_text.jpeg';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import EutSlideDeck from '../../assets/pdfs/EUt+ R&I mapping slide deck.pdf';

import CUTSlideDeck from '../../assets/pdfs/CUT R&I mapping slides.pdf';
import RTUSlideDeck from '../../assets/pdfs/RTU R&I mapping slides.pdf';
import TUSSlideDeck from '../../assets/pdfs/TUS R&I mapping slides.pdf';
import UPCTSlideDeck from '../../assets/pdfs/UPCT R&I mapping slides.pdf';
import UTCNSlideDeck from '../../assets/pdfs/UTCN R&I mapping slides.pdf';
import UTTSlideDeck from '../../assets/pdfs/UTT R&I mapping slides.pdf';
import H_DASlideDeck from '../../assets/pdfs/H_DA R&I mapping slides.pdf';
import TUDublinSlideDeck from '../../assets/pdfs/TU Dublin R&I mapping slides.pdf';

function Homepage() {
    const universities = [
        {
            name: 'University of Technology of Troyes',
            image: utt,
            downloadLink: UTTSlideDeck,
            increase: true
        },
        {
            name: 'Darmstadt University of Applied Sciences',
            image: h_da,
            downloadLink: H_DASlideDeck,
        },
        {
            name: 'Riga Technical University',
            image: rtu,
            downloadLink: RTUSlideDeck,
        },
        {
            name: 'Technological University Dublin',
            image: tudublin,
            downloadLink: TUDublinSlideDeck,
            increase: true
        },
        {
            name: 'Technical University of Sofia',
            image: tus,
            downloadLink: TUSSlideDeck,
        },
        {
            name: 'Cyprus University of Techonology',
            image: cut,
            downloadLink: CUTSlideDeck,
            increase: true
        },
        {
            name: 'Universidad Politécnica de Cartagena',
            image: upct,
            downloadLink: UPCTSlideDeck,
            increase: true
        },
        {
            name: 'Technical University of Cluj-Napoca',
            image: utcn,
            downloadLink: UTCNSlideDeck,
        }
    ]

    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip" >
            {text}
        </Tooltip>
    );

    const downloadSlideDeck = (link) => {
        // using Java Script method to get PDF file
        fetch(link).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'EUt+ R&I mapping slide deck.pdf';
                alink.click();
            })
        })
    }

    return <div className='homepage h-100 d-flex flex-column '>
        <EutNavbar />
        <div className='content-container container-fluid animate__animated animate__fadeIn'>
            <div className='row h-100'>
                <div
                    style={{ backgroundImage: `url(${(confettiBg)}` }}
                    className='left-flex-container col-md-6 col-12 px-md-5 pt-5'>
                    <div className='transparent-card mt-auto my-5'>
                        <h3 className='text-white'>Interactive Platform</h3>
                        <p className='text-white mt-2'>This platform is a tool aimed at presenting and facilitating the interactive exploration of research and innovation capacities of the 8 EUt+ universities. Thanks to topic modeling it groups together documents that deal with similar thematics, allowing to see R&I overlaps between partners, paving the way for further collaboration.</p>
                        <NavLink to='/dashboard' className='btn mt-3 w-100'>Explore the platform</NavLink>
                    </div>
                </div>
                <div className='col-md-6 col-12'>
                    <div className='right-flex-container d-flex flex-column h-100'>
                        <div className='flex-1 text-center h-50'>
                            <div className='row h-100 ps-md-5'>
                                <div className='col-md-6 col-12 py-5 my-auto text-start'>
                                    <h3 className='text-blue'>EUt+ R&I mapping slide deck</h3>
                                    <p>A slide deck providing an in-depth analysis of the R&I portfolio of EUt+ as a whole, with focus on distinctive capacities, internal and external collaborations, and the alignment of research and innovation with societal challenges and territorial priorities.</p>
                                    <div className='d-flex pe-5'>
                                        <a target='_blank' href={EutSlideDeck} className='btn btn-blue mt-3 w-100'>Read</a>
                                        <a target='_blank' href={EutSlideDeck} download="EUt+ R&I mapping slide deck" className='btn btn-blue mt-3 w-100 ms-2'>Download</a>
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 my-auto'>
                                    <img className='report-img rounded-img my-auto' src={slideDeckCopertina}></img>
                                    <div className='d-flex mt-2 slide-deck-sub-imgs'>
                                        <img src={confetti} className='slide-deck-sub-img confetti'></img>
                                        <img src={collaborations} className='slide-deck-sub-img collaborations'></img>
                                        <img src={parallel} className='slide-deck-sub-img parallel'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-1 text-center bg-light-blue my-mt-2 my-5 pb-4'>
                            <div className='row h-100 px-5'>
                                <div className='col-md-6 col-12 py-5 my-auto text-start'>
                                    <h3 className='text-blue'>Institutional R&I mapping slide decks</h3>
                                    <p>The institutional slide decks present key charts and descriptive visualizations of the research and innovation activities of each EUt+ member, It is based on the EUt+ mapping slide deck, with the same data sources and similar analysis.</p>
                                    <div className='d-flex pe-5'>
                                        <NavLink to='/slide-deck' className='btn btn-blue mt-3 w-100'>Read</NavLink>
                                        {/* <NavLink to='/slide-deck' className='btn btn-blue mt-3 w-100 ms-2'>Download</NavLink> */}
                                    </div>
                                </div>
                                <div className='col-md-6 col-12 my-auto'>
                                    <div className='row my-auto'>
                                        {universities.map((uni, index) => {
                                            return <div key={uni.name} className={'uni-col col-6 mt-4 my-auto'}>

                                                <div className='uni-logo-img-btns p-relative'>
                                                    <img className={'uni-logo me-1 my-auto ' + (uni.increase ? 'increase' : '')} src={uni.image} />


                                                    <div className='uni-hover-btns d-flex flex-column my-auto'>

                                                        <a target='_blank' href={uni.downloadLink} className='btn'>
                                                            <i className='fas fa-sm fa-eye mx-1'></i>
                                                        </a>
                                                        <a target='_blank' href={uni.downloadLink} download="EUt+ R&I mapping slide deck" className='btn'>
                                                            <i className='fas fa-sm fa-download mx-1'></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    {/* <img className='report-img' src={mockCopertina}></img> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <div className='text-center p-4'>
                        <h6 className='text-muted'>The three toolkit products from Task 4.1 will be complementary with the products and insights currently in development in SWAFs tasks 3.2 (mapping demand by SMEs) 4.2 (local socioeconomic priorities), 4.3 (external research trends) and 5.2 (EUt+ common research infrastructure). They also benefit greatly from the common EUt+ <a href="https://www.openaire.eu/" target="_blank">OpenAire research portal</a></h6>
                    </div>
                </div>
            </div>

        </div>
        <Footer />

    </div >
}

export default Homepage;