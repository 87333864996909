import React, { Component, Fragment } from 'react';
import { Stage, AppConsumer } from '@inlet/react-pixi'
import * as d3 from 'd3';
import _ from 'lodash';

import {
    CONFIG
} from '../../common/constants';

import {
    ProjectDot,
    ClustersSVG,
    LabelsSVG,
    Line
} from './Canvas';

import './index.scss';

class TopicsCanvas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clickedXY: []
        }

        this.canvasRef = React.createRef();
    }

    async componentDidMount() {
        // Call any function in provider
        // document.addEventListener("keydown", (e) => { if (e.key === 'Control') document.body.style.cursor = "crosshair"; });
        // document.addEventListener("keyup", (e) => { if (e.key === 'Control') document.body.style.cursor = "unset"; });

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (_.isEqual(this.props.data.topics.data, nextProps.data.topics.data)
            && _.isEqual(this.props.filters, nextProps.filters)
            && _.isEqual(this.props.canvasApplication, nextProps.canvasApplication)
            && _.isEqual(this.state.clickedXY.length, nextState.clickedXY.length
                && _.isEqual(this.props.getFilteredProjects(), nextProps.getFilteredProjects())
                && _.isEqual(this.props.getFilteredTopics(), nextProps.getFilteredTopics()))
        ) {
            // console.log("TopicsCanvas -> Has not change");
            return false;
        } else {
            // console.log("TopicsCanvas -> shouldComponentUpdate");
            this.props.displayFilters();
            console.log("THIS CANVASREF", this.canvasRef.current)
            return true;
        }
    }

    render() {

        const { data, canvasWidth, canvasHeight, setCanvasApplication } = this.props;
        console.log(this.props)


        // SCALES
        const xScale = d3.scaleLinear()
            .range([CONFIG.TOPICS.LAYOUT.MARGIN, canvasWidth - CONFIG.TOPICS.LAYOUT.MARGIN])
            .domain(d3.extent(data.topics.data, function (datum) { return datum.x })),
            yScale = d3.scaleLinear()
                .range([CONFIG.TOPICS.LAYOUT.MARGIN, canvasHeight - CONFIG.TOPICS.LAYOUT.MARGIN])
                .domain(d3.extent(data.topics.data, function (datum) { return datum.y }));
        var topicColorScale = d3.scaleOrdinal()
            .domain(_.map(data.topics.topics, topic => topic.cluster))
            .range(
                [].concat(d3.schemeSet1)
                    .concat(d3.schemeSet2)
                    .concat(d3.schemeSet3)
                    .concat(d3.schemeTableau10)
                    .concat(d3.schemePaired)
                    .slice(0, data.topics.topics.length)
            );


        const dotSizeScale = CONFIG.APP.PROJECT_RELATED ? d3.scaleLinear()
            .range([3, 11])
            .domain(d3.extent(data.topics.data, function (datum) { return datum.funding })) : () => { return 2 };

        return <Fragment>
            <Stage
                id={'canvas'}
                ref={this.canvasRef}
                width={canvasWidth}
                height={canvasHeight}
                options={{
                    antialias: true,
                    backgroundAlpha: 0,
                    autoDensity: true,
                    resolution: 1,
                    transparent: true,
                }}>
                <AppConsumer>
                    {app => setCanvasApplication(app)}
                    {/* {app => APP(app)} */}
                </AppConsumer>
                {
                    this.props.canvasApplication &&
                    this.props.canvasApplication.stage &&
                    this.props.canvasApplication.stage.children &&
                    _.map(data.topics.data
                        .filter(x => (x.type == "National projects" || x.type == "H2020" || x.type == "Patents" || x.type == "Publications")),
                        (datum, index) => {
                            // if (datum.type != "Publications")
                            // console.log("DATUM NO PUB", datum)
                            return this.props.getFilteredProjects().includes(datum.id) ? <ProjectDot
                                key={index}
                                x={xScale(datum.x)}
                                y={yScale(datum.y)}
                                dotColor={topicColorScale(datum.cluster)}
                                topicColorScale={topicColorScale}
                                dotSize={dotSizeScale(datum.funding)}
                                datum={datum}
                                canvasApplication={this.props.canvasApplication}
                                getFilteredProjects={this.props.getFilteredProjects}
                                getFilteredTopics={this.props.getFilteredTopics}
                                openOffCanvasMenu={this.props.openOffCanvasMenu}
                                openAnalyticsModal={this.props.openAnalyticsModal}
                                getTopicInformation={this.props.getTopicInformation}
                                getClickedDot={this.props.getClickedDot}
                                getClickedLabel={this.props.getClickedLabel}
                                dotHandleOver={this.props.dotHandleOver}
                                dotHandleOut={this.props.dotHandleOut}
                                colouringType={this.props.colouringType}
                            ></ProjectDot> : <></>
                        })
                }

                <Line
                    line_coords={this.state.clickedXY}
                    {...this.props}
                ></Line>
            </Stage >

            {
                this.props.canvasApplication &&
                this.props.canvasApplication.stage &&
                this.props.canvasApplication.stage.children &&
                <LabelsSVG
                    xScale={xScale}
                    yScale={yScale}
                    colorScale={topicColorScale}
                    {...this.props}></LabelsSVG>
            }

            <ClustersSVG
                xScale={xScale}
                yScale={yScale}
                colorScale={topicColorScale}
                {...this.props}></ClustersSVG >

        </Fragment >
    }
}

export default TopicsCanvas;