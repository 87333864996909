import { Component } from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import './index.scss';

import infoIcon from '../../assets/img/info-icon.svg'


class ReactTooltip extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                {this.props.tooltipText}
            </Tooltip>
        );

        return (
            <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 400 }}
                overlay={renderTooltip}
            >
                {/* <small className='text-muted'>{this.props.text}</small> */}
                {this.props.text == "INFO" ? <img className='info-icon' style={{ 'filter': this.props.color == 'black' ? 'invert(1)' : 'invert(0)' }} src={infoIcon}></img> :
                    this.props.text == "DOWNLOAD" ? <i className='fas fa-download'></i> : <></>}

            </OverlayTrigger >
        );
    }
}

export default ReactTooltip;