import React from 'react';
import './index.scss';
import eutLogo from '../../assets/img/eut-header-logo.png'


function Footer() {
    return <div className='mt-auto'>
        <div className='eut-footer p-5'>
            <img width={150} src={eutLogo} />
        </div>
        <div className='eut-footer-darker py-4'>
        </div>
    </div>
}

export default Footer;