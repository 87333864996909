import React from 'react';
import { NavLink } from 'react-router-dom';
import EutNavbar from '../../components/Navbar';
import './index.scss';

import confettiBg from '../../assets/img/static_website/confetti-bg.png';
// import mockCopertina from '../../assets/img/static_website/mock-copertina.png';
import institutionalSlideDeck from '../../assets/img/static_website/institutional-slide-deck-copertina-landscape.png';
import Footer from '../../components/Footer';

import tudublin from '../../assets/img/static_website/logos/tudublin_text.jpeg';
import tus from '../../assets/img/static_website/logos/tus_text.jpeg';
import rtu from '../../assets/img/static_website/logos/rtu_text.png';
import utt from '../../assets/img/static_website/logos/utt_text.jpeg';
import h_da from '../../assets/img/static_website/logos/h_da_text.jpeg';
import upct from '../../assets/img/static_website/logos/upct_text.jpeg';
import utcn from '../../assets/img/static_website/logos/utcn_text.jpeg';
import cut from '../../assets/img/static_website/logos/cut_text.jpeg';

import Tooltip from 'react-bootstrap/Tooltip';

import EutSlideDeck from '../../assets/pdfs/EUt+ R&I mapping slide deck.pdf';
import CUTSlideDeck from '../../assets/pdfs/CUT R&I mapping slides.pdf';
import RTUSlideDeck from '../../assets/pdfs/RTU R&I mapping slides.pdf';
import TUSSlideDeck from '../../assets/pdfs/TUS R&I mapping slides.pdf';
import UPCTSlideDeck from '../../assets/pdfs/UPCT R&I mapping slides.pdf';
import UTCNSlideDeck from '../../assets/pdfs/UTCN R&I mapping slides.pdf';
import UTTSlideDeck from '../../assets/pdfs/UTT R&I mapping slides.pdf';
import H_DASlideDeck from '../../assets/pdfs/H_DA R&I mapping slides.pdf';
import TUDublinSlideDeck from '../../assets/pdfs/TU Dublin R&I mapping slides.pdf';

function Report() {

    const universities = [
        {
            name: 'University of Technology of Troyes',
            image: utt,
            downloadLink: UTTSlideDeck,
            increase: true
        },
        {
            name: 'Darmstadt University of Applied Sciences',
            image: h_da,
            downloadLink: H_DASlideDeck,
        },
        {
            name: 'Riga Technical University',
            image: rtu,
            downloadLink: RTUSlideDeck,
        },
        {
            name: 'Technological University Dublin',
            image: tudublin,
            downloadLink: TUDublinSlideDeck,
            increase: true
        },
        {
            name: 'Technical University of Sofia',
            image: tus,
            downloadLink: TUSSlideDeck,
        },
        {
            name: 'Cyprus University of Techonology',
            image: cut,
            downloadLink: CUTSlideDeck,
            increase: true
        },
        {
            name: 'Universidad Politécnica de Cartagena',
            image: upct,
            downloadLink: UPCTSlideDeck,
            increase: true
        },
        {
            name: 'Technical University of Cluj-Napoca',
            image: utcn,
            downloadLink: UTCNSlideDeck,
        }
    ]
    const renderTooltip = (text) => (
        <Tooltip id="button-tooltip" >
            {text}
        </Tooltip>
    );

    return <div className='slide-deck h-100'>
        <EutNavbar />
        <div className='page-title'>
            <h1 className='text-white text-uppercase'>Institutional R&I mapping slide decks</h1>
        </div>
        <div className='content-container container-fluid px-md-5 pb-5 animate__animated animate__fadeIn'>
            <div className='row d-flex h-100'>
                <div className='col-md-5 col-12'>
                    <img className='copertina-big rounded-img' src={institutionalSlideDeck} />
                </div>
                <div className='col-md-7 px-5 my-auto'>
                    <div className='right-flex-container text-start d-flex flex-column h-100'>
                        <h3 className='text-blue fw-400'>Each of the institutional slide decks includes key charts and descriptive visualizations for every EUt+ member, offering a comprehensive summary of the R&I activities conducted by each institution.</h3>
                        <div className='content-list mt-4'>
                            <p>The slide deck is divided into the following sections:</p>
                            <ul>
                                <li>Context;</li>
                                <li>Institutional distinctive capacities;</li>
                                <li>Collaborations;</li>
                                <li>Societal and territorial challenges.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <div className='universities-list mt-2 mx-auto'>
                        <div className='row'>
                            {universities.map((uni, index) => {
                                return <div key={uni.name} className={'university-col col-md-6 col-12 mt-4 ' + (index % 2 != 0 ? 'ps-md-0' : 'me-md-5')}>
                                    <div className='d-flex w-100'>
                                        <img className={'uni-logo me-4 my-auto ' + (uni.increase ? 'increase' : '')} src={uni.image} />
                                        <div className='d-flex flex-column w-100'>
                                            <h4 className='uni-name fw-bold'>{uni.name}</h4>

                                            <div className='d-flex mt-2'>
                                                <a target='_blank' href={uni.downloadLink} className='btn btn-blue w-100 fw-bold'>Read</a>
                                                <a target='_blank' href={uni.downloadLink} download="EUt+ R&I mapping slide deck" className='btn btn-blue w-100 fw-bold ms-2'>Download</a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >
}

export default Report;