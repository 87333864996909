import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import DropdownIndicator from 'react-select';
import { ActionMeta, OnChangeValue } from 'react-select';
import './index.scss';

class FilterKeywordSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orQuery: true,
            currentSearch: null
        }
    }

    async componentDidMount() {
        // Call any function in provider
    }

    searchKeywords = () => {
        this.props.setOverlayLoader({ how: true })
        setTimeout(() => {
            this.props.setFilter({
                filter: [{
                    value: this.state.currentSearch,
                    label: this.state.orQuery,
                    sort: this.state.currentSearch,
                }],
                type: 'keyword_search'
            })
        }, 500);
    }

    handleSelected = (options, { action, removedValue }) => {

        var search = options.map(x => x.value)

        this.setState({
            currentSearch: search
        })

        this.searchKeywords();
    }

    render() {
        const { options, placeholder } = this.props;

        return <Fragment>
            <CreatableSelect
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({
                        ...provided, zIndex: 9999, borderRadius: 5,
                        paddingTop: 2,
                        paddingBottom: 2,
                        paddingLeft: 5,
                        paddingRight: 5,
                    }),
                    option: (base, { data, isDisabled, isFocused, isSelected }) => {
                        return {
                            ...base,
                            borderRadius: 5,
                            backgroundColor: isFocused ? "#dfdfdf" : "white",
                            color: isFocused ? "var(--blue-color)" : "var(--darker-blue-color)",
                            fontWeight: isFocused ? "bold" : "normal",
                        };
                    }
                }
                }
                onChange={this.handleSelected}
                isMulti
                isClearable={true}
                allowCreateWhileLoading={true}
                formatCreateLabel={(x) => { return 'Add ' + x }}
                openMenuOnClick={false}
                noOptionsMessage={() => { return '' }}
                placeholder={<div className='text-truncate'>{placeholder}</div>}
                className={this.props.dynamicClassName ? this.props.dynamicClassName : 'react-select-container'}
                classNamePrefix={'kw_search_select'}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--blue-color)',
                        primary: 'var(--darker-blue-color)',
                        neutral0: 'white',
                        neutral20: 'var(--darker-blue-color)',
                    },
                })} />

            <div className='mt-2'>
                <div className="form-check">
                    <input onClick={() => {
                        this.setState({
                            orQuery: true
                        })
                        this.searchKeywords();
                    }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" readOnly checked={this.state.orQuery} />
                    <small className="form-check-label text-white" for="flexRadioDefault1">
                        Documents with at least <b>one</b> of the keywords
                    </small>
                </div>
                <div className="form-check">
                    <input onClick={() => {
                        this.setState({
                            orQuery: false
                        })
                        this.searchKeywords();
                    }} className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" readOnly checked={!this.state.orQuery} />
                    <small className="form-check-label text-white" for="flexRadioDefault2">
                        Documents with <b>all</b> the keywords
                    </small>
                </div>
            </div>
        </Fragment>
    }
}

export default FilterKeywordSearch;