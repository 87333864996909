import React from 'react';
import { NavLink } from 'react-router-dom';
import EutNavbar from '../../components/Navbar';
import './index.scss';

import confettiBg from '../../assets/img/static_website/confetti-bg.png';
// import mockCopertina from '../../assets/img/static_website/mock-copertina.png';
import slideDeckCopertina from '../../assets/img/static_website/slide-deck-copertina-landscape.png';
import Footer from '../../components/Footer';

function Report() {
    return <div className='report h-100 '>
        <EutNavbar />
        <div className='page-title'>
            <h1 className='text-white'>EUt+ R&I MAPPING SLIDE DECK</h1>
        </div>
        <div className='content-container container animate__animated animate__fadeIn'>
            <div className='row h-100'>
                <div
                    className='col-md-6 col-12 pe-5 my-auto'>
                    <img className='copertina-big rounded-img' src={slideDeckCopertina} />
                </div>
                <div className='col-md-6 col-12 ps-5'>
                    <div className='right-flex-container text-start d-flex flex-column h-100'>
                        <h3 className='text-blue fw-400'>The report offers a wide overview of all the research and innovation activities of the eight partners, providing strategic insights as well as descriptive plots and practical use cases.</h3>
                        <div className='content-list mt-4'>
                            <p>The report is divided into the following sections:</p>
                            <ul>
                                <li>Context and objectives;</li>
                                <li>Data sources and integration;</li>
                                <li>Topic modeling;</li>
                                <li>Contribution to SDG;</li>
                                <li>Contribution to S3;</li>
                                <li>Collaborations.</li>
                            </ul>
                        </div>
                        <div className='d-flex pe-5 mt-2'>
                            <a href='' className='btn btn-blue mt-3 w-100 py-3 fw-bold'>Read</a>
                            <a href='' className='btn btn-blue mt-3 ms-2 w-100 py-3 fw-bold'>Download</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Report;