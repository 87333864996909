import * as d3 from 'd3';

export const zoomed = function ({
    transform = null,
    canvasApp = null
}) {
    const gLabels = d3.select(".svg-labels g");
    gLabels.attr("transform", transform);

    const gClusters = d3.select(".svg-clusters g");
    gClusters.attr("transform", transform);

    gLabels.selectAll("text").style("font-size", 14 / transform.k + "px");
    gLabels.selectAll("text").attr("x", d => d.bbox.x - (d.bbox.width * 0.5));
    gLabels.selectAll("text").attr("y", d => d.bbox.y);
    gLabels.selectAll("text").attr("dy", navigator.userAgent.indexOf("Firefox") > -1 ? d => 1 / transform.k + "%" : '0%');

    gLabels.selectAll("rect").attr("x", d => d.bbox.x - (d.bbox.width * 0.5));
    gLabels.selectAll("rect").attr("y", d => d.bbox.y);
    gLabels.selectAll("rect").attr("width", d => (d.bbox.width * 0.9) / transform.k);
    // gLabels.selectAll("rect").attr("height", d => (d.bbox.height * 0.8) / transform.k);

    gLabels.selectAll("rect").attr("rx", 8 / transform.k);
    gLabels.selectAll("rect").style("height", 30 / transform.k + "px");

    canvasApp.stage.scale.x = transform.k;
    canvasApp.stage.scale.y = transform.k;
    canvasApp.stage.position.x = transform.x;
    canvasApp.stage.position.y = transform.y;

    console.log("CHILDO", canvasApp.stage.children[0])
    console.log("Transform", transform)



    canvasApp.stage.children.forEach(function (child) {

        // if (transform.k * 0.8 > 0.9) {
        //     // return 0.8;
        //     // child.radius = child.radius / (transform.k * 0.8);
        //     child.scale.x = 5 / transform.k * 0.8;
        //     child.scale.y = 5 / transform.k * 0.8;
        // }

        // child.x += transform.x
        // child.y += transform.y
    })
}