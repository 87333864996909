import * as d3 from 'd3';
import {
    SPARQL_ENDPOINT,
    CONFIG
} from '../common/constants';

import { queries } from '../common/queries/index';

import query from '../common/sparql/index';
import axios from 'axios'
// const ecUrlLocal = 'http://192.168.0.191:9200/ut+_v2/_search' // local
// const ecUrl = 'http://77.231.127.11:9400/ut+_v2/_search'


const ecUrlLocal = 'https://eutplusapi.unics.cloud/ut+_v2/_search';
const ecUrl = 'https://eutplusapi.unics.cloud/ut+_v2/_search';
const ecHeaders = { 'Accept': 'application/json', 'Content-type': 'application/json' }
// From SPARQL endpoint

var ecLocal = false;

export async function getProjectsInformation(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.projectsInformation(array)
    );

    return result;
}

export async function getProjectsKeywords(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.projectsKeywords(array)
    );

    return result;
}

export async function getProjectsParticipants(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.projectsParticipants(array)
    );

    return result;
}

export async function getProjectsSectors(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.projectsSectors(array)
    );

    return result;
}

export async function getProjectsTechnologies(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.projectsTechnologies(array)
    );

    return result;
}

export async function getProjectsSDGs(array) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.projectsSDGs(array)
    );

    return result;
}

export async function getProjectParticipantsInformation(projectId) {
    const result = await query(
        SPARQL_ENDPOINT,
        queries.projectParticipantsInformation(projectId)
    );

    return result;

}

// From local sources

export async function getProjectCoords() {

    const projectCoords = d3.json(CONFIG.TOPICS.DATA_FOLDERS.COORDS + "/coords.json", function (error, data) {
        if (error) {
            return console.warn(error);
        }
        return data;
    });

    return projectCoords;
}

export async function getTopics() {

    const topics = d3.csv(CONFIG.TOPICS.DATA_FOLDERS.COORDS + "/topics.csv", function (data) {
        return data;
    });

    return topics;
}

// export async function keywordSearch(text) {
//     text = text.split(',').map(x => x.trim().toLowerCase()).join(' OR ');
//     var query = {
//         "query_string": {
//             "query": text,
//             "default_field": "abstract"
//         }
//     };
//     return axios.post(ecUrl, { "from": 0, "size": 10000, "query": query }, { headers: ecHeaders })
//         .then((res) => {
//             return res.data.hits.hits.map(x => x._id)
//         })
//         .catch((err) => {
//             console.log("Kw search error", err)
//         })
// }

export async function checkEcLocal() {

    console.log("Checking EC")
    return axios
        .get(ecUrl, { headers: ecHeaders }, { timeout: 2000 })
        .then((res) => {
            console.log("Checking EC - Passed")
            return true;
        })
        .catch((err) => {
            console.log("Checking EC - Error")
            ecLocal = true;
            return false;
        })
}


function creteKeywordSearchQuery(kw_list, orQuery) {
    var kw_texts = kw_list.map(x => x.trim().toLowerCase())
    var kw_array = []
    kw_texts.forEach(kw => {
        let query_obj = {
            "match_phrase": {
                "abstract": {
                    "query": kw
                }
            }
        };
        kw_array.push(query_obj)
    });

    var query = {}
    if (orQuery == true)
        query = { "bool": { "should": kw_array } };
    else
        query = { "bool": { "must": kw_array } };

    return query
}

export async function keywordSearch(kw_list, orQuery) {
    // console.log("Checking EC - is local?", ecLocal, "requesting with address", ecLocal ? ecUrlLocal : ecUrl)

    var kw_query = creteKeywordSearchQuery(kw_list, orQuery)

    var full_query = { "from": 0, "size": 10000, "query": kw_query }

    return axios
        .post(ecLocal ? ecUrlLocal : ecUrl, full_query, { headers: ecHeaders })
        .then((res) => {
            return res.data.hits.hits.map(x => x._id)
        })
        .catch((err) => {
            console.log("Kw search error", err)
        })
}

export async function getAbstract(id) {
    var query = {
        "query_string": {
            "query": id,
            "default_field": "ID"
        }
    };
    return axios.post(ecLocal ? ecUrlLocal : ecUrl, { "from": 0, "size": 1, "query": query }, { headers: ecHeaders }, { timeout: 5000 })
        .then((res) => {
            let abstract = res.data.hits.hits[0]['_source'].abstract

            return abstract != 'nan' && abstract != '' ? abstract : 'Not available'
        })
        .catch((err) => {
            console.log("Abstract search error", err)
            return 'Service not available'
        })
}

export async function getPublicationsEC(filters, kw_list, orQuery) {
    const filters_array = []
    filters
        .filter(x => x.type != 'keyword_search') // added separately later
        .forEach(filter_obj => {
            const query_obj = { "bool": { "should": [] } };
            let currentType = filter_obj['type'];

            if (currentType == 'type') currentType = 'doc_type'
            if (currentType == 'university') currentType = 'uni'
            if (currentType == 'topics') currentType = 'cluster'
            if (currentType == 'years') currentType = 'year'

            filter_obj['values'].forEach(filter_value => {
                const query_sub_obj = { 'match_phrase': {} }
                query_sub_obj['match_phrase'][currentType] = { "query": filter_value.toString() }

                query_obj['bool']['should'].push(
                    query_sub_obj
                )
            });
            if (query_obj['bool']['should'].length > 0)
                filters_array.push(query_obj)
        });

    var query = {}

    if (kw_list) {
        const kw_query = creteKeywordSearchQuery(kw_list, orQuery)
        filters_array.push(kw_query)
    }

    query = {
        "bool": {
            "must": filters_array
        }
    };

    const full_query = {
        "from": 0,
        "size": 10000,
        "_source": ["ID", "type", "year"],
        "query": query,
    }

    return axios
        .post(ecLocal ? ecUrlLocal : ecUrl, full_query, { headers: ecHeaders })
        .then((res) => {
            return res.data.hits.hits;
        })
        .catch((err) => {
            console.log("Summary search error", err)
        })
}

export async function getSummaryEC(filters, kw_list, orQuery) {
    var filters_array = []
    filters
        .filter(x => x.type != 'keyword_search') // added separately later
        .forEach(filter_obj => {
            let query_obj = { "bool": { "should": [] } };
            let currentType = filter_obj['type'];

            if (currentType == 'type') currentType = 'doc_type'
            if (currentType == 'university') currentType = 'uni'
            if (currentType == 'topics') currentType = 'cluster'
            if (currentType == 'years') currentType = 'year'

            filter_obj['values'].forEach(filter_value => {

                let query_sub_obj = { 'match_phrase': {} }
                query_sub_obj['match_phrase'][currentType] = { "query": filter_value.toString() }

                query_obj['bool']['should'].push(
                    query_sub_obj
                )
            });
            if (query_obj['bool']['should'].length > 0)
                filters_array.push(query_obj)
        });

    var query = {}

    if (kw_list) {
        var kw_query = creteKeywordSearchQuery(kw_list, orQuery)
        filters_array.push(kw_query)
    }

    query = {
        "bool": {
            "must": filters_array
        }
    };

    var full_query = {
        "from": 0,
        "size": 0,
        "query": query,
        "aggs": {
            "column_to_group": {
                "terms": {
                    "field": "doc_type.raw",
                    "size": 300
                }
            }
        }
    }

    return axios
        .post(ecLocal ? ecUrlLocal : ecUrl, full_query, { headers: ecHeaders })
        .then((res) => {
            return res.data.aggregations.column_to_group.buckets;
        })
        .catch((err) => {
            console.log("Summary search error", err)
        })
}

export async function getSummary() {
    const summary = d3.csv(CONFIG.TOPICS.DATA_FOLDERS.COORDS + "/summary.csv", function (data) {
        data['SDGs'] = (data['SDGs']).split(';')
        data['uni'] = (data['uni']).split(';')
        return data;
    });

    return summary;
}

export async function getParticipantsTest() {
    const topics = d3.csv(CONFIG.TOPICS.DATA_FOLDERS.COORDS + "/participants_test.csv", function (data) {
        return data;
    });

    return topics;
}



export async function getDiseases() {

    const diseases = d3.csv(CONFIG.TOPICS.DATA_FOLDERS.COORDS + "/ris3cat_diseases.csv", function (data) {
        return data;
    });

    return diseases;
}