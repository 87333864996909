export const queries = {
  projectsInformation: (array) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id ?title ?year ?funding ?grant ?frameworkName
  
  WHERE {
    ?project a :Project.
    ?project :projectId ?id.
    FILTER(${array.map(function (id, index) {
    return '?id = "' + id + (index !== array.length - 1 ? '" || ' : '"')
  }).join('')
    })
    ?project :title ?title .
    ?project :startingYear ?year .
    ?project :framework ?framework .
    ?framework :extendedName ?frameworkName .
    optional {
      ?project :totalInvestment ?funding .
    }
    optional {
      ?project :totalGrant ?grant .
    }
  }
  `,
  projectsKeywords: (array) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id (GROUP_CONCAT(DISTINCT ?keyword ; separator=";") AS ?keywords)
  
  WHERE {
    ?project a :Project.
    ?project :projectId ?id.
    FILTER(${array.map(function (id, index) {
    return '?id = "' + id + (index !== array.length - 1 ? '" || ' : '"')
  }).join('')
    })
    optional { 
      ?project :keyword ?keywordProp .   
      ?keywordProp :textualValue ?keyword .     
    } 
  }
  GROUP BY ?id
  `,
  projectsParticipants: (array) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id ?participantId ?participantName ?activityTypeId ?investment ?grant
  
  WHERE {
    ?project a :Project.
    ?project :projectId ?id.
    FILTER(${array.map(function (id, index) {
    return '?id = "' + id + (index !== array.length - 1 ? '" || ' : '"')
  }).join('')
    })
    ?project :reifiedParticipant ?participantNode .  
    optional { ?participantNode :investment ?investment } .  
    optional { ?participantNode :grant ?grant } .  
    ?participantNode :nuts2 ?participantNuts2 .
    ?participantNuts2 :identifier ?participantNuts2Id .              
    FILTER (?participantNuts2Id = "ES51")   
    ?participantNode :organization ?organization .         
    ?organization :unicsId ?participantId .
    ?organization :extendedName ?participantName .
    ?participantNode :ecActivityType ?activityType .         
    ?activityType :shortName ?activityTypeId .  
  }
  `,
  projectsSectors: (array) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id ?sectorName
  
  WHERE {
    ?project a :Project.
    ?project :projectId ?id.
    FILTER(${array.map(function (id, index) {
    return '?id = "' + id + (index !== array.length - 1 ? '" || ' : '"')
  }).join('')
    })
    ?project :classification ?category .                 
    ?category :classificationName ?categoryTypeName .              
    FILTER(?categoryTypeName = "RIS3CAT Àmbit Sectorial Líder")
    ?category :extendedName ?sectorName .  
  }
  `,
  projectsTechnologies: (array) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id ?technologyName
  
  WHERE {
    ?project a :Project.
    ?project :projectId ?id.
    FILTER(${array.map(function (id, index) {
    return '?id = "' + id + (index !== array.length - 1 ? '" || ' : '"')
  }).join('')
    })
    ?project :classification ?category .                 
    ?category :classificationName ?categoryTypeName .              
    FILTER(?categoryTypeName = "RIS3CAT Tecnologia Facilitadora Transversal")
    ?category :extendedName ?technologyName .  
  }
  `,
  projectsSDGs: (array) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>  
  SELECT ?id ?sdgId ?sdgName
  
  WHERE {
    ?project a :Project.
    ?project :projectId ?id.
    FILTER(${array.map(function (id, index) {
    return '?id = "' + id + (index !== array.length - 1 ? '" || ' : '"')
  }).join('')
    })
    optional {   
      ?project :sdgReified ?sdgReified .   
      ?sdgReified :sdg ?sdg .   
      ?sdg :shortName ?sdgId . 
      ?sdg :extendedName ?sdgName . 
    }
  }`,
  projectParticipantsInformation: (projectId) => `PREFIX : <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69#>    
  PREFIX ris3cat: <http://www.semanticweb.org/ontologies/2016/4/untitled-ontology-69/ris3cat#>
  SELECT distinct
  ?ecParticipantName
  # ?abstract
  # ?totalCost
  ?ecContribution
  # ?cityName
  ?countryName
  WHERE {
    ?proj a :Project .
    ?proj :simpleProjectId ?simpleProjectId .
    FILTER(?simpleProjectId = "${projectId}") .
    # ?proj :abstract ?abstract .
    # ?proj :totalInvestment ?totalCost .
    ?proj :reifiedParticipant ?ecParticipant .
    ?ecParticipant :nuts2 ?participantNuts2 .
    ?participantNuts2 :identifier ?participantNuts2Id .              
    FILTER (?participantNuts2Id = "ES51")    
    ?ecParticipant :organization ?organization . 
    ?organization :extendedName ?ecParticipantName .
    ?ecParticipant :grant ?ecContribution .
    # optional { ?ecParticipant :cityName ?cityName } .
    ?ecParticipant :country ?country .
    ?country :extendedName ?countryName .
    FILTER(?countryName = "Spain")
  }
  `
}


