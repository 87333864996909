import React, { Component, Fragment } from 'react';
import './index.scss';
class ItemHeader extends Component {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        // Call any function in provider
    }



    render() {
        const { actor } = this.props;
        return <Fragment>
            <header className='d-flex px-4 pt-4'>
                <h4 className='m-0 text-capitalize'>{actor['final_Name']}</h4>
                <div className="ms-auto">
                    <small className='text-uppercase'>{actor['final_type']}</small>
                </div>
            </header>
            <div className="d-flex px-4 pb-4">
                <h5 className='text-muted m-0'>{actor['country']}</h5>
                <div className="ms-auto">
                    <small>Total Documents: <strong>{actor['Total Documents']}</strong> | patents <strong>{actor['Documents patents']}</strong></small>
                </div>
            </div>
        </Fragment>
    }
}

export default ItemHeader;