import React from 'react';
import { NavLink } from 'react-router-dom';
import EutNavbar from '../../components/Navbar';
import './index.scss';

import confettiBg from '../../assets/img/static_website/confetti-bg.png';
import mockCopertina from '../../assets/img/static_website/mock-copertina.png';
import Footer from '../../components/Footer';
import eut from '../../assets/img/eut-header-logo.png';
// import tudublin from '../../assets/img/static_website/logos/tudublin.png';
// import tus from '../../assets/img/static_website/logos/tus.png';
// import rtu from '../../assets/img/static_website/logos/rtu.png';
// import utt from '../../assets/img/static_website/logos/utt.png';
// import h_da from '../../assets/img/static_website/logos/h_da.png';
// import upct from '../../assets/img/static_website/logos/upct.png';
// import utcn from '../../assets/img/static_website/logos/utcn.png';
// import cut from '../../assets/img/static_website/logos/cut.png';
import artboard from '../../assets/img/static_website/artboard.png';

import tudublin from '../../assets/img/static_website/logos/tudublin_text.jpeg';
import tus from '../../assets/img/static_website/logos/tus_text.jpeg';
import rtu from '../../assets/img/static_website/logos/rtu_text.png';
import utt from '../../assets/img/static_website/logos/utt_text.jpeg';
import h_da from '../../assets/img/static_website/logos/h_da_text.jpeg';
import upct from '../../assets/img/static_website/logos/upct_text.jpeg';
import utcn from '../../assets/img/static_website/logos/utcn_text.jpeg';
import cut from '../../assets/img/static_website/logos/cut_text.jpeg';

function Contact() {
    const universities = [
        {
            name: 'University of Technology of Troyes',
            image: utt,
            contactList: [{
                name: 'Christophe Couteau',
                email: 'christophe.couteau@utt.fr'
            },
            {
                name: 'Kastriot Gjoni',
                email: 'kastriot.gjoni@utt.fr'
            }],
            increase: true

        },
        {
            name: 'Darmstadt University of Applied Sciences',
            image: h_da,
            contactList: [{
                name: 'Rainer Becker',
                email: 'rainer.becker@h-da.de'
            }, {
                name: 'Anna Herbst',
                email: 'anna.herbst@h-da.de'
            }]
        },
        {
            name: 'Riga Technical University',
            image: rtu,
            contactList: [{
                name: 'Gatis Bazbauers',
                email: 'gatis.bazbauers@rtu.lv'
            }
            ]
        },
        {
            name: 'Technological University Dublin',
            image: tudublin,
            contactList: [
                {
                    name: 'John Donovan',
                    email: 'john.donovan@tudublin.ie'
                },
                {
                    name: 'Andy Maguire',
                    email: 'andy.maguire@tudublin.ie'
                }
            ],
            increase: true

        },
        {
            name: 'Technical University of Sofia',
            image: tus,
            contactList: [{
                name: 'Lidia Galabova',
                email: 'lgalabova@tu-sofia.bg'
            },
            {
                name: 'Elitsa Gieva',
                email: 'gieva@tu-sofia.bg'
            }]
        },
        {
            name: 'Cyprus University of Techonology',
            image: cut,
            contactList: [{
                name: 'Nicolas Tsapatsoulis',
                email: 'nicolas.tsapatsoulis@cut.ac.cy'
            }],
            increase: true

        },

        {
            name: 'Universidad Politécnica de Cartagena',
            image: upct,
            contactList: [{
                name: 'Daniel Robles',
                email: 'daniel.robles@upct.es'
            },
            {
                name: 'Catalina Egea Gilabert',
                email: 'catalina.egea@upct.es'
            }
            ],
            increase: true

        },
        {
            name: 'Technical University of Cluj-Napoca',
            image: utcn,
            contactList: [{
                name: 'Marius Purcar',
                email: 'marius.purcar@ethm.utcluj.ro'
            },
            {
                name: 'Liliana Pop',
                email: 'liliana.pop@staff.utcluj.ro'
            }]
        },
    ]

    return <div className='contact h-100 d-flex flex-column'>
        <EutNavbar />
        <div className='page-artboard '>
            <img src={artboard} />
        </div>
        <div className='mt-5 animate__animated animate__fadeIn'>
            <div className='row mx-auto text-center justify-content-center d-flex h-100 container pb-5 '>
                <h3 className='fw-bold text-blue'>Contact for further information</h3>
                <h5 className='uni-name text-blue fw-bold'>European Research Office</h5>
                <a href={"mailto:ero@univ-tech.eu"} className='uni-name text-dark fw-100'>

                    <h6>ero@univ-tech.eu</h6>
                </a>
                <div className='row mx-auto px-md-5'>
                    {universities.map((uni, index) => {
                        return <div key={uni.name} className={'university-col mx-auto col-md-4 col-12 mt-5'}>
                            <div className='d-flex text-start'>
                                {/* <img className='uni-logo me-3 my-auto' src={uni.image} /> */}
                                <img className={'uni-logo me-4 my-auto ' + (uni.increase ? 'increase' : '')} src={uni.image} />
                                <div className='d-flex flex-column w-100 my-auto'>
                                    {uni.contactList.map((contact, index) => {
                                        return <div className='uni-name-col'>
                                            <h5 className='uni-name text-blue fw-bold'>{contact.name}</h5>
                                            {/* <h6 className='uni-name text-dark fw-100'>{contact.email}</h6> */}
                                            <a href={"mailto:" + contact.email} className='uni-name text-dark fw-100'>
                                                <h6>{contact.email}</h6>
                                            </a>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <Footer />
    </div>
}

export default Contact;