import * as d3 from 'd3';

import {
    CONFIG,
    UNI_NAME_MAPPING
} from '../../common/constants'
// TOOLTIP
export const showTooltip = function ({
    event = null,
    datum = null,
    cluster = null
}) {

    createTooltip({
        x: event.data.global.x,
        y: event.data.global.y,
        content: datum,
        cluster: cluster
    })
}

export const hideTooltip = function () {

    d3.selectAll(".siris-tooltip").remove();
    // event.target.removeChild(event.target.message);
    // delete event.target.message;
}


export const createTooltip = function ({
    content = null,
    cluster = null,
    clusterName = null,
    clusterColor = null
}) {

    d3.selectAll(".siris-tooltip").remove();

    const contentType = typeof content;

    let htmlContent;

    // <div style="borderLeft: 3px solid ${clusterColor}" className='left-tip'></div>
    // <h5><span className="text-dark" >${clusterName}</span></h5>

    if (contentType === 'string') {
        const keywords = content.split(",").splice(0, 15);
        htmlContent = `<div class="">
        
        <small class='text-muted d-block text-uppercase'>Topic name</small>
        <h5 style=" color: '#333' "  class='my-auto'>${clusterName}</h5>


        <small class='text-muted d-block text-uppercase mt-2'>Keywords</small>
        ${keywords.map(keyword => '<span class="badge bg-light me-1">' + keyword + '</span>').join('')}
        </div>`
    } else {
        // const activities = content.activityType.split(";")
        // const keywords = content.keywords.split(";");
        htmlContent = `
        <div>
            <small class='text-muted text-uppercase'>Document title</small> 
            <h5 class="">${content.title}</h5>
        </div>
        <div>
            <small class='text-muted text-uppercase'>University</small> 
            <h5 class="">${UNI_NAME_MAPPING[content.university]}</h5>
        </div>
        <div>
            <small class='text-muted text-uppercase'>Topic</small> 
            <h5 class="">${cluster[0].topic_label}</h5>
        </div>
        `
    }

    d3.select("#canvasContainer").append("div")
        .attr("class", "siris-tooltip")
        .style('position', 'absolute')
        .style('z-index', '999')
        .style('left', '0px')
        .style('bottom', '0px')
        .style('right', '0px')
        // .style('background-color', 'rgba(0,0,0,.25)')
        .style('background-color', 'rgba(255,255,255,1)')
        .style('border-top', `6px solid ${clusterColor || content.dotColor}`)
        .style('font-size', '16px')
        .style('padding', CONFIG.TOPICS.LAYOUT.SPACER + 'px')
        .style('pointer-events', 'none')
        .html(htmlContent);
}