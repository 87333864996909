import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import ItemHeader from '../ItemHeader';

function getParticipantTopics({
    id = null,
    topicsData = []
}) {
    const participantProjects = _.groupBy(topicsData[id], project => project['Final labelling'])
    const topicsSorted = Object.keys(participantProjects).sort(function (a, b) { return participantProjects[b].length - participantProjects[a].length })

    const html = <div className='d-flex flex-wrap px-4'> {_.map(topicsSorted, (topic, index) => {
        return <small key={index} className='badge border position-relative text-muted me-3 mb-3'>
            {topic}
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light border text-dark">
                {participantProjects[topic].length}
            </span>
        </small>
    })}
    </div>
    return html;
}

function Items({
    currentItems,
    clickedItem,
    listItemHandleClick,
    topicsData }) {

    // console.log("Items -> currentItems", currentItems);
    // console.log("Items -> listItemHandleClick", listItemHandleClick);

    return (
        <>
            {currentItems &&
                currentItems.map((actor, index) => (
                    <div
                        className={`list-item border-top ${actor['final_Name'] === clickedItem ? 'bg-dark text-white' : 'bg-light'}`}
                        key={index}
                        onClick={() => listItemHandleClick({ item: actor })}
                    >
                        <ItemHeader actor={actor}></ItemHeader>
                        {
                            getParticipantTopics({ id: actor['final_Name'], topicsData: topicsData })
                        }
                    </div>
                ))}
        </>
    );
}

export function PaginatedItems({
    itemsPerPage,
    listItems,
    clickedItem,
    listItemHandleClick,
    topicsData
}) {

    console.log("PaginatedItems -> listItems", listItems);

    // We start with an empty list of items.
    let [currentItems, setCurrentItems] = useState(null);
    let [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    // setPageCount(Math.ceil(listItems.length / itemsPerPage));



    const endOffset = itemOffset + itemsPerPage;
    useEffect(() => {
        // Fetch items from another resources.
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(listItems.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(listItems.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % listItems.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    currentItems = listItems.slice(itemOffset, endOffset);
    pageCount = Math.ceil(listItems.length / itemsPerPage);

    console.log("PaginatedItems -> pageCount", pageCount);

    // setItemOffset(itemOffset);

    return (
        <>
            <div className="bg-white items-list">
                <Items
                    currentItems={currentItems}
                    clickedItem={clickedItem}
                    listItemHandleClick={listItemHandleClick}
                    topicsData={topicsData}
                />
            </div>
            <div className="pagination-bar border">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={6}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    );
}