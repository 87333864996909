import * as d3 from 'd3';
import _ from 'lodash';
import {
    ACTIVITY_TYPES
} from '../common/constants'

export const getNetworkData = function ({
    projectsData = null,
    participantsData = null
}) {

    console.log("PROJECTS_DATA", projectsData);
    console.log("PARTICIPANTS_DATA", participantsData);

    // Generate nodes from participants in projects
    let nodesParticipants = _.map(projectsData, project => project.participants);
    nodesParticipants = nodesParticipants.filter((a) => a);

    let edges = [];

    let edgesFromParticipants = _.map(participantsData, participant => {
        return {
            current: participant,
            collaborations: _.filter(nodesParticipants, participants => participants.includes(participant.value))
        }
    })

    _.map(edgesFromParticipants, edge => {
        _.map(edge.collaborations, collaboration => {
            _.map(collaboration, participant => {
                if (edge.current.value !== participant) {
                    edges.push([edge.current.value, participant])
                }
            })
        })
    })

    const groupedEdges = _.groupBy(edges, edge => edge);
    const uniqueEdges = _.uniqWith(edges, _.isEqual);

    const dotSizeScale = d3.scaleLinear()
        .range([4, 24])
        .domain(d3.extent(participantsData, function (datum) { return datum.totalInvestment })),
        colorScale = d3.scaleOrdinal()
            .range(d3.schemeSet2)
            .domain(_.map(ACTIVITY_TYPES, type => type.value))

     const start_and_end = (str) => {
                if (str.length > 20) {
                  return str.substr(0, 10) + '...' + str.substr(str.length-10, str.length);
                }
                return str;
              }


    const networkNodes = _.map(participantsData, node => {

        const nodeSize = dotSizeScale(node.totalInvestment);
        const nodeColor = colorScale(node.activityTypeId);
        const nodeLabel = start_and_end(node.label)

        return {
            data: {
                id: node.value,
                label: nodeLabel,
                size: nodeSize,
                color: nodeColor
            }
        }
    })

    const networkEdges = _.map(uniqueEdges, edge => {

        const edgeWidth = Math.round(groupedEdges[edge[0] + ',' + edge[1]].length * 0.5)

        return {
            data: {
                source: edge[0],
                target: edge[1],
                weight: edgeWidth
            }
        }
    });

    const networkElements = networkNodes.concat(networkEdges)




    return networkElements

}
