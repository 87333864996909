import React, { Component, Fragment } from 'react';
import Select from 'react-select'
import './index.scss';

class SortingDropdown extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        // Call any function in provider
    }

    handleSelected = (options, { action, removedValue }) => {

        console.log("handleSelected");
        console.log("options", options);
        // console.log("action", action);
        // console.log("removedValue", removedValue);

        this.props.sortList({
            criteria: options.criteria,
            direction: options.direction
        })
    }

    render() {
        const { options, placeholder } = this.props;
        return <Fragment>
            <Select
                options={options}
                onChange={this.handleSelected}
                placeholder={<div className='text-truncate'>{placeholder}</div>}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'hotpink',
                        primary: 'black',
                    },
                })} />
        </Fragment>
    }
}

export default SortingDropdown;