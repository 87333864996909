import React, { Component } from 'react';

import './index.scss';

class Loader extends Component {

    render() {

        const { width, height, text, image } = this.props;

        return <div className={`loader mx-auto border w-${width ? width : 100} h-${height ? height : 100}`}>
            {image && <img className="mb-2 d-block" alt="logotype" src={image} />}
            <div className="spinner-border" role="status">
                {/* <span className="sr-only">{text && text}</span> */}
            </div>
            {text && <p className="mb-0 ml-2 pt-4">{text}</p>}
        </div>
    }
}

export default Loader;