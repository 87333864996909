import React, { Component, Fragment, useState } from 'react';
import Form from 'react-bootstrap/Form'
import './index.scss';
import Slider from 'rc-slider';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class FilterSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: []
        }
    }

    async componentDidMount() {
        // Call any function in provider
    }

    handleSelected = (event) => {
        this.setState({ filters: [] })
        for (var i = event[0]; i <= event[1]; i++) {
            this.state.filters.push({ value: i, label: i, sort: i })
        }
    }

    filterResults = () => {
        this.props.setOverlayLoader({ how: true })
        setTimeout(() => {
            this.props.setFilter({
                filter: this.state.filters,
                type: this.props.entity
            })
        }, 500);
    }

    renderTooltip = (text) => (
        <Tooltip id="button-tooltip" >
            {text}
        </Tooltip>
    );

    render() {
        var marks = {};
        for (var i = this.props.min; i <= this.props.max; i++) {
            if (this.props.everyTwo == false ? true : i % 2 == 0)
                marks[i] = i.toString()
            else marks[i] = ' '
        }
        return <Fragment>
            <div className='d-flex ps-2'>
                <Slider range marks={marks}
                    min={this.props.min}
                    max={this.props.max}
                    // value={this.props.value && this.props.value.length > 0 ? this.props.value : [this.props.min, this.props.max]}
                    defaultValue={this.props.defaultValue}
                    step={2}
                    onAfterChange={this.handleSelected} />
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 100, hide: 400 }}
                    overlay={this.renderTooltip('Apply filter')}
                >
                    <i onClick={this.filterResults} className='cursor-pointer fas fa-check-circle text-white ms-3 pe-0 me-0'></i>

                </OverlayTrigger >

            </div>
        </Fragment>
    }
}

export default FilterSlider;