
// common config for the vega vizs
export const VEGA_CONFIG = {
  title: {
    fontFamily: 'Roboto'
  }
};

// Mapping (labelling) properties from data
export const ACTIVITY_TYPES = [
  { 'value': 'HES', 'label': 'Higher Education Institutions' },
  { 'value': 'OTH', 'label': 'Other Participants' },
  { 'value': 'PRC', 'label': 'Private Sector' },
  { 'value': 'PUB', 'label': 'Public Bodies' },
  { 'value': 'REC', 'label': 'Research Organizations' }
];

// export const API_URL = 'http://ec2-3-250-29-147.eu-west-1.compute.amazonaws.com:8080/';

// export const SPARQL_ENDPOINT = 'http://ec2-52-51-177-228.eu-west-1.compute.amazonaws.com/unics/sparql/query';
export const SPARQL_ENDPOINT = 'http://ec2-52-51-177-228.eu-west-1.compute.amazonaws.com/ris3cat/sparql/query';

export const UNI_NAME_MAPPING = {
  "UTT": "University of Technology of Troyes",
  "H_DA": "Darmstadt University of Applied Sciences",
  "RTU": "Riga Technical University",
  "TUDublin": "Technological University Dublin",
  "TUS": "Technical University of Sofia",
  "CUT": "Cyprus University of Technology",
  "UPCT": "Technical University of Cartagena",
  "UTCN": "Technical University of Cluj-Napoca"
}

export const UNI_COLORS_MAPPING = {
  "UTT": "#46bdc6",
  "H_DA": "#fbbc04",
  "RTU": "#980000",
  "TUDublin": "#355823",
  "TUS": "#ff6d01",
  "CUT": "#6d9eeb",
  "UPCT": "#34a853",
  "UTCN": "#ea4335"
}

// INITIAL CONFIG FOR PROJECT
export const CONFIG = {
  APP: {
    NAME: 'EUt+ R&I topics',
    TOPICS: {
      ACTIVE: true,
      NAME: 'topics'
    },
    NETWORK: {
      ACTIVE: false,
      NAME: 'network'
    },
    LIST: {
      ACTIVE: false,
      NAME: 'list'
    },
    PROJECT_RELATED: false, // true if we want to show data,
    SHOW_LEFT_BAR: true
  },
  TOPICS: {
    LAYOUT: {
      MARGIN: 48,
      SPACER: 12,
      COLOR_CLUSTER: "#333",
      PANEL_WIDTH: 360,
      OPACITIES: {
        TOPIC_INACTIVE: 0,
        INACTIVE: 0.05,
        NORMAL: 0.45,
        ACTIVE: 0.85,
        FULL: 1
      }
    },
    DATA_FOLDERS: {
      COORDS: "./eut-data",
      TOPICS: "./eut-data",
    }
  },
  SORTING: {
    OPTIONS: [
      {
        label: 'name (asc)',
        value: 'nameAsc',
        criteria: 'name',
        direction: 'asc'
      },
      {
        label: 'name (desc)',
        value: 'nameDesc',
        criteria: 'name',
        direction: 'desc'
      },
      {
        label: 'country (asc)',
        value: 'countryAsc',
        criteria: 'country',
        direction: 'asc'
      },
      {
        label: 'country (desc)',
        value: 'countryDesc',
        criteria: 'country',
        direction: 'desc'
      },
      {
        label: 'n. of documents (asc)',
        value: 'documentsAsc',
        criteria: 'documents',
        direction: 'asc'
      },
      {
        label: 'n. of documents (desc)',
        value: 'documentsDesc',
        criteria: 'documents',
        direction: 'desc'
      }
    ]
  },
  COLABORATORS: {}
};
