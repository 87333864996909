import React, { Component, Fragment } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

import {
    getProjectParticipantsInformation
} from '../../data/DataService'

import './index.scss';

import { Vega } from 'react-vega'
import * as specs from '../VegaCharts/specs/specs'

import { Accordion } from 'react-bootstrap'
import {
    ACTIVITY_TYPES,
    CONFIG
} from '../../common/constants';


class PanelInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {

        let participantsInProject = await getProjectParticipantsInformation(this.props.clickedDot);
        this.setState({
            projectParticipantsInfo: participantsInProject,
        })
    }

    async componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.clickedDot !== prevProps.clickedDot || this.props.clickedLabel !== prevProps.clickedLabel) {
            let participantsInProject = await getProjectParticipantsInformation(this.props.clickedDot);
            this.setState({
                projectParticipantsInfo: participantsInProject,
            })
        }
    }

    calculateTopicInformation = (topicId) => {
        const projectsInTopic = _.filter(this.props.data.topics.data, project => project.cluster === topicId);
        const topicSummary = this.props.data.topics.summary.filter(x => x.cluster_n == this.props.clickedLabel)

        var typeDistributionObj = _(topicSummary)
            .groupBy('type')
            .map((doc, id) => ({
                dataKey: id,
                dataValue: _.sumBy(doc, 'n_docs'),
            }))
            .value()

        var universitySourceDistribution = { 'table': [] }
        var grouped = _.groupBy(projectsInTopic, function (i) {
            return i.type + '$' + i.university
        })

        Object.keys(grouped).forEach(group => {
            var obj = {}
            let split_group = group.split('$')
            obj['dataGroup'] = split_group[0]
            obj['dataKey'] = split_group[1]
            obj['dataValue'] = grouped[group].map(x => x.id).filter(function (value, index, array) {
                return array.indexOf(value) === index;
            }).length
            universitySourceDistribution.table.push(obj)
        });


        // ? Type distribution average (spec index on sources)
        var typeDistributionOnAvg = {
            table: []
        }

        var groupByTypeAndCluster = _.groupBy(this.props.data.topics.summary, function (i) {
            return i.type + '$' + i.cluster_n
        })

        var docPerTypeTotals = [];
        Object.keys(groupByTypeAndCluster).forEach(group => {
            var obj = {}
            let split_group = group.split('$')
            obj['type'] = split_group[0]
            obj['cluster'] = split_group[1]
            obj['sum_docs'] = _.sumBy(groupByTypeAndCluster[group], 'n_docs')
            docPerTypeTotals.push(obj)
        });

        var total_topics = _.uniqBy(this.props.data.topics.summary, 'cluster_n').length

        typeDistributionOnAvg = {
            table: _(docPerTypeTotals)
                .groupBy('type')
                .map((doc, id) => ({
                    dataKey: id,
                    dataValue: _.sumBy(doc, 'sum_docs') / total_topics,
                }))
                .value()
        }

        var typeDistributionOnAvgfinalresults = {
            table: []
        }
        typeDistributionOnAvg.table.map(x => x.dataKey).forEach(t_type => {
            var totalsobjtype = typeDistributionObj.filter(x => x.dataKey == t_type);
            var xobjtype = typeDistributionOnAvg.table.filter(x => x.dataKey == t_type)
            if (totalsobjtype.length > 0 && xobjtype.length > 0)
                typeDistributionOnAvgfinalresults.table.push({
                    dataKey: t_type,
                    dataValue: (totalsobjtype[0].dataValue / xobjtype[0].dataValue).toFixed(2)
                })
        });

        const aggregates = {
            totalProjects: projectsInTopic.length,
            nationalProjects: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "National projects"), 'n_docs') : 0,
            h2020Projects: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "H2020"), 'n_docs') : 0,
            patents: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "Patents"), 'n_docs') : 0,
            publications: topicSummary ? _.sumBy(topicSummary.filter(x => x.type == "Publications"), 'n_docs') : 0,
            universitySourceDistribution: universitySourceDistribution,
            typeDistributionOnAvg: typeDistributionOnAvgfinalresults,
            typeDistribution: { // sources distribution chart 
                table: typeDistributionObj
            },
            yearDistribution: { // trend chart
                table: _.sortBy(_(topicSummary)
                    .groupBy('year')
                    .map((doc, id) => ({
                        dataKey: id,
                        dataValue: _.sumBy(doc, 'n_docs'),
                    }))
                    .value(), 'dataKey').filter(x => x.dataKey >= 2014 && x.dataKey <= 2020)
            },
            SDGDistribution: _.countBy(projectsInTopic.reduce((acc, val) => {
                if (val.SDGs)
                    val.SDGs.forEach(el => {
                        acc.push({ projectId: val.id, SDGs: [el][0] });
                    });
                return acc;
            }, []), 'SDGs'),
        }

        return aggregates;
    }

    paintClusterKeywords = (keywords) => {
        const keywordsArray = keywords.split(", ").map(x => x.replace(',', '')).filter(x => x.trim() != '-' && x.trim() != '(' && x.trim() != ')').slice(0, 25);
        const htmlContent = <p className=''>{keywordsArray.map((keyword, index) => <span key={index} className='badge bg-secondary me-1'>{keyword}</span>)}</p>;
        return htmlContent;
    }

    paintProjectKeywords = (keywords) => {
        const keywordsArray = keywords.split(",").slice(0, 25);
        const htmlContent = <p className=''>{keywordsArray.map((keyword, index) => <span key={index} className='badge bg-secondary me-1'>{keyword}</span>)}</p>;
        return htmlContent;
    }

    generateColorPalette = (color) => {
        var palette = [];
        if (!color) return ['rgb(0,0,0)'];

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
        var start_rgb = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        }

        palette.push({ r: start_rgb.r, g: start_rgb.g, b: start_rgb.b })

        for (var i = 0; i < 8; i++) {
            palette.push({ r: (palette[palette.length - 1].r / 1.15).toFixed(0), g: (palette[palette.length - 1].g / 1.15).toFixed(0), b: (palette[palette.length - 1].b / 1.15).toFixed(0) })
        }

        return palette.map(x => {
            return `rgb(${x.r}, ${x.g}, ${x.b})`
        })
    }

    slicePalette = (palette, n) => {
        return palette.slice(0, n).reverse()
    }

    organiseDataForVega = (data) => {
        return {
            table: Object.entries(data).map(([dataKey, dataValue]) => ({
                dataKey,
                dataValue
            })).filter(x => x.dataKey != 'N/A')
        }
    }

    render() {


        function handleHover(...args) {
            console.log("HANDLE HOVER", args);
        }

        const signalListeners = { tooltip: handleHover };

        const { projectParticipantsInfo } = this.state;

        const currentProject = this.props.getProjectInformation(this.props.clickedDot);
        const currentTopic = this.props.getTopicInformation(this.props.clickedLabel);


        const clusterColor = _.find(this.props.data.topics.data, project => project.cluster === this.props.clickedLabel).dotColor;


        // Calculate 
        const currentTopicAggregates = this.calculateTopicInformation(this.props.clickedLabel);

        var SDGDistributionVegaValue = this.organiseDataForVega(currentTopicAggregates.SDGDistribution);

        SDGDistributionVegaValue.table.forEach(sdg => {
            sdg['parent'] = " "
        });
        SDGDistributionVegaValue.table.unshift({ "dataKey": " ", "parent": null, "dataValue": null })
        SDGDistributionVegaValue.table = SDGDistributionVegaValue.table.map(x => { x.dataKey = x.dataKey.replace('ODS', 'SDG'); return x })

        var topic_palette = this.generateColorPalette(clusterColor)
        // Still doesn't work, to change
        var SDGPalette = {
            'SDG 1': 'rgb(229,36,59)',
            'SDG 2': 'rgb(221,166,58)',
            'SDG 3': 'rgb(76,159,56)',
            'SDG 4': 'rgb(197,25,45)',
            'SDG 5': 'rgb(255,58,33)',
            'SDG 6': 'rgb(38,189,226)',
            'SDG 7': 'rgb(252,195,11)',
            'SDG 8': 'rgb(162,25,66)',
            'SDG 9': 'rgb(253,105,37)',
            'SDG 10': 'rgb(221,19,103)',
            'SDG 11': 'rgb(253,157,36)',
            'SDG 12': 'rgb(191,139,46)',
            'SDG 13': 'rgb(63,126,68)',
            'SDG 14': 'rgb(10,151,217 )',
            'SDG 15': 'rgb(86,192,43)',
            'SDG 16': 'rgb(0,104,157)',
            'SDG 17': 'rgb(25,72,106)',
        }

        return <Fragment>
            {/* <h6 className='text-uppercase'><small>Topic</small></h6> */}
            <Accordion className="custom-accordion" defaultActiveKey="0">
                {
                    this.props.clickedDot
                    && <Accordion.Item eventKey="0">
                        <Accordion.Header>{CONFIG.APP.PROJECT_RELATED ? 'Project' : 'Selected document'}</Accordion.Header>
                        <Accordion.Body className={`scrollable-accordion  ${this.props.clickedDot ? 'double' : 'single'}`}>
                            <h6 className="panel-title mt-0">project title</h6>
                            <h5>{currentProject[0].title}</h5>

                            <h6 className="panel-title mt-4">year</h6>
                            <h5>{currentProject[0].year}</h5>

                            <h6 className="panel-title mt-4">type</h6>
                            <h5>{currentProject[0].type}</h5>

                            <h6 className="panel-title mt-4">university</h6>
                            <h5>{currentProject[0].university}</h5>

                            <h6 className="panel-title">Keywords</h6>
                            <div className="row">
                                <div className="col">{this.paintProjectKeywords(currentProject[0].keywords)}
                                </div>
                            </div>

                            {projectParticipantsInfo && projectParticipantsInfo.length > 0 ?
                                <>
                                    <h6 className="panel-title mt-0">Participants organisations</h6>
                                    <ul className='mb-4 list-group'>
                                        {
                                            _.map(projectParticipantsInfo, (participant, index) => {
                                                return <li key={index} className='list-group-item'>
                                                    {/* <small className='d-block text-uppercase'>{participant.countryName}</small> */}
                                                    {participant.ecParticipantName}  <br />
                                                    {/* <strong>{d3.format(',')(participant.ecContribution)}€</strong> */}
                                                </li>
                                            })
                                        }
                                    </ul>
                                </> : ''}
                        </Accordion.Body>
                    </Accordion.Item>}
                <Accordion.Item eventKey={this.props.clickedDot ? "1" : "0"}>
                    <Accordion.Header>Selected topic</Accordion.Header>
                    <Accordion.Body className="scrollable-accordion">
                        <h3 style={{ color: clusterColor }}>{currentTopic.length > 0 && currentTopic[0].topic_label}</h3>
                        <div className="row py-2">
                            <div className="col col-3 text-center text-uppercase">
                                <p className='text-truncate'><small>National projects</small></p>
                                <h4>{currentTopicAggregates.nationalProjects}</h4>
                            </div>
                            <div className="col col-3 text-center text-uppercase">
                                <p className='text-truncate'><small>H2020</small></p>
                                <h4>{currentTopicAggregates.h2020Projects}</h4>
                            </div>
                            <div className="col col-3 text-center text-uppercase">
                                <p className='text-truncate'><small>Patents</small></p>
                                <h4>{currentTopicAggregates.patents}</h4>
                            </div>
                            <div className="col col-3 text-center text-uppercase">
                                <p className='text-truncate'><small>Publications</small></p>
                                <h4>{currentTopicAggregates.publications}</h4>
                            </div>
                        </div>
                        <h6 className="panel-title">Keywords</h6>
                        <div className="row">
                            <div className="col">{this.paintClusterKeywords(currentTopic[0].topic_words)}
                            </div>
                        </div>

                        {/* Vega charts */}
                        <div className="mt-4">
                            <h6 className="panel-title">Trend</h6>
                            <Vega spec={specs.createLineChartSpec(topic_palette)} data={currentTopicAggregates.yearDistribution} />

                            <h6 className="mt-4 panel-title">Number of documents in the topic by university</h6>
                            <Vega
                                spec={specs.createStackedBarChartSpecUniv(null, false)}
                                data={currentTopicAggregates.universitySourceDistribution} />

                            <h6 className="mt-4 panel-title">Share of document types in the topic by university</h6>
                            <Vega
                                spec={specs.createStackedBarChartSpecUniv(currentTopicAggregates.universitySourceDistribution.table, true)} />

                            {/* <h6 className="mt-4 panel-title">Sources distribution</h6>
                            <Vega
                                spec={specs.createVerticalBarChartSpec(this.slicePalette(topic_palette, currentTopicAggregates.typeDistribution.table.length))}
                                data={currentTopicAggregates.typeDistribution} /> */}

                            <h6 className="mt-4 panel-title">Sources distribution</h6>
                            {console.log(currentTopicAggregates.typeDistributionOnAvg.table)}
                            <Vega
                                spec={specs.createSpecIndexChart(currentTopicAggregates.typeDistributionOnAvg.table, Math.ceil(Math.max(...currentTopicAggregates.typeDistributionOnAvg.table.map(x => parseFloat(x.dataValue)))), this.slicePalette(topic_palette, currentTopicAggregates.typeDistributionOnAvg.table.length))}
                                data={currentTopicAggregates.typeDistributionOnAvg}
                            />


                            {SDGDistributionVegaValue.table.length > 1 ?
                                <>
                                    <h6 className="mt-4 panel-title">SDG distribution</h6>
                                    <Vega spec={specs.createTreeMapSpec(Object.values(SDGPalette), Object.keys(SDGPalette))} data={SDGDistributionVegaValue} />
                                </> : ''}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Fragment >
    }
}

export default PanelInformation;