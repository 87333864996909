import React, { Component, Fragment } from 'react';
import { PaginatedItems } from './components';
import SortingDropdown from '../SortingDropdown';
import { CONFIG } from '../../common/constants';
import * as d3 from 'd3';
import _ from 'lodash';
import './index.scss';

class ListExtended extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    async componentDidMount() {
        // Call any function in provider
    }

    getParticipantTopics({
        id = null
    }) {
        const participantProjects = _.groupBy(this.props.data.list.topicsData[id], project => project['Final labelling'])
        const topicsSorted = Object.keys(participantProjects).sort(function (a, b) { return participantProjects[b].length - participantProjects[a].length })

        const html = <div className='d-flex flex-wrap px-4'> {_.map(topicsSorted, (topic, index) => {
            return <small key={index} className='badge border position-relative text-muted me-3 mb-3'>
                {topic}
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light border text-dark">
                    {participantProjects[topic].length}
                </span>
            </small>
        })}
        </div>
        return html;
    }


    render() {

        console.log("LIstExtended -> render", this.props)

        const { data, filters, clickedItem, sortList } = this.props;

        console.log("HEE", data.list)
        return <Fragment>
            <div className="list-extended">
                <header className='p-4 d-flex'>
                    <h3>{d3.format(",")(filters.summary.participants)} {filters.summary.participants > 1 ? 'participants' : 'participant'} | {d3.format(",")(filters.summary.documents)} {filters.summary.documents > 1 ? 'documents' : 'document'}</h3>
                    <div className="ms-auto">
                        <SortingDropdown options={CONFIG.SORTING.OPTIONS} placeholder='List sorting' sortList={sortList}></SortingDropdown>
                    </div>
                </header>

                <PaginatedItems
                    itemsPerPage={25}
                    listItems={filters.list}
                    clickedItem={clickedItem}
                    listItemHandleClick={this.props.listItemHandleClick}
                    topicsData={data.list.topicsData}
                />

                {/* <div className='items-list'>
                    {_.map(filters.list, (actor, index) => {
                        return <div
                            className={`list-item border-top ${actor['final_Name'] === clickedItem ? 'bg-dark text-white' : 'bg-light'}`}
                            key={index}
                            onClick={() => this.props.listItemHandleClick({ item: actor })}>
                            <ItemHeader actor={actor}></ItemHeader>
                            {
                                this.getParticipantTopics({ id: actor['final_Name'] })
                            }
                        </div>
                    })}
                </div> */}

            </div>
        </Fragment>
    }
}

export default ListExtended;