import React, { Component, Fragment } from 'react';
import { Context } from '../../context/Context';
import { Provider } from '../../context/Provider';
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from "react-offcanvas";

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import EutNavbar from '../../components/Navbar';

import { IoCloseSharp } from "react-icons/io5";

import TopicsCanvas from '../../components/TopicsCanvas';
import NetworkCanvas from '../../components/NetworkCanvas';
import PanelInformation from '../../components/PanelInformation';
import FiltersPanel from '../../components/FiltersPanel';
import Modal from '../../components/Modal';
import UniversityMiniCard from '../../components/Modal/UniversityMiniCard';
import FiltersSummary from '../../components/FiltersSummary';
import Loader from '../../components/Loader';
import OverlayLoader from '../../components/OverlayLoader';
import ListExtended from '../../components/ListExtended';

import './index.scss';

import {
    CONFIG
} from '../../common/constants';
import AnalyticsModal from '../../components/AnalyticsModal';
import ListModal from '../../components/ListModal';

class Home extends Component {

    constructor() {
        super();
        this.state = {
            isMobile: false
        }
        this.canvasContainer = React.createRef()
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            canvasContainerWidth: this.canvasContainer.current.offsetWidth,
            canvasContainerHeight: this.canvasContainer.current.offsetHeight,
            isMobile: window.innerWidth < 768
        })

        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange() {
        this.canvasContainer = React.createRef()
        this.setState({
            isMobile: window.innerWidth <= 768
        });
    }



    render() {

        const { canvasContainerWidth, canvasContainerHeight } = this.state

        return <div className="home">
            <EutNavbar />
            {!this.state.isMobile ? <Provider className='d-none'>
                <Context.Consumer>
                    {
                        context => <Fragment>
                            {/* {console.log("HOME -> CONTEXT", context)} */}
                            {context.loading && <Loader text={'Loading documents and topics'}></Loader>}

                            <OffCanvas
                                width={CONFIG.TOPICS.LAYOUT.PANEL_WIDTH}
                                effect={"push"}
                                isMenuOpened={context.isMenuOpened}
                                position={"right"}
                            >

                                <OffCanvasBody className='off-canvas-body'>
                                    {
                                        !context.loading
                                        && <FiltersPanel {...context}></FiltersPanel>
                                    }


                                    {context.getOverlayLoader() && <OverlayLoader text={"Filtering"} />}

                                    <div id="canvasContainer" ref={this.canvasContainer} className="canvas-container">
                                        {context.colouringType != 'cluster' && <div className="mt-2 colors-container">
                                            {context.colorScaleDescription.map((color, i) => {
                                                return (
                                                    <div key={i} className="">
                                                        <span className="badge me-1 d-inline" style={{ color: color.color }}>⬤</span>
                                                        <span className="color-description badge d-inline">{color.name} <small className="d-none">({color.count})</small></span>
                                                    </div>
                                                )
                                            })}
                                        </div>}
                                        {
                                            !context.loading
                                            && <FiltersSummary {...context}></FiltersSummary>
                                        }

                                        <h3 className='menu-trigger text-white position-absolute px-3 py-2'
                                            onClick={context.closeOffCanvasMenu.bind(this)}>
                                            {
                                                context.isMenuOpened && <IoCloseSharp></IoCloseSharp>
                                            }
                                        </h3>
                                        {
                                            !context.loading
                                            && <Tabs defaultActiveKey="topics" id="canvas-options" className="main-tabs">
                                                {
                                                    CONFIG.APP.TOPICS.ACTIVE &&
                                                    <Tab eventKey="topics" title="Topics map">
                                                        <TopicsCanvas
                                                            canvasWidth={canvasContainerWidth}
                                                            canvasHeight={canvasContainerHeight}
                                                            {...context}></TopicsCanvas>
                                                    </Tab>
                                                }
                                                {
                                                    CONFIG.APP.NETWORK.ACTIVE &&
                                                    <Tab eventKey="network" title="Participants network">
                                                        <NetworkCanvas
                                                            canvasWidth={canvasContainerWidth}
                                                            canvasHeight={canvasContainerHeight}
                                                            {...context}></NetworkCanvas>
                                                    </Tab>
                                                }
                                                {
                                                    CONFIG.APP.LIST.ACTIVE &&
                                                    <Tab eventKey="list" title="Participants list">
                                                        <ListExtended
                                                            canvasWidth={canvasContainerWidth}
                                                            canvasHeight={canvasContainerHeight}
                                                            {...context}></ListExtended>
                                                    </Tab>
                                                }
                                            </Tabs>


                                        }

                                    </div>
                                </OffCanvasBody>
                                {/* <OffCanvasMenu className='off-canvas-menu'>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col g-0">
                                                {
                                                    context.clickedLabel != undefined
                                                    && <PanelInformation {...context}></PanelInformation>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </OffCanvasMenu> */}
                            </OffCanvas>

                            {/* MODALS */}
                            {
                                !context.loading
                                && <Modal modalId="analytics" {...context}>
                                    {
                                        context.clickedLabel != undefined
                                        && <AnalyticsModal {...context}></AnalyticsModal>
                                    }
                                </Modal>
                            }

                            {
                                !context.loading
                                && <ListModal {...context}></ListModal>

                            }

                            {/* INFO MODAL */}
                            {
                                !context.loading
                                && <Modal size="xl" modalId="info" {...context}>
                                    <div className='py-3'>
                                        <div className='source-cards d-flex flex-md-row flex-column'>
                                            <div className='source-card'>
                                                <h6 className='text-yellow fw-bold'>National and regional <br/> R&I projects (2010-2022)</h6>
                                                <p className='text-white'>Internal databases of EUt+ universities</p>
                                            </div>
                                            <div className='source-card'>
                                                <h6 className='text-yellow fw-bold'>Horizon 2020 projects  <br/>(2014-2020)</h6>
                                                <p className='text-white'><a href="https://cordis.europa.eu/" target='_blank'>CORDIS</a></p>
                                            </div>
                                            <div className='source-card w-25'>
                                                <h6 className='text-yellow fw-bold'>Publications <br/>(2014-2021)</h6>
                                                <p className='text-white'><a href="https://www.openaire.eu/" target='_blank'>OpenAIRE</a></p>
                                            </div>
                                            <div className='source-card'>
                                                <h6 className='text-yellow fw-bold'>Patents <br/>(2014-2020)</h6>
                                                <p className='text-white'><a href="https://www.epo.org/searching-for-patents" target='_blank'>European Patent Office</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                            }
                            {/* MODALS */}


                        </Fragment>
                    }
                </Context.Consumer>
            </Provider> : <div>
                <h4 className='m-auto text-center'>Sorry, this page is not available on mobile devices. Please visit from a desktop computer.</h4>
            </div>}
        </div >
    }
}

export default Home;